import React, { Component } from "react";
import IcOffice from "../../Assets/Office.png";
import { findCityById, findStateById } from "../../helpers";
import PencilSimpleLine from "../../Assets/PencilSimpleLine.png";
import "./index.css";
import moment from "moment";

export class CardWorkExperiences extends React.Component {
  handleEdit = (e,id) => {
    this.props.onShowEdit(id)
  };

  render() {
    return (
      <>
        {this.props.experiences.map((experience, index) => {
          return (
            <div className="flex py-6 px-6 border border-line-gray-200 rounded-md mb-4 px"  style={{ justifyContent: "space-between" }}>
              {/* <div
                className="flex justify-center pt-1 ic-section min-w-[70px] md:min-w-[100px]"
              >
                <img src={IcOffice} alt="icon" className="h-[50px] w-[50px]" />
              </div> */}
              <div className="desc-section">
                <p className="font-bold mb-0.5">{experience.job_title}</p>
                <p className="font-light text-slate-600 mb-0.5 text-sm md:text-base">
                  {experience.company_name} -{" "}
                  {findStateById(experience.province).name},
                  {findCityById(experience.city).name}
                </p>
                <p className="font-light text-slate-500 text-sm  mb-2">
                  {moment(experience.start_date).format("DD/MM/Y")} -{" "}
                  {experience.is_current ? "Present" : moment(experience.end_date).format("DD/MM/Y")}
                </p>
                <p className="text-slate-600 text-sm md:text-base mb-2">
                  <b>Skill</b> : {experience.related_skill_desc}
                </p>
                <p className="text-slate-600 text-sm md:text-base mb-2">
                  {experience.job_description}
                </p>
                {/* <ul className="text-slate-600 list-disc list-inside pl-3">
                  <li>Competitor Research</li>
                </ul> */}
              </div>
              <div className="edit-section">
                <button className="bg-white">
                  <img
                    src={PencilSimpleLine}
                    alt="icon"
                    className="h-[15px] w-[15px] md:h-[20px] md:w-[20px]"
                    onClick={(e) => {
                      this.handleEdit(e,experience.id)
                    }}
                  />
                </button>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default CardWorkExperiences;
