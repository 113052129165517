import React, { Component } from "react";
// import "./index.css";
import moment from "moment";
import domtoimage from "dom-to-image";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DotSixVertical from "../Icons/DotSixVertical";

export class AssessmentSort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: "",
      questionImage: null,
      items: [
        { id: "item-1", content: "Item Y" },
        { id: "item-2", content: "Item X" },
        { id: "item-3", content: "Item ZZ" },
      ],
    };

    this.elemRef = React.createRef(null);
  }

  componentDidMount() {
    const self = this;
    const element = this.elemRef.current;
    if (element) {
      domtoimage
        .toPng(element)
        .then(function (dataUrl) {
          self.setState({ questionImage: dataUrl });
        })
        .catch(function (error) {
          // Handle error
          // console.error("Error converting element to image:", error);
        });
    }
  }

  convertToImage() {}

  setAnswer(answer) {
    this.setState({ answer: answer });
    this.props.answer({
      ...this.props.question,
      answer: JSON.stringify(answer),
    });
  }

  // updateEl(el) {
  //   console.log(el);
  // }

  render() {
    const {
      id = "",
      content = "",
      order = "",
      type = null,
      kind = "",
      score = "",
      times = "",
      options = [],
    } = this.props.question;
    const currentAnswer = this.props.currentAnswer;
    const onDragEnd = (result) => {
      if (!result.destination) return; // If dropped outside a droppable area, do nothing

      const updatedItems = Array.from(
        currentAnswer.length > 0
          ? currentAnswer.find((curr) => curr.id == id)
            ? JSON.parse(currentAnswer.find((curr) => curr.id == id).answer)
            : options
          : options
      );
      const [reorderedItem] = updatedItems.splice(result.source.index, 1);
      updatedItems.splice(result.destination.index, 0, reorderedItem);

      this.setAnswer(updatedItems);
    };

    return (
      <div className="rounded-[8px] w-full mdb:px-5 mdb:py-5 px-2 py-2 mt-2 mdb:mt-4">
        <div className="mdb:mb-5 mb-2">
          <h2 className="text-lg font-bold">Drag and Sort</h2>
        </div>
        <div className="flex">
          <div className="flex flex-col" style={{ marginTop: "0.7rem" }}>
            {options.map((option, index) => {
              return (
                <div
                  className="rounded-[8px] border border-line-grey-200  px-5 py-3 mr-2"
                  style={{ marginTop: index > 0 ? "0.9rem" : "" }}
                >
                  <p className="text-[16px] leading-[140%] font-bold no-select">
                    {index + 1}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col w-full">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-3 mt-3"
                  >
                    {currentAnswer.length > 0
                      ? currentAnswer.find((curr) => curr.id == id)
                        ? JSON.parse(
                            currentAnswer.find((curr) => curr.id == id).answer
                          ).map((option, index) => (
                            <Draggable
                              key={`option-${option.id}`}
                              draggableId={`option-${option.id}`} // Ensure this matches the id of the item
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="flex"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  {/* <div className="rounded-[8px] border border-line-grey-1 hover:border-2 hover:font-bold px-5 py-3 cursor-pointer mr-2">
                              <p className="text-[16px] leading-[140%] font-bold no-select">
                                {index + 1}
                              </p>
                            </div> */}
                                  <div className="rounded-[8px] w-full border border-line-grey-200 hover:border-2 hover:font-bold px-5 py-3 cursor-pointer">
                                    <p className="text-[16px] leading-[140%] flex justify-between no-select">
                                      {option.value} <DotSixVertical />
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))
                        : options.map((option, index) => {
                            return (
                              <Draggable
                                key={`option-${option.id}`}
                                draggableId={`option-${option.id}`} // Ensure this matches the id of the item
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="flex"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    {/* <div className="rounded-[8px] border border-line-grey-1 hover:border-2 hover:font-bold px-5 py-3 cursor-pointer mr-2">
                                <p className="text-[16px] leading-[140%] font-bold no-select">
                                  {index + 1}
                                </p>
                              </div> */}
                                    <div className="rounded-[8px] w-full  border border-line-grey-200 hover:border-2 hover:font-bold px-5 py-3 cursor-pointer">
                                      <p className="text-[16px] leading-[140%] flex justify-between no-select">
                                        {option.value} <DotSixVertical />
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                      : options.map((option, index) => {
                          return (
                            <Draggable
                              key={`option-${option.id}`}
                              draggableId={`option-${option.id}`} // Ensure this matches the id of the item
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="flex"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  {/* <div className="rounded-[8px]  border border-line-grey-1 hover:border-2 hover:font-bold px-5 py-3 cursor-pointer mr-2">
                              <p className="text-[16px] leading-[140%] font-bold no-select">
                                {index + 1}
                              </p>
                            </div> */}
                                  <div className="rounded-[8px] w-full border border-line-grey-200 hover:border-2 hover:font-bold px-5 py-3 cursor-pointer">
                                    <p className="text-[16px] leading-[140%] flex justify-between no-select">
                                      {option.value} <DotSixVertical />
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }
}
