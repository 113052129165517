import React, { Component } from "react";
import PlusGrey from "../Assets/PlusGrey.png";

export class TagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      inputTag: "",
      inputValue: "",
      suggestions: [],
    };
  }

  componentDidMount() {
    this.setState({ tags: this.props.currentTags.split(",") })
  }

  handleKeyPress = (e) => {
    // console.log(e);
    if (e.key === 'Enter') {
      e.preventDefault();
      const button = e.target.closest('.flex').querySelector('button');
      if (button) {
        button.click();
      }
    }
  };

  onInputChange = (event) => {
    const inputText = event.target.value;
    // Filter suggestions based on the input text (e.g., from an API or predefined list)
    const filteredSuggestions = this.props.suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputText.toLowerCase())
    );

    this.setState({
      inputValue: inputText,
      suggestions: filteredSuggestions,
    });
  };

  onSuggestionClick = (suggestion) => {
    this.setState({ inputTag: suggestion, suggestions: [] });
  };

  renderSuggestions = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }

    return (
      <div className="suggestion-list">
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className="suggestion-item"
            onClick={() => this.onSuggestionClick(suggestion)}
          >
            {suggestion}
          </div>
        ))}
      </div>
    );
  };

  OnAddTag = (e) => {
    e.preventDefault();
    var newState = this.state.tags;
    newState.push(this.state.inputTag);
    this.setState({ tags: newState });
    this.setState({ inputTag: "" });
    this.props.tags(this.state.tags.join());
  };

  OnInputTag = (e) => {
    e.preventDefault();
    if (e.target.value == "") {
      this.setState({ inputTag: e.target.value, suggestions: [] });
    } else {
      if (typeof this.props.suggestions != "undefined") {
        if (this.props.suggestions.length > 0) {
          const filteredSuggestions = this.props.suggestions.filter((suggestion) =>
            suggestion.toLowerCase().includes(e.target.value.toLowerCase())
          );
          this.setState({ inputTag: e.target.value, suggestions: filteredSuggestions });
        } else {
          this.setState({ inputTag: e.target.value });
        }
      } else {
        this.setState({ inputTag: e.target.value });
      }
    }


  };

  OnDeleteTag = (e, index) => {
    e.preventDefault();
    let newTags = this.state.tags;
    newTags.splice(index, 1);
    this.setState({ tags: newTags });
    this.props.tags(this.state.tags.join());
  }

  render() {
    return (
      <>
        <div>
          <div className="flex border flex-wrap w-full bg-white items-center rounded-lg p-3 h-10 mb-4" onKeyDown={this.handleKeyPress}>
            <input
              className="pl-2 w-[85%] h-8 placeholder:text-sm mt-[-8px] border-transparent focus:border-transparent focus:ring-0"
              type="text"
              autoComplete="off"
              name="jobRelated"
              id="jobRelated"
              placeholder="Input Tag"
              value={this.state.inputTag}
              onChange={(e) => this.OnInputTag(e)}
            />
            <div className="flex w-[15%] mt-[-8px] justify-end">
              <button
                className="bg-white ml-[16px]"
                onClick={(e) => this.OnAddTag(e)}
              >
                <img src={PlusGrey} alt="icon" className="h-[24px] w-[24px]" />
              </button>
            </div>
          </div>
          <div>
          {this.renderSuggestions()}
          </div>
          
          <div className="px-2 pt-2 pb-11 mb-3 flex flex-wrap border bg-white items-center rounded-lg">
            {this.state.tags.map((tag, index) => {
              if (tag === "" || tag === null) return (<></>);
              return (
                <span className="flex flex-wrap pl-4 pr-2 py-2 m-1 justify-between items-center text-sm font-medium rounded-xl cursor-pointer bg-purple-500 text-gray-200 hover:bg-purple-600 hover:text-gray-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-100">
                  {tag}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 ml-3 hover:text-gray-300"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={(e) => this.OnDeleteTag(e, index)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default TagInput;
