import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  
  let navigate = useNavigate();
  
  useEffect(() => {
    sessionStorage.removeItem("user");
    navigate("/login", {replace: true});
  });
  
}

export default ProfilePage;