import React from "react";
// import  "../../../features/assessment/assessmentSlice"
import { ClockIcon } from "@heroicons/react/24/solid";

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
      intervalId: null,
      deductionInterval: 1,
    };
  }

  getTime = () => {
    if (this.state.seconds > 0) {
      this.setState({
        seconds: this.state.seconds - this.props.deductionInterval,
      });
      sessionStorage.setItem("latest_timer", this.state.seconds);
    } else {
      clearInterval(this.state.intervalId);
      this.props.setTimeOver();
    }
  };

  componentDidMount() {
    if (this.props.time) {
      this.setState({ seconds: this.props.time });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.deductionInterval);
    // if (this.props.deductionInterval) {
    //   this.setState({ deductionInterval: this.props.deductionInterval })
    // }

    if (this.props.play && prevState.seconds !== this.state.seconds) {
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
      }
      const intervalId = setInterval(() => this.getTime(), 1000);
      this.setState({ intervalId });
    }
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  render() {
    return (
      <div className="flex items-center">
        <div className="hidden mdb:block">
          <ClockIcon width={24} />
        </div>
        <div className="block mdb:hidden mr-2">
          <ClockIcon width={20} />
        </div>
        <p className="font-medium mr-3 ml-3 mdb:text-base text-sm hidden mdb:block">{this.props.label}</p>
        <p className="font-extrabold mdb:text-base text-sm">
          {Math.floor(this.state.seconds / 60)} :{" "}
          {this.state.seconds % 60 < 10
            ? "0" + (this.state.seconds % 60)
            : this.state.seconds % 60}
        </p>
      </div>
    );
  }
}

export default Timer;
