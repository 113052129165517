import React from "react";
import Autosuggest from "react-autosuggest";
import "./theme.css";

const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

class TagInputWithSuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      tags: [],
      inputTag: "",
      data: []
    };
  }

  componentDidMount() {
    this.setState({data: this.props.data})
    this.setState({tags: this.props.currentTags ? this.props.currentTags.split(",") : []})
  }
  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const data = this.state.data;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : data.filter(
          (val) => val.name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  OnAddTag = (suggestion) => {
    var newState = this.state.tags;
    newState.push(suggestion.name);
    this.setState({ tags: newState });
    this.setState({ inputTag: "" });
    this.props.tags(this.state.tags.join());
    return "";
  };

  // OnInputTag = (e) => {
  //   e.preventDefault();
  //   this.setState({ inputTag: e.target.value });
  // };

  OnDeleteTag = (e, index) => {
    e.preventDefault();
    let newTags = this.state.tags;
    newTags.splice(index, 1);
    this.setState({ tags: newTags });
    this.props.tags(this.state.tags.join());
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Type related skill",
      value,
      onChange: this.onChange,
    };

    // Finally, render it!
    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.OnAddTag}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />

        <div className="px-2 pt-2 pb-11 mb-3 flex flex-wrap border bg-white items-center rounded-lg mt-2">
          {this.state.tags.map((tag, index) => {
            return (
              <span className="flex flex-wrap pl-4 pr-2 py-2 m-1 justify-between items-center text-sm font-medium rounded-xl cursor-pointer bg-purple-500 text-gray-200 hover:bg-purple-600 hover:text-gray-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-100">
                {tag}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-3 hover:text-gray-300"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  onClick={(e) => this.OnDeleteTag(e, index)}
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            );
          })}
        </div>
      </>
    );
  }
}

export default TagInputWithSuggest;
