import React from "react";
import Autosuggest from "react-autosuggest";
import "./theme.css";

const renderSuggestion = (suggestion) => (
  <div>
    <p className="">{suggestion.title}</p>
    <p className="text-sm text-gray-500">{suggestion.title}</p>
  </div>
);

class FaqAutoComplete extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      tags: [],
      inputTag: "",
      data: [],
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
    this.setState({
      tags: this.props.currentTags ? this.props.currentTags.split(",") : [],
    });
  }
  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const data = this.state.data;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : data.filter(
          (val) =>
            val.content.toLowerCase().includes(inputValue) ||
            val.title.toLowerCase().includes(inputValue)
        );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  OnAddTag = (suggestion) => {
    this.setState({ value: suggestion.title });
    this.props.selected(suggestion);
    return "";
  };

  // OnInputTag = (e) => {
  //   e.preventDefault();
  //   this.setState({ inputTag: e.target.value });
  // };

  OnDeleteTag = (e, index) => {
    e.preventDefault();
    let newTags = this.state.tags;
    newTags.splice(index, 1);
    this.setState({ tags: newTags });
    this.props.tags(this.state.tags.join());
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Search FAQ",
      value: this.state.value,
      onChange: this.onChange,
    };

    // Finally, render it!
    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.OnAddTag}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </>
    );
  }
}

export default FaqAutoComplete;
