import axios from "axios";
export const progressStart = async (candidate_test_id) => {
  try {
    const response = await axios.patch(
      `/api/assessment/progress-start/${candidate_test_id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const progressSubmit = async (testToken, form) => {
  try {
    const response = await axios.post(`/api/assessment/progress-submit`, form, {
      headers: {
        "Content-Type": "application/json",
        "Test-Token": testToken,
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const progressSubmitGroup = async (testToken, form) => {
  try {
    const response = await axios.post(`/api/assessment/progress-submit-group`, form, {
      headers: {
        "Content-Type": "application/json",
        "Test-Token": testToken,
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const progressCheck = async () => {
  try {
    const response = await axios.get(`/api/assessment/progress-check`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const progressFinish = async (testToken, form) => {
  try {
    const response = await axios.post(`/api/assessment/progress-finish`, form, {
      headers: {
        "Content-Type": "application/json",
        "Test-Token": testToken,
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const progressFinishGroup = async (testToken, form) => {
  try {
    const response = await axios.post(`/api/assessment/progress-finish-group`, form, {
      headers: {
        "Content-Type": "application/json",
        "Test-Token": testToken,
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const progressPinalties = async (testToken, form) => {
  try {
    const response = await axios.put(`/api/assessment/progress-pinalties`, form, {
      headers: {
        "Content-Type": "application/json",
        "Test-Token": testToken,
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


