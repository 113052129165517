import React from "react";
import { useState, useEffect } from "react";
import BtnSpinner from "../../../Component/BtnSpinner";
import ContryStateCities from "../../../Component/ContryStateCities";

const CompanyForm = ({ onContinue, updating, userName }) => {
    const [disabled, setDisabled] = useState(true);
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const handleContinue = (e) => {
        e.preventDefault();
        onContinue({ country, province, city, zipCode }, 3);
    };

    useEffect(() => {
        if (country == "" || province == "" || city == "" || zipCode == "") {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [country, province, city, zipCode])
    return (
        <div className="bg-white md:rounded-lg rounded-t-[20px] shadow w-full lg:w-[483px] md:mb-[100px] px-10 py-4 md:py-8 md:h-[340px] h-[100%] translate-y-[50%] md:translate-y-[0%]">
            <div className="w-[50px] h-[5px] bg-grey-text rounded-lg m-auto mb-4 md:hidden"></div>
            <h1 className="font-semibold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                Welcome {userName}
            </h1>

            <div className="w-full justify-center">
                <ContryStateCities
                    onCountryChange={(e) =>
                        setCountry(e)
                    }
                    onStateChange={(e) =>
                        setProvince(e)
                    }
                    onCityChange={(e) => setCity(e)}
                    onZipCodeChange={(e) =>
                        setZipCode(e)
                    }
                    countryValue={country}
                    stateValue={province}
                    cityValue={city}
                    zipCodeValue={zipCode}
                />
            </div>
            <div className="flex mt-5 justify-center">
                <button
                    className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    style={{ textAlign: "-webkit-center" }}
                    disabled={updating || disabled}
                    onClick={handleContinue}
                >
                    {updating ? <BtnSpinner /> : "Continue"}
                </button>
            </div>
        </div>
    )
}

export default CompanyForm;