import React from "react";
import { useState } from "react";
import BtnSpinner from "../../../Component/BtnSpinner";
import DropdownMultipleChoice from "../../../Component/DropdownMultipleChoice/DropdownMultipleChoice";
import axios from "axios";
import PageSpinner from "../../../Component/PageSpinner";


const SpecialConditionForm = ({ onContinue, updating }) => {
    const [fileUpload, setFileUpload] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [description, setDescription] = useState("");
    const [isHave, setIsHave] = useState(false);
    const [user] = useState(() => {
        return JSON.parse(sessionStorage.getItem("user")) || null;
    });
    const [conditions] = useState([
        {
            name: "Partial Impairments",
            caption: ""
        },
        {
            name: "Visual Impairments",
            caption: ""
        },
        {
            name: "Hearing Impairments",
            caption: ""
        },
        {
            name: "High sensitivity sensor",
            caption: ""
        },
        {
            name: "Cognitive Impairments",
            caption: "(ADHD, etc)"
        },
        {
            name: "Neurodivergent conditions",
            caption: "(ASD, dyslexia, etc)"
        },
        {
            name: "Others",
            caption: "Please specify in the description box"
        }
    ]);
    const [condition, setCondition] = useState([])
    const handleContinue = (e) => {
        e.preventDefault();
        onContinue({ special_condition: condition, special_condition_desc: description, special_condition_file_id: fileUpload ? fileUpload.id : null }, 4);
    };


    const handleSelectionChange = (selected) => {
        setCondition(selected);
    };

    const handleNoHave = () => {
        setCondition([]);
        setIsHave(!isHave)
    }

    const handleFile = (e) => {
        setUploading(true);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios
            .post("/api/profile/personal/upload-doc-impairement", formData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                const result = response.data;
                setUploading(false);
                if (result.success) {
                    setFileUpload(result.data)
                }
            })
            .catch(() => {
                setUploading(false);

            });
    }

    //CLEAR
    return (
        <div className="bg-white md:rounded-lg rounded-t-[20px] shadow w-full lg:w-[483px] md:mb-[100px] px-10 py-4 md:py-8 lg:h-[640px] md:h-[340px] h-[100%] translate-y-[50%] md:translate-y-[0%]">
            <div className="w-[50px] h-[5px] bg-grey-text rounded-lg m-auto mb-4 md:hidden"></div>
            <div className="max-h-[90%] px-1 py-1 h-[90%] overflow-auto">
                <h1 className="font-semibold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                    Special Condition
                </h1>
                <p className="text-grey-500 text-[16px] tracking-[.01em] leading-[125%] mb-6">
                    We want to know you to know more about your special condition before taking assessment
                </p>
                <div className="w-full justify-center">
                    <div className="mb-6">
                        <label className="text-gray-800 text-md  \mb-4 leading-[140%] font-medium">
                            Do you have a condition that affects your visual, hearing, memory,  & concentration abilities?
                        </label>
                        <div className="mt-3 mb-3">
                            <DropdownMultipleChoice
                                options={conditions}
                                selectedOptions={condition}
                                onChange={handleSelectionChange}
                                disabled={!isHave}
                            />
                        </div>
                        <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                            type="checkbox"
                            id="inlineCheckbox1"
                            defaultValue="1"
                            checked={!isHave}
                            onChange={() => {
                                handleNoHave()
                            }}
                        />
                        <label
                            className="form-check-label inline-block text-grey-darkest text-[12px] leading-[16.8px] mb-2"
                            htmlFor="inlineCheckbox1"
                        >
                            I dont have any special condition
                        </label>
                        <p className="text-[12px] text-grey-500  leading-[1.5]">
                            If you don’t consider your condition to be a hindrance when taking this test, you can answer “No” to proceed to the assessment.
                        </p>
                    </div>

                    {isHave ? <>
                        <div className="mb-6">
                            <label className="text-gray-800 text-md  mb-4 leading-[140%] font-medium">
                                Special conditions description
                            </label>
                            <textarea
                                className="border mt-3 mb-3 w-full p-3 h-20 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                type="text"
                                name="lastname"
                                id="lastname"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="I have..."
                            ></textarea>
                            <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                                Please clarify your condition and tell us what accommodation you require. Our team will validate your request and configure your assessment within 24 business hours.
                            </p>
                            <p className="text-[14px] text-grey-500  leading-[1.5]">
                                {description.length}/1000
                            </p>
                        </div>
                        <div className="mb-6">
                            <label className="text-gray-800 text-md  mb-4 leading-[140%] font-medium">
                                Upload file (Optional)
                            </label>
                            {uploading ? <PageSpinner padding={"30px"} /> :
                                fileUpload ?
                                    <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                                        <span className="block text-soft-grey text-[14px]">
                                            {fileUpload.original_filename} {/* {profile.resume_file.replace(/^.*[\\\/]/, "")} */}
                                        </span>
                                        <button onClick={() => {
                                            setFileUpload(null);
                                        }}>
                                            <svg
                                                className="h-5 w-5 text-gray-500"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                                <line x1="4" y1="7" x2="20" y2="7" />{" "}
                                                <line x1="10" y1="11" x2="10" y2="17" />{" "}
                                                <line x1="14" y1="11" x2="14" y2="17" />{" "}
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>
                                    </div>
                                    :
                                    <input
                                        className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                        type="file"
                                        onChange={(e) => handleFile(e)}
                                    />}


                            <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                                Maximum 2 Mb
                            </p>
                        </div>
                    </> : <></>}

                </div>
                <div className="flex mt-5 justify-center">
                    <button
                        className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                        style={{ textAlign: "-webkit-center" }}
                        onClick={handleContinue}
                    >
                        {updating ? <BtnSpinner /> : "Continue"}
                    </button>
                </div>
            </div>

        </div>
    )
}

export default SpecialConditionForm;