import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
  setQuestionTimeOver,
  setTestTimeOver,
} from "../features/assessment/assessmentSlice";
import NavbarAssessment from "../Component/NavbarAssessment";
import axios from "axios";
import PageSpinner from "../Component/PageSpinner";
import { QuestionContext } from "../Contexts/QuestionContext";
import domtoimage from "dom-to-image";
import Timer from "../Pages/AssessmentTest/Components/Timer";
import ImageAsync from "../Component/ImageAsync";

import { capitalizeSentences } from "../helpers";
import { Accordion } from "flowbite-react";
import CustomAudioPlayer from "../Component/CustomAudioPlayer";
import TestSectionSlider from "../Component/TestSectionSlider";
import CustomVideoPlayer from "../Component/CustomVideoPlayer";
const QuestionLayout = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [assessmentLoading, setAssessmentLoading] = useState(true);
  const [assessment, setAssessment] = useState(null);
  const [question, setQuestion] = useState(null);
  const [forceFinish, setForceFinish] = useState(false);
  const [showForceFinish, setShowForceFinish] = useState(false);
  const [questionImage, setQuestionImage] = useState(null);
  const questionRef = useRef(null);
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const { candidate_test_id, question_id } = useParams();

  const { deductionInterval } = useSelector((state) => state.assessment.timer);
  const getAssessment = async () => {
    setAssessmentLoading(true);
    await axios
      .get(`/api/assessment/test/${candidate_test_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          // setSummary(result.data.summary)
          setAssessment(result.data);
        }
      })
      .catch((e) => {
        // console.log(e);
      });

    setAssessmentLoading(false);
  };

  const getQuestion = async () => {
    setLoading(true);
    await axios
      .get(`/api/assessment/test/${candidate_test_id}/q/${question_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setShowForceFinish(true);
          setQuestion(result.data);
        }
      })
      .catch((e) => {
        // console.log(e);
      });

    setLoading(false);
  };

  const handleForceFinish = () => {
    setForceFinish(true);
  };

  const handleQuestionTimeOver = () => {
    dispatch(setQuestionTimeOver(true));
  };

  const handleTestTimeOver = () => {
    dispatch(setTestTimeOver(true));
  };

  const convertToImage = () => {
    const element = questionRef.current;
    if (element) {
      domtoimage
        .toPng(element)
        .then(function (dataUrl) {
          setQuestionImage(dataUrl);
        })
        .catch(function (error) {
          // Handle error
          // console.error("Error converting element to image:", error);
        });
    }
  };

  useEffect(() => {
    getQuestion();
  }, [question_id]);

  useEffect(() => {
    getAssessment();
  }, []);

  useEffect(() => {
    if (!loading) {
      convertToImage();
    }
  }, [loading]);

  const ContentJson = ({ content, section = null }) => {
    return (
      <>
        {section && (
          <>
            <p className="text-gray-900 leading-[180%] mb-4">
              {capitalizeSentences(
                "This question has multiple sections. Read each section carefully before answering."
              )}
            </p>
            {/* ACCORDION VERSION */}
            {/* <Accordion collapseAll>
              {section?.map((sc, index) => {
                return (
                  <Accordion.Panel>
                    <Accordion.Title id={"sc-" + index}>
                      {sc.title}
                    </Accordion.Title>
                    <Accordion.Content>
                      {sc.content_array.map((ct) => {
                        if (
                          content[ct].type === "text" &&
                          content[ct].content != null
                        ) {
                          return (
                            <div key={index}>
                              <p className="text-gray-900 leading-[180%] mb-4">
                                {capitalizeSentences(content[ct].content)}
                              </p>
                            </div>
                          );
                        } else if (
                          content[ct].type === "image" &&
                          content[ct].content != null
                        ) {
                          return (
                            <div key={index} className="overflow-y-hidden mb-4">
                              <img
                                src={content[ct].content}
                                className="max-h-[360px]"
                                alt="content"
                              />
                            </div>
                          );
                        } else if (
                          content[ct].type === "audio" &&
                          content[ct].content != null
                        ) {
                          return (
                            <div key={index} className="overflow-y-hidden mb-4">
                              <CustomAudioPlayer
                                src={content[ct].content}
                                preventStop={true}
                              />
                            </div>
                          );
                        } else if (
                          content[ct].type === "html" &&
                          content[ct].content != null
                        ) {
                          return (
                            <div
                              key={index}
                              className="prose overflow-y-hidden mb-4"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: content[ct].content,
                                }}
                                className="text-gray-900 leading-[180%]"
                              />
                            </div>
                          );
                        }
                      })}
                    </Accordion.Content>
                  </Accordion.Panel>
                );
              })}
            </Accordion> */}
            <TestSectionSlider>
              {section?.map((sc, index) => {
                return (
                  <>
                    <h2 className="mb-2 font-semibold text-md">{sc.title}</h2>
                    {sc.content_array.map((ct) => {
                      if (
                        content[ct].type === "text" &&
                        content[ct].content != null
                      ) {
                        return (
                          <div key={index}>
                            <p className="text-gray-900 leading-[180%] mb-4">
                              {capitalizeSentences(content[ct].content)}
                            </p>
                          </div>
                        );
                      } else if (
                        content[ct].type === "image" &&
                        content[ct].content != null
                      ) {
                        return (
                          <div key={index} className="overflow-y-hidden mb-4">
                            <img
                              src={content[ct].content}
                              className="max-h-[360px]"
                              alt="content"
                            />
                          </div>
                        );
                      } else if (
                        content[ct].type === "audio" &&
                        content[ct].content != null
                      ) {
                        return (
                          <div key={index} className="overflow-y-hidden mb-4">
                            <CustomAudioPlayer
                              src={content[ct].content}
                              preventStop={true}
                            />
                            {/* <audio controls className="">
                                <source
                                  src={content[ct].content}
                                  type="audio/mp3"
                                />
                                Your browser does not support the audio element.
                              </audio> */}
                          </div>
                        );
                      } else if (
                        content[ct].type === "html" &&
                        content[ct].content != null
                      ) {
                        return (
                          <div
                            key={index}
                            className="prose overflow-y-auto mb-4"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content[ct].content,
                              }}
                              className="text-gray-900 leading-[180%]"
                            />
                          </div>
                        );
                      }
                    })}
                  </>
                );
              })}
            </TestSectionSlider>
          </>
        )}
        {!section && (
          <>
            {content.map((item, index) => {
              if (item.type === "text" && item.content != null) {
                return (
                  <div key={index}>
                    <p className="text-gray-900 leading-[180%] mb-4">
                      {capitalizeSentences(item.content)}
                    </p>
                  </div>
                );
              } else if (item.type === "image" && item.content != null) {
                return (
                  <div key={index} className="overflow-y-hidden mb-4">
                    {/* <ImageAsync imageUrl={item.content} /> */}
                    {/* <img src={process.env.REACT_APP_BASE_URL + "/api/public" + item.content} className="max-w-[420px]" alt='content' /> */}
                    <img
                      src={item.content}
                      // className="max-w-[350px] mdb:max-w-[620px]"
                      className="max-h-[360px]"
                      alt="content"
                    />
                  </div>
                );
              } else if (item.type === "audio" && item.content != null) {
                return (
                  <div key={index} className="overflow-y-hidden mb-4">
                    <CustomAudioPlayer src={item.content} preventStop={true} />
                    {/* <audio controls>
                      <source src={item.content} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio> */}
                  </div>
                );
              } else if (item.type === "video" && item.content != null) {
                return (
                  <div key={index} className="overflow-y-hidden mb-4">
                    <CustomVideoPlayer src={item.content} preventStop={true} />
                    {/* <video className="w-full" controls controlsList="nodownload nofullscreen noremoteplayback">
                      <source src={item.content} type="video/mp4" />
                      Your browser does not support the video element.
                    </video> */}
                  </div>
                );
              } else if (item.type === "html" && item.content != null) {
                return (
                  <div key={index} className="prose overflow-y-auto mb-4">
                    {/* <ImageAsync imageUrl={item.content} /> */}
                    {/* <img src={process.env.REACT_APP_BASE_URL + "/api/public" + item.content} className="max-w-[420px]" alt='content' /> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                      className="text-gray-900 leading-[180%]"
                    />
                  </div>
                );
              }
              return null;
            })}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    // console.log(deductionInterval)
  }, [deductionInterval]);
  return (
    <>
      <div className="h-screen flex flex-col over">
        <QuestionContext.Provider
          value={{
            question,
            setQuestion,
            assessment,
            setAssessment,
            candidateTestId: candidate_test_id,
            assessmentLoading: assessmentLoading,
            forceFinish: forceFinish,
            loadingQuestion: loading,
          }}
        >
          <NavbarAssessment
            assessment={assessment}
            playTimer={true}
            deductionInterval={deductionInterval}
            setTimeOver={(e) => handleTestTimeOver()}
            showForceFinish={showForceFinish}
            setForceFinish={(e) => {
              handleForceFinish();
            }}
          />
          <div className="fit-height flex-1 overflow-y-hidden bg-gradient-to-r from-purple-400 via-purple-600 to-white mdb:px-2 mdb:py-3">
            <div className="w-full flex flex-col md:flex-row flex-1 overflow-y-hidden mdb:px-2 md:px-0 mdb:gap-4 gap-0 md:gap-1 h-full">
              <div className="bg-main-violate-50 w-full lg:w-[50%] md:w-[50%] xl:w-[40%] flex flex-col max-h-[320px] md:max-h-full mdb:rounded-lg lg:py-[30px] lg:px-[30px] xl:px-[30px] xl:py-[30px]">
                {loading ? (
                  <PageSpinner />
                ) : (
                  <>
                    <div className="box flex-1 flex flex-col overflow-y-auto p-2">
                      {assessment ? (
                        <>
                          {assessment.company_assessment_test.assessment_test
                            .time_type == "in_question" ? (
                            <>
                              <div className="timer mb-10">
                                <Timer
                                  label={"Question Time"}
                                  play={true}
                                  time={
                                    sessionStorage.getItem("latest_timer")
                                      ? sessionStorage.getItem("latest_timer")
                                      : question.times
                                  }
                                  deductionInterval={deductionInterval}
                                  setTimeOver={(e) => handleQuestionTimeOver()}
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      <div className="order h-[40px] mdb:h-[80px]">
                        {question.variant == "group" ? (
                          <p className="text-gray-900 font-bold text-lg mdb:text-xl mb-6">
                            Question {question.real_order}{" "}
                            {question.questions.length > 1 &&
                              "-" +
                                " " +
                                (question.real_order +
                                  question.questions.length -
                                  1)}
                          </p>
                        ) : (
                          <p className="text-gray-900 font-bold text-lg mdb:text-xl mb-6">
                            Question {question.real_order}
                          </p>
                        )}
                      </div>
                      <div className="question-content overflow-y-auto flex-1 flex flex-col">
                        {/* {questionImage ? (
                      <img src={questionImage} alt="Question" />
                    ) : ( */}
                        <div className="flex flex-col flex-1 overflow-y-auto">
                          {question.context && (
                            <>
                              {question.context_kind === "json" ? (
                                <ContentJson content={question.context} />
                              ) : (
                                <p className="text-gray-900 font-semibold leading-[180%] mb-4">
                                  {question.context}
                                </p>
                              )}
                            </>
                          )}
                          {/* {question.context && <hr className="mb-4" />} */}
                          {question.context ? (
                            <>
                              {/* {question.kind === "text" && <><p className="text-gray-900 leading-[180%]">{question.content}</p></>}
                          {question.kind === "html" && <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: question.content.replace(/\\n/g, "<br>"),
                              }}
                              ref={questionRef}
                              className="text-gray-900 leading-[180%]"
                            /></>}
                          {question.kind === "json" && <>
                            <ContentJson content={question.content} />
                          </>} */}
                            </>
                          ) : (
                            <>
                              {question.kind === "text" && (
                                <>
                                  <p className="text-gray-900 leading-[180%] text-[16px]">
                                    {question.content}
                                  </p>
                                </>
                              )}
                              {/* {question.kind === "html" && (
                                <>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: question.content.replace(
                                        /\\n/g,
                                        "<br>"
                                      ),
                                    }}
                                    ref={questionRef}
                                    className="text-gray-900 leading-[180%]"
                                  />
                                </>
                              )} */}
                              {question.kind === "json" && (
                                <>
                                  <ContentJson
                                    section={question?.section}
                                    content={question.content}
                                  />
                                </>
                              )}
                              {question.kind === "html" && (
                                <>
                                  <ContentJson
                                    section={question?.section}
                                    content={question.content}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="content flex-1 w-full lg:w-[50%] md:w-[50%] xl:w-[60%] overflow-y-hidden">
                <Outlet />
              </div>
            </div>
          </div>
        </QuestionContext.Provider>
      </div>
    </>
  );
};

export default QuestionLayout;
