import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import CornersOut from "../../Assets/CornersOut.svg";
import exampleVideo from "../../Assets/example_video.mp4";
import "./style.css";
import CornersIn from "../../Component/Icons/CornersIn";
import PageSpinner from "../../Component/PageSpinner";
import { CheckIcon } from "@heroicons/react/24/solid";
import { AssessmentContext } from "../../Contexts/AssessmentContext";
import { documentTitle } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  handleProgressStart,
  setToken,
} from "../../features/assessment/assessmentSlice";
import BtnSpinner from "../../Component/BtnSpinner";
import { progressStart } from "../../api";

const AssessmentExample = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const { token } = useSelector((state) => state.assessment);
  const [progressStarting, setProgressStarting] = useState(false);
  const { assessment } = useContext(AssessmentContext);
  const [loading, setLoading] = useState(true);
  // const [firstQuestionId, setFirstQuestionId] = useState("");
  const { candidate_test_id } = useParams();
  const [full, setFull] = useState(false);
  const fullVideo = (e) => {
    if (full) setFull(false);
    else setFull(true);
  };
  useEffect(() => {
    if (assessment) {
      setLoading(false);
    }
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("Assessment Example");
  }, []);

  const goTest = async (e) => {
    e.preventDefault();
    // dispatch(handleProgressStart(candidate_test_id));
    // progressStart();
    setProgressStarting(true);
    const result = await progressStart(candidate_test_id);
    if (result.success) {
      dispatch(setToken(result.data.token));
      const answers = JSON.parse(sessionStorage.getItem("answers"));
      if (answers) {
        // CALL LAST INDEX OF ANSWERS
        const lastAnswer = answers[answers.length - 1];
        let currentQuestion = null;
        if (lastAnswer.variant == "group") {
          // currentQuestion = Math.max(
          //   ...lastAnswer.questions.map((question) => question.next_question)
          // );
          currentQuestion = lastAnswer.next_question;
        } else {
          currentQuestion = lastAnswer.next_question;
        }
        navigate(`/assessment/test/${candidate_test_id}/q/${currentQuestion}`);
      } else {
        navigate(
          `/assessment/test/${candidate_test_id}/q/${assessment.first_question}`
        );
        // navigate(
        //   `/assessment/test/${candidate_test_id}/q/${assessment.company_assessment_test.assessment_test.first_question}`
        // );
      }
    }
    setProgressStarting(false);
  };

  // check if logged in /test
  return (
    <>
      <ToastContainer />
      <div className="bg-white rounded-lg shadow mb-[40px] h-full flex flex-col ">
        <div className="card hidden mdb:block">
          <div className="w-full py-6">
            <div className="flex">
              <div className="w-1/2">
                <div className="relative mb-2">
                  <div className="w-12 h-12 mx-auto bg-main-violate-100 rounded-full text-lg flex items-center justify-center">
                    <CheckIcon width={28} className="text-main-violate-600" />
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative mb-2">
                  <div
                    className="absolute flex align-center items-center align-middle content-center"
                    style={{
                      width: "calc(100% - 2.5rem - 1rem)",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div className="w-full bg-main-violate-100 rounded items-center align-middle align-center flex-1">
                      <div className="w-0 bg-main-violate-100 py-1 rounded"></div>
                    </div>
                  </div>
                  <div className="w-12 h-12 mx-auto bg-main-violate-100 rounded-full text-lg flex items-center justify-center">
                    <CheckIcon width={28} className="text-main-violate-600" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {full ? (
          <>
            <div className="mb-8 video-container-full">
              <video width="100%" height="100%" autoPlay="autoplay" muted loop>
                <source src={exampleVideo} type="video/mp4"></source>
              </video>
              <div
                className="player-buttons cursor-pointer"
                onClick={(e) => fullVideo(e)}
              >
                <CornersIn color="white" size={"24px"} />
              </div>
              <div
                className="assesment-buttons-overlay"
                onClick={(e) => fullVideo(e)}
              >
                <button
                  className="bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg  hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                  type="button"
                >
                  I Understand
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center h-full">
              <div className="px-6 flex flex-col max-w-[840px] h-full justify-center space-y-10">
                {loading ? (
                  <PageSpinner />
                ) : (
                  <>
                    {/* <h2 className="text-main-violate text-[24px] font-bold leading-7 tracking-[.01em] mb-3">
                      {test.name}
                    </h2>
                    <h3 className="text-grey text-[20px] font-light leading-6 tracking-[.01em] mb-6">
                      {test.assessment.name}
                    </h3> */}
                    <div>
                      {/* <p className="font-bold text-black mb-4 text-lg">
                        {assessment.company_assessment_test.assessment_test.description}
                      </p> */}
                      <p className="font-bold text-black mb-4 text-lg">
                        Petunjuk
                      </p>
                      <ul className="list-disc list-inside px-2 leading-8 mdb:mb-10  font-semibold ">
                        <li>
                          Sebelum Anda memulai, pastikan Anda berada dalam
                          lingkungan yang tenang dan bebas gangguan untuk hasil
                          yang lebih akurat.
                        </li>
                        <li>
                          Ikuti instruksi dengan seksama, dan jujurlah dalam
                          menjawab pertanyaan-pertanyaan yang diberikan.
                        </li>
                        <li>
                          Kami sangat menjaga kerahasiaan data Anda. Privasi
                          Anda adalah prioritas kami, dan data yang Anda berikan
                          akan dijaga dengan ketat.
                        </li>
                      </ul>
                      {/* <p className="text-gray-600 mb-6">
                        Rules : Don’t switch tab and moving out of the camera
                      </p> */}
                    </div>

                    {/* <p className="text-black font-bold mb-4">Tutorial</p>

                    <div className="mb-8 video-container">
                      <video
                        width="480"
                        height="320"
                        autoplay="autoplay"
                        muted
                        loop
                      >
                        <source
                          src={exampleVideo}
                          type="video/mp4"
                        ></source>
                      </video>
                      <div
                        className="player-buttons cursor-pointer"
                        onClick={(e) => fullVideo(e)}
                      >
                        <CornersOut color="white" size={"24px"} />
                      </div>
                    </div> */}
                    <div className="flex ">
                      <button
                        className="bg-main-violate w-[200px] px-4 text-[16px] text-white font-semibold h-[46px] rounded-lg mt-4 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                        onClick={(e) => goTest(e)}
                        disabled={progressStarting}
                      >
                        {progressStarting ? <BtnSpinner /> : "Start Assessment"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AssessmentExample;
