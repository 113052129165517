import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setResumes } from "../../../features/profile/profileSlice";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import { toast } from "react-toastify";
import {
  ArrowRightCircleIcon,
  DocumentIcon,
  InformationCircleIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import FileUploadIcon from "../../../Component/Icons/FileUploadIcon";
import moment from "moment";
import DropdownDots from "../../../Component/DropdownDots";
import { Dropdown } from "flowbite-react";
import {
  deleteResumes,
  getResumes,
  postResumes,
  uploadResumes,
} from "../../../api";
const ResumeSection = ({}) => {
  const { data: resumes, loaded } = useSelector(
    (state) => state.profile.resumes
  );
  const dispatch = useDispatch();
  const [, setValidations] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);

  const [form, setForm] = useState({
    file_id: null,
  });

  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const result = await postResumes(form);
    if (result.success) {
      setFileUpload(false);
      setForm({ ...form, file_id: null });
      fetchResumes();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(result.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setSaving(false);
  };

  const handleFile = async (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("resume_file", e.target.files[0]);
    const result = await uploadResumes(formData);
    if (result.success) {
      setFileUpload(result.data);
      setForm({ ...form, file_id: result.data.id });
    } else {
      toast.error(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setUploading(false);
  };
  const fetchResumes = async () => {
    setLoading(true);
    const result = await getResumes();
    if (result.success) {
      dispatch(setResumes(result.data));
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  const handlePreview = () => {};

  const handleDelete = async (id) => {
    setLoading(true);
    const result = await deleteResumes(id);
    if (result.success) {
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      fetchResumes();
    } else {
      toast.error(error.response.data.message, {
        toastId: "forgot-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showModal) setValidations({});
  }, [showModal]);

  useEffect(() => {
    async function getInformationApi() {
      if (!loaded) {
        await fetchResumes();
      }
    }
    getInformationApi();
  }, []);

  // const updateProfileData = () => {

  // };
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white cv-section">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <DocumentIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Curriculum Vitae
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            {resumes.length > 0 ? (
              <PencilIcon width={22} />
            ) : (
              <PlusIcon width={24} />
            )}

            {/* <img src={Plus} alt="icon" className="h-[24px] w-[24px]" /> */}
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <DocumentIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3">
                        Curriculum Vitae
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <div
                        className="bg-grey-lightest flex flex-col items-center"
                        name="work_experience"
                      >
                        <div className="max-h-[60vh] overflow-y-auto w-full max-w-lg">
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Curriculum Vitae
                            </label>
                            {uploading ? (
                              <PageSpinner padding={"30px"} />
                            ) : fileUpload ? (
                              <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                                <span className="block text-soft-grey text-[14px]">
                                  {fileUpload.original_filename}{" "}
                                  {/* {profile.resume_file.replace(/^.*[\\\/]/, "")} */}
                                </span>
                                <button
                                  onClick={() => {
                                    setFileUpload(null);
                                  }}
                                >
                                  <FileUploadIcon />
                                </button>
                              </div>
                            ) : (
                              <input
                                className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                type="file"
                                accept=".pdf"
                                onChange={(e) => handleFile(e)}
                              />
                            )}
                            <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                              Maximum <b>PDF</b> size 2 Mb
                            </p>
                          </div>
                        </div>
                        <div className="resumes w-full">
                          {loading ? (
                            <PageSpinner padding={"30px"} />
                          ) : (
                            <>
                              {resumes.map((resume) => (
                                <div className="card-mini w-full mb-2">
                                  <div className="content flex items-center">
                                    <div className="left-side w-[90%]">
                                      <p className="font-semibold text-gray-900 mb-1">
                                        {resume.file_name}
                                      </p>
                                      <p className="font-normal text-gray-500">
                                        Uploaded on{" "}
                                        {moment(resume.created_at).format(
                                          "DD MMMM YYYY"
                                        )}{" "}
                                      </p>
                                    </div>
                                    <div className="left-side w-[10%]">
                                      <DropdownDots>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handlePreview(resume.id)
                                          }
                                        >
                                          <InformationCircleIcon
                                            color="#6B7280"
                                            width={16}
                                            className="mr-2"
                                          />{" "}
                                          Preview
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDelete(resume.id)
                                          }
                                        >
                                          <InformationCircleIcon
                                            color="#6B7280"
                                            width={16}
                                            className="mr-2"
                                          />{" "}
                                          Delete
                                        </Dropdown.Item>
                                      </DropdownDots>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                        <div className="flex mt-5 justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving || !fileUpload}
                            onClick={submit}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                            onClick={() => setShowModal(false)}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {resumes.length > 0 ? (
              <>
                {resumes.map((resume) => {
                  return (
                    <div className="card-mini w-full mb-2">
                      <div className="content flex items-center">
                        <div className="left-side w-[90%]">
                          <p className="font-semibold text-gray-900 mb-1">
                            {resume.file_name}
                          </p>
                          <p className="font-normal text-gray-500 mb-3">
                            Uploaded on{" "}
                            {moment(resume.created_at).format("DD MMMM YYYY")}{" "}
                          </p>
                          <div className="rounded-lg border px-2 py-2 border-gray-200 w-full mb-2 max-w-max flex items-center">
                            <p className="text-sm font-medium mr-2">
                              Preview CV
                            </p>
                            <ArrowRightCircleIcon width={18} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ResumeSection;
