import { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import NavbarB2C from "../Component/NavbarB2C";
import Footer from "../Component/FooterLanding";
import NavbarLanding from "../Component/NavbarLanding";
import config from "../config";
const LandingLayout = () => {
  const [user, setUser] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="relative">
        <div className="w-full fixed md:static z-50">
          <NavbarLanding />
        </div>
        <div className="z-0">
          <div className="z-0">
            <div className="py-[70px] mdb:py-[0px]">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {/* <div className="w-full fixed md:static z-50">
        <NavbarB2C />
      </div> */}
    </>
  );
};

export default LandingLayout;
