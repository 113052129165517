import React, { Component } from 'react';
import ImageAsync from './ImageAsync';

class AssessmentContentJson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }
    render() {

        const { question } = this.props;
        return question.map((item, index) => {
            if (item.type === 'text') {
                return (
                    <div key={index}>
                        <p className="text-gray-900 leading-[180%] mb-2">{item.content}</p>
                    </div>
                );
            } else if (item.type === 'image') {
                return (
                    <div key={index} className='overflow-y-hidden'>
                        {/* <ImageAsync imageUrl={item.content} className="max-w-[420px]" /> */}
                        {/* <img src={process.env.REACT_APP_BASE_URL + "/api/public" + item.content} className="max-w-[420px]" alt='content' /> */}
                        <img src={item.content} className="max-w-[580px]" alt='content' />
                    </div>
                );
            }
            return null;
        })

    }
}

export default AssessmentContentJson;
