import React, { Component } from "react";

export class BeforeUnload extends Component {
  componentDidMount() {
    window.onbeforeunload = function () {
      this.onUnload();
      return "";
    }.bind(this);
  }
  render() {
    return (
      <></>
    );
  }
}
export default BeforeUnload;