import axios from "axios";
export const getAssessments = async () => {
  try {
    const response = await axios.get(`/api/dashboard/assessment`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
