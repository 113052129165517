import {
  useNavigate, useParams,
} from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { CheckIcon } from "@heroicons/react/24/outline";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/solid"
import { AssessmentContext } from "../../Contexts/AssessmentContext";
import PageSpinner from "../../Component/PageSpinner";
import { documentTitle } from "../../helpers";
const Preparation = () => {
  let navigate = useNavigate();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const { assessment } = useContext(AssessmentContext);
  const [loading, setLoading] = useState(true);
  const { candidate_test_id } = useParams();
  useEffect(() => {
    documentTitle("Assessment Preparation")
  }, [])
  // check if logged in
  useEffect(() => {
    if (assessment) {
      setLoading(false);
    }
    if (!user) {
      navigate("/login", { replace: true });
    }
  });
  return (
    <>
      <ToastContainer />
      <div className="bg-white rounded-lg shadow mb-[40px] h-full">
        <div className='card mb-6 hidden mdb:block'>
          <div className="w-full py-6">
            <div className="flex">
              <div className="w-1/2">
                <div className="relative mb-2">
                  <div className="w-12 h-12 mx-auto bg-main-violate-100 rounded-full text-lg flex items-center justify-center">
                    <CheckIcon width={28} className='text-main-violate-600' />
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative mb-2">
                  <div className="absolute flex align-center items-center align-middle content-center" style={{ width: "calc(100% - 2.5rem - 1rem)", top: "50%", transform: "translate(-50%, -50%)" }}>
                    <div className="w-full bg-main-violate-100 rounded items-center align-middle align-center flex-1">
                      <div className="w-0 bg-main-violate-100 py-1 rounded"></div>
                    </div>
                  </div>
                  <div className="w-12 h-12 mx-auto bg-main-violate-100 rounded-full text-lg flex items-center justify-center">
                    <ClipboardDocumentListIcon width={28} className='text-main-violate-600' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-t-lg">
          {loading ? <PageSpinner /> : <>
            <div className="px-6 py-8 mx-auto w-full flex flex-col items-center">
              <div className="max-w-[740px]">
                <h2 className="font-bold text-xl mb-4 ">
                  Preparation
                </h2>
                <p className="text-slate-600 mb-4">
                  Before you start the assessment,  there is a few things to know before you start.
                </p>
                <ul className="list-disc list-inside px-2 leading-8 mb-10">
                  <li>
                    This assessment consists of {assessment.company_assessment_test.assessment_test.total_question} questions, and it will
                    take approximately 15 minutes to complete
                  </li>
                  <li>
                    This assessment is timed. A timer will be shown per test
                    or per question;
                  </li>
                  <li>
                    Please allow the use of your camera/ webcam and do not
                    leave full-screen mode. Snapshots will be taken of you
                    periodically during the assessment. These measuers are
                    taken to ensure fairness for everyone;
                  </li>
                  <li>
                    Turn on your speakers or headphones (to play audio);
                  </li>
                  <li>You are free to use pen and paper;</li>
                  <li>We recommend completing the assessment in one go.</li>
                </ul>
                <button
                  className="bg-main-violate  px-4 text-[16px] text-white font-semibold h-[46px] rounded-lg mt-4 hover:bg-violet-600"
                  type="submit"
                  onClick={() => navigate(`/assessment/test/${candidate_test_id}/introduction`)}
                >
                  Proceed
                </button>
              </div>

            </div>
          </>}

        </div>
      </div>
    </>
  );
};

export default Preparation;
