export const TEST_COMPLETE = "finished";
export const TEST_READY = "ready";
export const TEST_QUEUE = "onqueue";

// ASSESSMENT TYPE
export const QUESTION_GROUP_TYPE = "group";
export const QUESTION_SINGLE_TYPE = "single";

// BRAND
export const BRAND_HIGHR_1 = "Highr ID";
export const BRAND_HIGHR_2 = "Highr Indonesia";