import React, { Component } from "react";
import logo from "../Assets/HumataLogo.png";
import notif from "../Assets/BellOutline.png";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { ClockIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import Timer from "../Pages/AssessmentTest/Components/Timer";
import BtnSpinner from "./BtnSpinner";

export class NavbarAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: "",
        role: "",
        token: "",
        email: "",
        avatar: "",
        company_slug: "",

      },
      signedOut: false,
      submitting: false,
    };
  }

  // componentWillUnmount(){
  //   alert("GOOD BYE");
  // }

  handleOpenSidebar() {
    this.props.openSidebar()
  }
  componentDidMount() {
    const user = JSON.parse(sessionStorage.getItem("user")) || null;
    if (user) {
      this.setState({ user: user });
    }
    // this.setState({...user, user: })
  }

  signOut = () => {
    sessionStorage.removeItem("user");
    window.location.replace("/login");
  }

  render() {
    const { assessment, playTimer, setForceFinish } = this.props;
    return (
      // <div>
      //   <nav className="bg-white shadow-lg w-[100%]">
      //     <div className="flex justify-between px-2 h-[80px]">
      //       <a href="#" className="flex items-center py-3 px-2">
      //         <img src={logo} alt="Logo" className="h-[80px] w-[80px] mr-2 mb-3"/>
      //       </a>
      //       <div className="notification flex justify-start items-center py-3 px-2">
      //         <img src={notif} alt="notif" className="h-[32px] w-[32px] mr-2 mb-3"/>
      //         <div className="rounded-full bg-secondary-orange w-[14px] h-[14px] ml-[-22px] mt-[-27px]"></div>
      //         <div className="font-semibold text-white text-[14px] w-[32px] h-[32px] rounded-full bg-dark-blue flex items-center justify-center mr-[30px] mb-3 ml-[15px]">
      //           <button type="button" className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
      //               <span className="sr-only">Open user menu</span>
      //               <img className="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-3.jpg" alt="user photo"/>
      //           </button>
      //         </div>
      //       </div>
      //     </div>
      //   </nav>
      // </div>
      <Navbar fluid={true} rounded={true} style={{ padding: "10px 10px", borderBottom: "solid #EDEDED 1px" }}>
        <Navbar.Brand href="/">
          <img
            src={logo}
            className="w-[60px] m-2 hidden mdb:block mr-4"
            alt="Flowbite Logo"
          />
          <div>
            <p className="text-gray-500 font-medium">Assessment</p>
            <p className="text-gray-900 font-medium">{assessment == null ? "-" : assessment.company_assessment_test.assessment_test.name}</p>
          </div>
          {/* <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            Flowbite
          </span> */}
        </Navbar.Brand>
        <div className="flex mdb:flex-row flex-col md:order-2 mdb:items-center items-end">
          {assessment ? <>
            {assessment.company_assessment_test.assessment_test.time_type == "in_test" ? <>
              <div className="mdb:mr-4 mb-1 mdb:mb-0">
                <Timer label={"Test Time"} play={playTimer} time={sessionStorage.getItem("latest_timer") ? sessionStorage.getItem("latest_timer") : assessment.company_assessment_test.assessment_test.times} deductionInterval={this.props.deductionInterval} setTimeOver={(e) => this.props.setTimeOver()} />
              </div>

            </> : ""}
            {
              this.props.showForceFinish ? <>
                <button
                  className="mdb:mr-8 text-right mdb:text-[16px] text-[14px] mdb:border mdb:border-main-violate-700 text-main-violate-700 mdb:px-4 text-smleading-[22.4px] text-grey-98 font-medium mdb:h-[44px] rounded-lg hover:bg-main-violate-50 hover:border-main-violate-900 hover:text-main-violate-900 focus:outline-none"
                  onClick={() => {
                    if (window.confirm("Are you sure you wish to finish the test?")) {
                      setForceFinish(true)
                      this.setState({ submitting: true })
                    }
                  }}
                >
                  {this.state.submitting ? <BtnSpinner /> : 'Force Finish'}

                </button></> : <></>
            }

          </> : ''}

          {/* <Dropdown
            arrowIcon={false}
            inline={true}
            label={
              <Avatar
                alt="User settings"
                img={this.state.user.avatar}
                rounded={true}
              />
            }
          >
            <Dropdown.Header>
              <span className="block text-sm">{this.state.user.name}</span>
              <span className="block truncate text-sm font-medium">
                {this.state.user.email}
              </span>
            </Dropdown.Header>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.signOut}>Sign out</Dropdown.Item>
          </Dropdown> */}
          {/* <Navbar.Toggle className="focus:outline-none active:bg-white focus:bg-white focus:border-none" onClick={(e) => {
            this.handleOpenSidebar();
          }} /> */}
        </div>
        {/* <Navbar.Collapse>
          <Navbar.Link href={`/assessment/${this.state.user.company_slug}`} active={true}>
            Assessment
          </Navbar.Link>
          <Navbar.Link href="/navbars">About</Navbar.Link>
          <Navbar.Link href="/navbars">Services</Navbar.Link>
          <Navbar.Link href="/navbars">Pricing</Navbar.Link>
          <Navbar.Link href="/navbars">Contact</Navbar.Link>
        </Navbar.Collapse> */}
      </Navbar>
    );
  }
}
export default NavbarAssessment;
