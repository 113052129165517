import React, { Component } from "react";
// import "./index.css";
import moment from "moment";
import domtoimage from "dom-to-image";

export class AssessmentSingle extends React.Component {
  constructor() {
    super();
    this.state = {
      answer: "",
      questionImage: null,
    };
    this.questionRef = React.createRef(null);
  }

  componentDidMount() {
    this.convertToImage();
  }
  convertToImage() {
    const self = this;
    const element = this.questionRef.current;
    if (element) {
      domtoimage
        .toPng(element)
        .then(function (dataUrl) {
          self.setState({ questionImage: dataUrl });
        })
        .catch(function (error) {
          // Handle error
          // console.error("Error converting element to image:", error);
        });
    }
  }
  setAnswer(answer) {
    let tempAnswers;
    if (this.props.question.id !== this.state.id) {
      tempAnswers = "";
      this.setState({ id: this.props.question.id, answer: "" }, () => {});
    } else {
      tempAnswers = this.state.answer;
    }
    this.setState({ answer: tempAnswers });
    this.props.answer({ ...this.props.question, answer: answer });
  }

  render() {
    const {
      id = "",
      content = "",
      order = "",
      type = null,
      kind = "",
      score = "",
      times = "",
      options = [],
    } = this.props.question;
    const currentAnswer = this.props.currentAnswer;
    return (
      <div className="rounded-[8px] w-full mdb:px-5 mdb:py-5 px-2 py-2 mt-2 mdb:mt-4">
        <div className="mdb:mb-5 mb-2">
          {/* {this.state.questionImage ? (
            <img src={this.state.questionImage} alt="Question" />
          ) : (
            <h2
              className="w-[653px] text-[16px] leading-[140%]"
              ref={this.questionRef}
            >
              {order}. {content}
            </h2>
          )} */}
          <h2 className="text-lg font-bold">Select an Option</h2>
        </div>

        {options.map((option, index) => {
          return (
            <>
              {currentAnswer.find((curr) => curr.id == id) ? (
                <div
                  key={index}
                  className={`rounded-[8px] border ${
                    currentAnswer.find((curr) => curr.id == id).answer ==
                    option.id
                      ? "border-main-violate"
                      : "border-line-gray-200 hover:border-2 hover:font-bold"
                  } px-5 py-3 mt-3  cursor-pointer `}
                  onClick={(e) => this.setAnswer(option.id)}
                >
                  <p className="text-[16px] leading-[140%] no-select">
                    {option.value}
                  </p>
                </div>
              ) : (
                <div
                  key={index}
                  className={`rounded-[8px] border ${
                    this.state.answer == option.id
                      ? "border-main-violate"
                      : "border-line-gray-200 hover:border-2 hover:font-bold"
                  } px-5 py-3 mt-3  cursor-pointer `}
                  onClick={(e) => this.setAnswer(option.id)}
                >
                  <p className="text-[16px] leading-[140%] no-select">
                    {option.value}
                  </p>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  }
}
