import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../Component/FooterDashboard";
import PageSpinner from "../../Component/PageSpinner";
import config from "../../config";
import {
  CheckIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  LanguageIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import { useSelector } from "react-redux";
import { documentTitle } from "../../helpers";
import { useTour } from "@reactour/tour";
import AboutSection from "../DashboardProfilePage/Sections/AboutSection";
import WorkExperienceSection from "../DashboardProfilePage/Sections/WorkExperienceSection";
import EducationalSection from "../DashboardProfilePage/Sections/EducationalSection";
import SkillSection from "../DashboardProfilePage/Sections/SkillSection";
import SocialNetworkSection from "../DashboardProfilePage/Sections/SocialNetworkSection";
import PreferenceSection from "../DashboardProfilePage/Sections/PreferenceSection";
import PersonalSection from "../DashboardProfilePage/Sections/PersonalSection";
import PersonalSalarySection from "../DashboardProfilePage/Sections/PersonalSalarySection";

const AssessmentDashboard = () => {
  const activeTest = useSelector((state) => state.assessment.active);
  const { setIsOpen, isOpen } = useTour();
  let navigate = useNavigate();
  const [user, setUser] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const [activeFocus, setActiveFocus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allowAssessment, setAllowAssessment] = useState(false);
  const [summary, setSummary] = useState({
    job_department: "",
    job_position: "",
    job_role: "",
    total_assessment: "",
    finished_assessment: "",
    deadline: "2023-01-01",
  });
  const [profile, setProfile] = useState({
    about: null,
    experiences: [],
    educations: [],
    skill: null,
    personal: {
      account: null,
      personal: null,
    },
    preference: [],
  });
  const [assessments, setAssessments] = useState([]);

  useEffect(() => {
    documentTitle("Assessment");
    if (!user) {
      navigate("/login", { replace: true });
    }
    if (activeTest) {
      navigate(`/assessment/test/${activeTest}/setup-device`);
    } else {
      if ("latest_timer" in sessionStorage) {
        sessionStorage.removeItem("latest_timer");
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      const onboarding = JSON.parse(localStorage.getItem("onboarding_tour"));
      localStorage.setItem(
        "onboarding_tour",
        JSON.stringify({ ...onboarding, assessment: true })
      );
    }
  }, [isOpen]);

  const getAssessment = (withLoading = true) => {
    setLoading(withLoading);
    axios
      .get(`/api/assessment/dashboard`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setProfile(result.data.profile);
          setSummary(result.data.summary);
          setAssessments(result.data.assessments);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const startTest = (test_id) => {
    // alert("Relame : " + assessment_idF + " " + test_id)
    navigate(`/assessment/test/${test_id}/setup-device`);
  };

  useEffect(() => {
    if (assessments) {
    }
    getAssessment();
  }, []);

  useEffect(() => {
    if (assessments.length > 0) {
    }
  }, [assessments]);
  // check if logged in /test

  useEffect(() => {
    if (!loading) {
      let params = new URLSearchParams(window.location.search);
      let focus = params.get("focus");
      if (focus) {
        setActiveFocus(focus);
      }

      if ("onboarding_tour" in localStorage) {
        const onboarding = JSON.parse(localStorage.getItem("onboarding_tour"));
        if (!onboarding.assessment) {
          setIsOpen(true);
          localStorage.setItem(
            "onboarding_tour",
            JSON.stringify({ ...onboarding, assessment: true })
          );
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    if (activeFocus && assessments.length > 0) {
      const element = document.getElementById(activeFocus);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [activeFocus, assessments]);

  useEffect(() => {
    if (
      // profile.about != null &&
      profile.experiences.length > 0 &&
      profile.educations.length > 0 &&
      profile.preference.length > 0 &&
      profile.skill != null &&
      profile.personal.personal.expected_salary != null
    ) {
      setAllowAssessment(true);
    } else {
      if (config.APP_ENV === "development") {
        setAllowAssessment(false);
      } else {
        setAllowAssessment(true);
      }
    }
  }, [
    profile.about,
    profile.experiences,
    profile.educations,
    profile.skill,
    profile.network,
    profile.preference,
  ]);
  const myStyle = {
    background: `#FBF3FF`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    overflowX: "hidden",
  };

  return (
    <>
      <div
        style={myStyle}
        className="px-[10px] py-[20px] md:px-[44px] md:py-[24px]"
      >
        <>
          <div className="mx-auto max-w-[1440px]">
            <h2 className="text-lg md:text-xl font-semibold leading-[150%] mb-6">
              Assessment
            </h2>
            <div className="bg-white rounded-lg shadow w-full mb-[6] px-6 py-6 assessment-summary">
              {loading ? (
                <PageSpinner />
              ) : (
                <>
                  <p className="text-lg md:text-xl font-semibold leading-[150%] mb-6">
                    {summary.job_role}
                  </p>
                  <p className="text-sm md:text-base text-grey-600 mb-2">
                    Finish your assessment before{" "}
                    <b>{moment(summary.deadline).format("D MMMM YYYY")}</b>
                  </p>
                  <div className="w-full flex flex-col">
                    <small className="text-gray-500 text-end">
                      {(
                        (summary.finished_assessment /
                          summary.total_assessment) *
                        100
                      ).toFixed(0)}
                      %
                    </small>
                    <div className="w-full h-[7px] bg-white-1 rounded-full mt-2">
                      <div
                        className="h-full text-center text-xs text-white bg-orange-secondary-400 rounded-full "
                        style={{
                          width: `${
                            (summary.finished_assessment /
                              summary.total_assessment) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!loading && (
              <div className="bg-white rounded-lg shadow w-full mb-[40px]">
                <div className="px-3 py-2 md:px-6 md:py-5 mt-5">
                  {allowAssessment && (
                    <>
                      {summary.is_expired && (
                        <div className="p-4 flex flex-col items-center">
                          <NoSymbolIcon
                            width={80}
                            className="text-red-500 mt-2 mb-4"
                          />
                          <p className="text-red-500 font-semibold">
                            This Job has expired
                          </p>
                          <p className="text-gray-500">
                            You can no longer take the assessment for this job
                          </p>
                        </div>
                      )}
                      {!summary.is_expired && (
                        <ol className="relative border-l border-gray-200 dark:border-gray-700">
                          {loading ? (
                            <PageSpinner />
                          ) : (
                            <>
                              <li className="mb-10 ml-8 assessment-accessibility">
                                <span className="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                  <CheckIcon
                                    width={20}
                                    className="text-green-600"
                                  />
                                </span>
                                <p className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                                  Accessibility
                                </p>
                                <p className="block mb-2 text-sm font-normal leading-none text-gray-900 dark:text-gray-500 mb-8">
                                  Your details here
                                </p>
                                <button
                                  className="mb-1 bg-main-violate-50 px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate hover:text-main-violate-50 disabled:bg-green-50 disabled:cursor-not-allowed disabled:text-green-600 flex items-center"
                                  type=""
                                  disabled={true}
                                >
                                  <CheckIcon width={16} className="mr-2" />{" "}
                                  Reviewed
                                </button>
                              </li>

                              {assessments.map((assessment, key) => {
                                return (
                                  <li
                                    key={key}
                                    className={`assessment-list mb-10 ml-8 ${
                                      activeFocus == assessment.assessment_id &&
                                      "assessment-fade-element"
                                    }`}
                                    id={assessment.assessment_id}
                                  >
                                    {assessment.status == "finished" ? (
                                      <>
                                        <span className="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                          <ClipboardDocumentCheckIcon
                                            width={20}
                                            className="text-green-600"
                                          />
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-50 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                          <ClipboardDocumentListIcon
                                            width={20}
                                            className="text-gray-600"
                                          />
                                        </span>
                                      </>
                                    )}

                                    <p className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                                      {assessment.name}
                                    </p>
                                    <p className="block mb-2 text-sm font-normal leading-none text-gray-900 dark:text-gray-500 mb-8">
                                      {assessment.finished_test}/
                                      {assessment.total_test} Tests Done
                                    </p>
                                    <div className="test-container flex overflow-x-auto flex-nowrap w-[100%]">
                                      {assessment.tests.map((test, index) => {
                                        if (test.status == "finished") {
                                          return (
                                            <div
                                              key={index}
                                              className="rounded  border border-lg border-green-600 w-[232px] mr-5 flex-shrink-0"
                                            >
                                              <div className="title font-bold  h-[48px] bg-gradient-to-br from-green-700 to-green-400 flex items-center px-4 mb-">
                                                <p className="text-white">
                                                  {test.name}
                                                </p>
                                              </div>
                                              <div className="content px-4 py-4">
                                                <p className="bg-green-100 text-green-800 font-medium px-[10px] py-1 rounded rounded-[6px] mb-4">
                                                  Finished
                                                </p>
                                                <div className="mb-5 flex flex-col gap-1">
                                                  <span className="flex">
                                                    <ClockIcon
                                                      width={16}
                                                      className="mr-2"
                                                    />{" "}
                                                    <p className="text-sm">
                                                      {Math.floor(
                                                        test.times / 60
                                                      ) +
                                                        " Minutes, " +
                                                        (test.times % 60) +
                                                        " Seconds"}
                                                    </p>
                                                  </span>
                                                  <span className="flex">
                                                    <LanguageIcon
                                                      width={16}
                                                      className="mr-2"
                                                    />{" "}
                                                    <p className="text-sm">
                                                      {test?.language_label}
                                                    </p>
                                                  </span>
                                                </div>
                                                <button
                                                  className="mb-1 bg-main-violate-50 w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate hover:text-main-violate-50 disabled:bg-green-50 disabled:cursor-not-allowed disabled:text-green-600"
                                                  type=""
                                                  disabled={true}
                                                >
                                                  Completed
                                                </button>
                                              </div>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              key={index}
                                              className="rounded  border border-lg border-main-violate-600-base w-[232px] mr-5 flex-shrink-0"
                                            >
                                              <div className="title font-bold  h-[48px] bg-gradient-to-br from-purple-800 via-purple-600 to-purple-400 flex items-center px-4 mb-">
                                                <p className="text-white">
                                                  {test.name}
                                                </p>
                                              </div>
                                              <div className="content px-4 py-4">
                                                <p className="bg-red-50 text-red-800 font-medium px-[10px] py-1 rounded rounded-[6px] mb-5">
                                                  Not Started
                                                </p>
                                                <div className="mb-5 flex flex-col gap-1">
                                                  <span className="flex">
                                                    <ClockIcon
                                                      width={16}
                                                      className="mr-2"
                                                    />{" "}
                                                    <p className="text-sm">
                                                      {Math.floor(
                                                        test.times / 60
                                                      ) +
                                                        " Minutes, " +
                                                        (test.times % 60) +
                                                        " Seconds"}
                                                    </p>
                                                  </span>
                                                  <span className="flex">
                                                    <LanguageIcon
                                                      width={16}
                                                      className="mr-2"
                                                    />{" "}
                                                    <p className="text-sm">
                                                      {test?.language_label}
                                                    </p>
                                                  </span>
                                                </div>

                                                <button
                                                  className="mb-1 bg-main-violate-50 w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate-100 hover:bg-main-violate-100 disabled:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400"
                                                  disabled={
                                                    test.status != "ready"
                                                  }
                                                  onClick={(e) =>
                                                    startTest(
                                                      test.candidate_test_id
                                                    )
                                                  }
                                                >
                                                  Start Now
                                                </button>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  </li>
                                );
                              })}

                              {/* <li className="mb-10 ml-8">
                    <span className="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                      <ChatBubbleOvalLeftEllipsisIcon width={20} className="text-green-600" />
                    </span>
                    <p className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Your assessment is done</p>
                    <p className="block mb-2 text-sm font-normal leading-none text-gray-900 dark:text-gray-500 mb-8">Give feedback</p>
                    <button
                      className="max-content mb-1 bg-main-violate-50 px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate-100 hover:bg-main-violate-100"
                      type=""
                    >
                      Submit Feedback
                    </button>
                  </li> */}
                            </>
                          )}
                        </ol>
                      )}
                    </>
                  )}
                  {allowAssessment == false && (
                    <>
                      <p className="text-base md:text-lg font-semibold mb-2">
                        Complete your profile
                      </p>
                      <p className="text-base md:text-base leading-[150%] mb-6 text-soft-grey">
                        You need to complete your profile before you can take
                        the assessment
                      </p>
                      <PersonalSalarySection
                        personal={profile.personal.personal}
                        account={profile.personal.account}
                        required={true}
                        passed={profile?.personal?.personal?.expected_salary}
                        onUpdate={() => {
                          getAssessment(false);
                        }}
                      />
                      {/* <AboutSection
                        about={profile.about}
                        required={true}
                        passed={profile.about}
                        onSubmitted={() => {
                          getAssessment(false);
                        }}
                      /> */}
                      <PreferenceSection
                        about={profile.preference}
                        required={true}
                        passed={profile.preference.length > 0}
                        onSubmitted={() => {
                          getAssessment(false);
                        }}
                      />
                      <WorkExperienceSection
                        required={true}
                        passed={profile.experiences.length > 0}
                        onSubmitted={() => {
                          getAssessment(false);
                        }}
                      />
                      <EducationalSection
                        required={true}
                        passed={profile.educations.length > 0}
                        onSubmitted={() => {
                          getAssessment(false);
                        }}
                      />
                      <SkillSection
                        required={true}
                        passed={profile.skill}
                        onSubmitted={() => {
                          getAssessment(false);
                        }}
                      />
                      {/* <SocialNetworkSection required={true} passed={profile.network} onSubmitted={() => {
                        getAssessment(false);
                      }}/> */}
                      {/* <div className="w-full flex justify-center">
                        <button className="bg-main-violate mx-auto w-[180px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed" onClick={() => {
                          getAssessment();
                        }}> Submit </button>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
        <ToastContainer />

        <Footer />
      </div>
    </>
  );
};

export default AssessmentDashboard;
