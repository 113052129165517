import React from 'react';
import axios from 'axios';
import PageSpinner from './PageSpinner';

class ImageAsync extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageData: null,
            loading: true,
        };
    }

    async componentDidMount() {
        try {
            const { imageUrl } = this.props;
            const response = await axios.get("/api" + imageUrl, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            this.setState({
                imageData: URL.createObjectURL(blob),
                loading: false,
            });
        } catch (error) {
            // console.error('Error fetching image:', error);
        }
    }

    componentWillUnmount() {
        if (this.state.imageData) {
            URL.revokeObjectURL(this.state.imageData);
        }
    }

    render() {
        const { imageData, loading } = this.state;

        if (loading) {
            return <PageSpinner />; // Or a loading indicator
        }

        if (imageData) {
            return <img src={imageData} alt="Question" className={this.props.className} />;
        } else {
            return null;
        }
    }
}

export default ImageAsync;
