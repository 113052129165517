import React from "react";
import { useState } from "react";
import { inputIsPhoneNumber } from "../../../helpers";
const PhonenumberForm = ({ onContinue }) => {
    const [phonenumber, setPhonenumber] = useState("+62");
    const handlePhonenumber = (e) => {
        const value = e.target.value
        if (inputIsPhoneNumber(value)) {
            setPhonenumber(value);
        }
    };
    const handleContinue = (e) => {
        e.preventDefault();
        onContinue({phonenumber: phonenumber}, 2);
    };
    return (
        <div className="bg-white md:rounded-lg rounded-t-[20px] shadow w-full lg:w-[483px] md:mb-[100px] px-10 py-4 md:py-8 md:h-[260px] h-[100%] translate-y-[50%] md:translate-y-[0%]">
            <div className="w-[50px] h-[5px] bg-grey-text rounded-lg m-auto mb-4 md:hidden"></div>
            <h1 className="font-semibold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                What&rsquo;s your phone number?
            </h1>

            <div className="w-full justify-center">
                <div className="mb-6">
                    <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Phone Number
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="tel"
                        name="phonenumber"
                        id="email"
                        value={phonenumber}
                        onChange={handlePhonenumber}
                        placeholder="+628xxx"
                    />
                </div>
            </div>
            <div className="flex mt-5 justify-center">
                <button
                    className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    style={{ textAlign: "-webkit-center" }}
                    onClick={handleContinue}
                    disabled={phonenumber.length < 11}
                >
                    Continue
                </button>
            </div>
        </div>
    )
}
export default PhonenumberForm;