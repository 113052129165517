import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../../Assets/bgAuth.webp";
import "../../css/login.css";
import FooterGuest from "../../Component/FooterGuest";
import PhonenumberForm from "./Forms/PhonenumberForm";
import NameForm from "./Forms/NameForm";
import CompanyForm from "./Forms/CompanyForm";
import SpecialConditionForm from "./Forms/SpecialConditionForm";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { documentTitle } from "../../helpers";

// Reusable hook to detect screen width and mobile device
// const useScreenWidth = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768 ? true : false);
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);

//   const handleWindowSizeChange = () => {
//     setScreenWidth(window.innerWidth);
//     setIsMobile(window.innerWidth <= 768); // Set your mobile breakpoint here (e.g., 768 pixels)
//   };

//   useEffect(() => {
//     // Add event listener to window resize
//     window.addEventListener('resize', handleWindowSizeChange);

//     // Cleanup event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', handleWindowSizeChange);
//     };
//   }, []);

//   return { isMobile, screenWidth };
// };

const CandidateProfileIntroPage = () => {
  let navigate = useNavigate();
  const [updating, setUpdating] = useState(false);
  const [profileForm, setProfileForm] = useState({
    first_name: "",
    last_name: "",
    phonenumber: "",
  });

  const [addressForm, setAddressForm] = useState({
    zip_code: "",
    province: "",
    city: "",
    country: "",
  });
  const [impairementForm, setImpairementForm] = useState(null);
  const [, setFile] = useState(null);
  const [formPosition, setFormPosition] = useState(1);
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const handleFile = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleUpdateProfile = (form) => {
    axios
      .post("/api/profile/personal-first-update", JSON.stringify(form), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success("Profile Saved!", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 550,
            onClose: () => {
              setFormPosition(3);
              setUpdating(false);
            },
          });
        }
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const handleUpdateAddress = (form) => {
    axios
      .post("/api/profile/personal-address-update", JSON.stringify(form), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success("Address Saved!", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 550,
            onClose: () => {
              setFormPosition(4);
              setUpdating(false);
            },
          });
        }
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const handleSpecialCondition = (form) => {
    axios
      .post(
        "/api/profile/personal-special-condition-update",
        JSON.stringify(form),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success("Information Saved!", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 550,
            onClose: () => navigate("/dashboard"),
          });
        }
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const handleContinue = (form, position) => {
    if (formPosition === 1) {
      setProfileForm({ ...profileForm, phonenumber: form.phonenumber });
      setFormPosition(position);
    }
    if (formPosition === 2) {
      setProfileForm({
        ...profileForm,
        first_name: form.first_name,
        last_name: form.last_name,
      });
      setUpdating(true);
    }
    if (formPosition === 3) {
      setAddressForm({
        ...addressForm,
        zip_code: form.zipCode,
        province: form.province,
        city: form.city,
        country: form.country,
      });
      setUpdating(true);
    }
    if (formPosition === 4) {
      setImpairementForm(form);
      setUpdating(true);
    }
  };

  useEffect(() => {
    documentTitle("Welcome Account");
  }, []);
  useEffect(() => {
    if (updating) {
      if (formPosition === 2) {
        handleUpdateProfile(profileForm);
      }
      if (formPosition === 3) {
        handleUpdateAddress(addressForm);
      }
      if (formPosition === 4) {
        handleSpecialCondition(impairementForm);
      }
    }
  }, [updating]);

  const FormFields = () => {
    if (formPosition === 1) {
      return <PhonenumberForm onContinue={handleContinue} />;
    } else if (formPosition === 2) {
      return <NameForm onContinue={handleContinue} updating={updating} />;
    } else if (formPosition === 3) {
      return (
        <CompanyForm
          onContinue={handleContinue}
          updating={updating}
          userName={user.name.split(" ")[0]}
        />
      );
    } else if (formPosition === 4) {
      return (
        <SpecialConditionForm
          onContinue={handleContinue}
          updating={updating}
          onAttachedFile={handleFile}
        />
      );
    }
  };
  // TEST 2
  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div>
      <div className="h-full flex flex-col md:justify-center">
        <ToastContainer />

        <div className="flex w-full flex-col md:flex-row h-[100vh]">
          <ParallaxProvider>
            <div
              className="w-full flex-col md:flex md:w-1/2 text-center md:text-left mb-0 md:mb-6 md:flex-col items-center"
              style={myStyle}
            >
              <Parallax className="" speed={-50}>
                <div className="flex flex-col justify-center h-[50%]">
                  <p className="text-xl md:text-4xl text-white-1 font-light">
                    Welcome To
                  </p>
                  <p className="text-xl md:text-5xl text-white-1 leading-[110%] font-light">
                    Highr ID
                  </p>
                </div>
              </Parallax>
            </div>
            <div className="absolute md:relative w-full md:w-1/2 flex justify-center items-end md:items-center h-full ">
              <FormFields />
            </div>
          </ParallaxProvider>
        </div>
      </div>
      <FooterGuest />
    </div>
  );
};

export default CandidateProfileIntroPage;
