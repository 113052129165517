import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bgLogin from "../Assets/bgLogin.webp";
import iconCompany from "../Assets/Office.png"
import Footer from "../Component/FooterDashboard";
import Navbar from "../Component/NavbarDashboard";


const CandidateAppPage = () => {

  let navigate = useNavigate();

  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });



  const myStyle = {
    backgroundImage: `url(${bgLogin})`,
    height: '187vh',
    marginTop: '-10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <Navbar />
      <div style={myStyle}>
        <div className="container mx-auto pt-12">
          <ToastContainer />
          <div className="mx-auto max-w-lg justify-center">
            <div className="bg-white rounded-lg shadow lg:w-[724px] mb-[40px] lg:ml-[-110px] pb-[8px]">
              <div className="border-b border-line-grey-1">
                <div className="px-6 py-4">
                  <div className="flex justify-between">
                    <h2 className="text-main-violate text-[24px] font-bold leading-[125%] tracking-[.01em] mt-2">My Applications</h2>
                    <div className="dropdown relative">
                      <a
                        className="
                                          dropdown-toggle
                                          px-3
                                          py-2
                                          text-soft-grey
                                          text-[14px]
                                          rounded-lg
                                          w-[137px]
                                          transition
                                          duration-150
                                          ease-in-out
                                          flex
                                          bg-white
                                          border-solid border-2 border-line-grey
                                          items-center
                                          whitespace-nowrap
                                        "
                        href="#"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Most Recent
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="caret-down"
                          className="w-2 ml-[20px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                          ></path>
                        </svg>
                      </a>
                      <ul
                        className="
                                          dropdown-menu
                                          min-w-max
                                          absolute
                                          hidden
                                          bg-white
                                          text-base
                                          z-50
                                          float-left
                                          py-2
                                          list-none
                                          text-left
                                          rounded-lg
                                          shadow-lg
                                          mt-1
                                          hidden
                                          m-0
                                          bg-clip-padding
                                          border-none
                                        "
                        aria-labelledby="dropdownMenuButton2"
                      >
                        <li>
                          <a
                            className="
                                              dropdown-item
                                              text-sm
                                              py-2
                                              px-4
                                              font-normal
                                              block
                                              w-full
                                              whitespace-nowrap
                                              bg-transparent
                                              text-gray-700
                                              hover:bg-gray-100
                                            "
                            href="#"
                          >Action</a
                          >
                        </li>
                        <li>
                          <a
                            className="
                                              dropdown-item
                                              text-sm
                                              py-2
                                              px-4
                                              font-normal
                                              block
                                              w-full
                                              whitespace-nowrap
                                              bg-transparent
                                              text-gray-700
                                              hover:bg-gray-100
                                            "
                            href="#"
                          >Another action</a
                          >
                        </li>
                        <li>
                          <a
                            className="
                                              dropdown-item
                                              text-sm
                                              py-2
                                              px-4
                                              font-normal
                                              block
                                              w-full
                                              whitespace-nowrap
                                              bg-transparent
                                              text-gray-700
                                              hover:bg-gray-100
                                            "
                            href="#"
                          >Something else here</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-6 pt-5 justify-center">
                <div className="border border-line-grey-1 w-[676px] h-[103px] px-4 flex pt-2 pb-5 justify-between rounded-lg ">
                  <div className="flex">
                    <img src={iconCompany} alt="icon" className="h-[48px] w-[48px] mt-3" />
                    <div className="ml-4 mt-[8px]">
                      <h4 className="text-black text-[16px] font-bold leading-[140%]">UI/UX Designer</h4>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Bank Permata</p>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Status : <span className="text-green-1 text-[14px] leading-[140%] mt-2 font-semibold">Active</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="bg-line-grey-1 w-[200px] px-4 py-2 text-[16px] leading-[140%] text-grey-text font-bold tracking-normal h-[46px] rounded-lg" type="submit" disabled>
                      On This Application
                    </button>
                    <p className="text-grey-70 text-[12px] leading-[140%] text-right mt-[8px]">Apply 1 day ago</p>
                  </div>
                </div>
                <div className="border border-line-grey-1 w-[676px] h-[103px] px-4 flex  pt-2 pb-5 justify-between mt-4 rounded-lg">
                  <div className="flex">
                    <img src={iconCompany} alt="icon" className="h-[48px] w-[48px] mt-3" />
                    <div className="ml-4 mt-[8px]">
                      <h4 className="text-black text-[16px] font-bold leading-[140%]">Product Designer</h4>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Monster University Inc</p>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Status : <span className="text-green-1 text-[14px] leading-[140%] mt-2 font-semibold ">Active</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="bg-main-violate w-[200px] px-4 py-2 text-[16px] leading-[140%] text-white font-semibold font-bold tracking-normal h-[46px] rounded-lg" type="submit">
                      Check Application
                    </button>
                    <p className="text-grey-70 text-[12px] leading-[140%] text-right mt-[8px]">Apply 5 day ago</p>
                  </div>
                </div>
                <div className="border border-line-grey-1 w-[676px] h-[103px] px-4 flex  pt-2 pb-5 justify-between mt-4 rounded-lg">
                  <div className="flex">
                    <img src={iconCompany} alt="icon" className="h-[48px] w-[48px] mt-3" />
                    <div className="ml-4 mt-[8px]">
                      <h4 className="text-black text-[16px] font-bold leading-[140%]">Product Designer</h4>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Monster University Inc</p>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Status : <span className="text-reed-1 text-[14px] leading-[140%] mt-2 font-semibold ">Not Active</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="bg-main-violate w-[200px] px-4 py-2 text-[16px] leading-[140%] text-white font-semibold font-bold tracking-normal h-[46px] rounded-lg" type="submit">
                      Check Application
                    </button>
                    <p className="text-grey-70 text-[12px] leading-[140%] text-right mt-[8px]">Apply 5 day ago</p>
                  </div>
                </div>
                <div className="border border-line-grey-1 w-[676px] h-[103px] px-4 flex  pt-2 pb-5 justify-between mt-4 rounded-lg">
                  <div className="flex">
                    <img src={iconCompany} alt="icon" className="h-[48px] w-[48px] mt-3" />
                    <div className="ml-4 mt-[8px]">
                      <h4 className="text-black text-[16px] font-bold leading-[140%]">UI/UX Designer</h4>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Bank Permata</p>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Status : <span className="text-green-1 text-[14px] leading-[140%] mt-2 font-semibold">Active</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="bg-main-violate w-[200px] px-4 py-2 text-[16px] leading-[140%] text-white font-semibold font-bold tracking-normal h-[46px] rounded-lg" type="submit">
                      Check Application
                    </button>
                    <p className="text-grey-70 text-[12px] leading-[140%] text-right mt-[8px]">Apply 1 day ago</p>
                  </div>
                </div>
                <div className="border border-line-grey-1 w-[676px] h-[103px] px-4 flex  pt-2 pb-5 justify-between mt-4 rounded-lg">
                  <div className="flex">
                    <img src={iconCompany} alt="icon" className="h-[48px] w-[48px] mt-3" />
                    <div className="ml-4 mt-[8px]">
                      <h4 className="text-black text-[16px] font-bold leading-[140%]">UI/UX Designer</h4>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Bank Permata</p>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Status : <span className="text-green-1 text-[14px] leading-[140%] mt-2 font-semibold">Active</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="bg-main-violate w-[200px] px-4 py-2 text-[16px] leading-[140%] text-white font-semibold font-bold tracking-normal h-[46px] rounded-lg" type="submit">
                      Check Application
                    </button>
                    <p className="text-grey-70 text-[12px] leading-[140%] text-right mt-[8px]">Apply 1 day ago</p>
                  </div>
                </div>
                <div className="border border-line-grey-1 w-[676px] h-[103px] px-4 flex  pt-2 pb-5 justify-between mt-4 rounded-lg">
                  <div className="flex">
                    <img src={iconCompany} alt="icon" className="h-[48px] w-[48px] mt-3" />
                    <div className="ml-4 mt-[8px]">
                      <h4 className="text-black text-[16px] font-bold leading-[140%]">UI/UX Designer</h4>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Bank Permata</p>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Status : <span className="text-green-1 text-[14px] leading-[140%] mt-2 font-semibold">Active</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="bg-main-violate w-[200px] px-4 py-2 text-[16px] leading-[140%] text-white font-semibold font-bold tracking-normal h-[46px] rounded-lg" type="submit">
                      Check Application
                    </button>
                    <p className="text-grey-70 text-[12px] leading-[140%] text-right mt-[8px]">Apply 1 day ago</p>
                  </div>
                </div>
                <div className="border border-line-grey-1 w-[676px] h-[103px] px-4 flex  pt-2 pb-5 justify-between mt-4 rounded-lg mb-20">
                  <div className="flex">
                    <img src={iconCompany} alt="icon" className="h-[48px] w-[48px] mt-3" />
                    <div className="ml-4 mt-[8px]">
                      <h4 className="text-black text-[16px] font-bold leading-[140%]">UI/UX Designer</h4>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Bank Permata</p>
                      <p className="text-grey-98 text-[14px] leading-[140%] mt-[4px]">Status : <span className="text-green-1 text-[14px] leading-[140%] mt-2 font-semibold">Active</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button className="bg-main-violate w-[200px] px-4 py-2 text-[16px] leading-[140%] text-white font-semibold font-bold tracking-normal h-[46px] rounded-lg" type="submit">
                      Check Application
                    </button>
                    <p className="text-grey-70 text-[12px] leading-[140%] text-right mt-[8px]">Apply 1 day ago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default CandidateAppPage;
