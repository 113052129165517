import React from "react";
import { useState } from "react";
import { inputIsOnlyAlphabetic } from "../../../helpers";
import BtnSpinner from "../../../Component/BtnSpinner";


const NameForm = ({ onContinue, updating }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const handleContinue = (e) => {
        e.preventDefault();
        onContinue({ first_name: firstName, last_name: lastName }, 3);
    };

    return (
        <div className="bg-white md:rounded-lg rounded-t-[20px] shadow w-full lg:w-[483px] md:mb-[100px] px-10 py-4 md:py-8 md:h-[340px] h-[100%] translate-y-[50%] md:translate-y-[0%]">
            <div className="w-[50px] h-[5px] bg-grey-text rounded-lg m-auto mb-4 md:hidden"></div>
            <h1 className="font-semibold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                What should we call you ?
            </h1>

            <div className="w-full justify-center">
                <div className="mb-6">
                    <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        First Name
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="text"
                        name="firstname"
                        id="firstname"
                        value={firstName}
                        onChange={(e) => {
                            if (inputIsOnlyAlphabetic(e.target.value)) setFirstName(e.target.value)
                        }}
                        placeholder="John"

                    />
                </div>
                <div className="mb-6">
                    <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Last Name
                    </label>
                    <input
                        className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={lastName}
                        onChange={(e) => { if (inputIsOnlyAlphabetic(e.target.value)) setLastName(e.target.value) }}
                        placeholder="Doe"
                    />
                </div>
            </div>
            <div className="flex mt-5 justify-center">
                <button
                    className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    style={{ textAlign: "-webkit-center" }}
                    disabled={updating || firstName.length < 2}
                    onClick={handleContinue}
                >
                    {updating ? <BtnSpinner /> : "Continue"}
                </button>
            </div>
        </div>
    )
}

export default NameForm;