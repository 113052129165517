import React, { useRef } from "react";
import { useState, useEffect } from "react";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import axios from "axios";
import { toast } from "react-toastify";
import ContryStateCities from "../../../Component/ContryStateCities";
import { IdentificationIcon, PencilIcon } from "@heroicons/react/24/solid";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  canvasPreview,
  findCityById,
  findCountryById,
  findStateById,
  inputIsMinimumAge,
  inputIsOnlyAlphabetic,
  inputIsPhoneNumber,
  inputIsRupiahFormat,
  inputMaxDate,
  rupiahFormat,
} from "../../../helpers";
import { Avatar } from "flowbite-react";
import Compressor from "compressorjs";
import { postMyPersonal } from "../../../api";
const PersonalSalarySection = ({
  about,
  personal,
  account,
  onUpdate,
  personalLoading,
  passed = false,
  required = false,
}) => {
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);
  const [validations, setValidations] = useState({});
  const [crop, setCrop] = useState({
    unit: "px",
    width: 100,
    height: 100,
    aspect: 1 / 1,
    x: 50,
    y: 50,
  });
  const [imageCropped, setImageCropped] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [form, setForm] = useState({
    about: "",
    fullname: "",
    phonenumber: "",
    date_of_birth: "",
    email: "",
    marital: "",
    gender: "",
    address: "",
    avatar: "",
    province: "",
    city: "",
    country: "",
    zip_code: "",
    expected_salary: "",
  });
  //
  const handleFullname = (value) => {
    if (inputIsOnlyAlphabetic(value)) {
      setForm({ ...form, fullname: value });
    }
  };

  const handlePhonenumber = (value) => {
    if (inputIsPhoneNumber(value)) {
      setForm({ ...form, phonenumber: value });
    }
  };

  const handleDateOfBirth = (value) => {
    const threshold = 17;
    if (inputIsMinimumAge(threshold, value)) {
      let defaultValidations = validations;
      if (typeof defaultValidations.date_of_birth != "undefined")
        delete defaultValidations.date_of_birth;
      setValidations(defaultValidations);
      setForm({ ...form, date_of_birth: value });
    } else {
      setValidations({
        ...validations,
        date_of_birth: [`You must be at least ${threshold} years old.`],
      });
      setForm({ ...form, date_of_birth: "" });
    }
  };
  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    let sanitizeForm = form;
    if (!hasUploaded) {
      sanitizeForm = { ...form, avatar: null };
    }
    const result = await postMyPersonal(sanitizeForm);
    if (result.success) {
      setHasUploaded(false);
      onUpdate();
      setShowModal(false);
      let currentUser = JSON.parse(sessionStorage.getItem("user"));
      currentUser.avatar = result.data.avatar;
      sessionStorage.setItem("user", JSON.stringify(currentUser));
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      if (response.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(response.message);
      } else {
        toast.error(response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setSaving(false);
  };

  const saveCropImage = async (e) => {
    e.preventDefault();
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !imageCropped) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      imageCropped.width * scaleX,
      imageCropped.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size

    const blob = await offscreen.convertToBlob({ type: "image/webp" });
    // Create a new File object
    const file = new File([blob], "profile.webp", { type: "image/webp" });

    await uploadImage(file);
  };

  const setUploadImage = (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (!file) {
      return;
    }
    if (file.size > maxSize) {
      alert(
        "Image size exceeds the maximum limit (2MB). Please select a smaller image."
      );
      return;
    }
    setImage(URL.createObjectURL(file));
  };
  const uploadImage = async (file) => {
    setUploading(true);
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 500,
      minHeight: 500,
      mimeType: "image/webp",
      success(result) {
        const formData = new FormData();
        formData.append("file", result);
        axios
          .post("/api/profile/personal/upload-image", formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            const result = response.data;
            setUploading(false);
            setImage(null);
            if (result.success) {
              setHasUploaded(true);
              setForm({ ...form, avatar: result.data });
              personal.avatar = URL.createObjectURL(file);
              toast.success(result.message, {
                toastId: "login-success-toast",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1250,
              });
            }
          })
          .catch((e) => {
            setUploading(false);
            toast.error(e.response.data.message, {
              toastId: "login-error-toast",
              position: toast.POSITION.TOP_CENTER,
            });
          });
      },
      error(err) {
        toast.error(err.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      },
    });
  };
  const handleShowModal = () => {
    setForm({ ...form, about: about });
    setShowModal(true);
  };

  useEffect(() => {
    setForm(personal);
  }, [personal]);

  useEffect(() => {
    if (!showModal) setValidations({});
  }, [showModal]);

  useEffect(() => {
    canvasPreview(imgRef.current, previewCanvasRef.current, imageCropped);
  }, [imageCropped]);

  useEffect(() => {
    async function getInformationApi() {
      // await getAchievements();
    }

    getInformationApi();
  }, []);
  return (
    <>
      <div
        className={`${
          required
            ? passed
              ? "border-2 border-green-1"
              : "border-2 border-reed-1"
            : "border-grey-200"
        } border w-full rounded-md mt-4 bg-white personal-section`}
      >
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <IdentificationIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Personal Information
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => handleShowModal()}
          >
            <PencilIcon width={22} />
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate max-w-max lg:w-[680px] lg:max-w-[680px]">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <IdentificationIcon
                        width={28}
                        className="text-orange-500"
                      />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
                        Personal Information
                      </h4>
                    </div>
                    {/*body*/}
                    <form
                      className="bg-grey-lightest px-2 py-5"
                      name="signup"
                      onSubmit={submit}
                    >
                      <div className="relative p-6 flex-auto max-h-[620px] overflow-y-auto">
                        <div className=" lg:w-[100%] justify-center">
                          {/* <div className="mb-5 mt-7 flex flex-col items-center">
                            {!image && (
                              <div className=" w-[80px] h-[80px] rounded-full mb-2">
                                {uploading && <PageSpinner />}
                                {!uploading && (
                                  <Avatar
                                    size={"lg"}
                                    alt="User settings"
                                    img={image ? image : personal?.avatar}
                                    rounded={true}
                                  />
                                )}
                              </div>
                            )}
                            {image && (
                              <>
                                <div className="flex gap-2 items-center mb-3">
                                  <div className="w-[50%] rounded-full mb-2">
                                    <ReactCrop
                                      aspect={1 / 1}
                                      circularCrop={true}
                                      crop={crop}
                                      onChange={(c) => setCrop(c)}
                                      minWidth={100}
                                      minHeight={100}
                                      onComplete={(c) => {
                                        setImageCropped(c);
                                      }}
                                    >
                                      <img src={image} ref={imgRef} />
                                    </ReactCrop>
                                  </div>
                                  {imageCropped && (
                                    <div className="w-[50%] rounded-full mb-2 flex justify-center">
                                      <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                          objectFit: "contain",
                                          width: imageCropped.width,
                                          height: imageCropped.height,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                {image && (
                                  <>
                                    <button
                                      type="button"
                                      onClick={(e) => saveCropImage(e)}
                                      className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                                      disabled={uploading}
                                    >
                                      {uploading ? (
                                        <BtnSpinner />
                                      ) : (
                                        "Save Image"
                                      )}
                                    </button>
                                  </>
                                )}
                              </>
                            )}

                            {!image && (
                              <div>
                                <div className="w-full flex justify-center">
                                  <label className="text-main-violate text-[16px] font-semibold mt-3 leading-[140%] text-center absolute">
                                    Change Profile Picture
                                  </label>
                                  <input
                                    type="file"
                                    className="h-full w-full opacity-0 cursor-pointer"
                                    name="uploadcv"
                                    accept="image/*"
                                    onChange={setUploadImage}
                                  />
                                </div>
                              </div>
                            )}
                          </div> */}

                          {!image && (
                            <>
                              <div className="mb-3">
                                <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                  Full Name{" "}
                                  <small className="text-soft-grey font-semibold">
                                    (required)
                                  </small>
                                </label>
                                <input
                                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border border-solid border-gray-300 focus:border-main-violate"
                                  type="text"
                                  name="fullname"
                                  id="fullname"
                                  placeholder="Full Name"
                                  value={form.fullname}
                                  onChange={(e) =>
                                    handleFullname(e.target.value)
                                  }
                                  required
                                />
                                {typeof validations.fullname != "undefined"
                                  ? validations.fullname.map((e) => {
                                      return (
                                        <span className="input-error-message">
                                          {e}
                                        </span>
                                      );
                                    })
                                  : ""}
                              </div>
                              <div className="mb-3">
                                <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                  Email{" "}
                                  <small className="text-soft-grey font-semibold">
                                    (required)
                                  </small>
                                </label>
                                <input
                                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border border-solid border-gray-300 focus:border-main-violate"
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                  value={form.email}
                                  onChange={(e) =>
                                    setForm({ ...form, email: e.target.value })
                                  }
                                  readOnly
                                />
                                {typeof validations.email != "undefined"
                                  ? validations.email.map((e) => {
                                      return (
                                        <span className="input-error-message">
                                          {e}
                                        </span>
                                      );
                                    })
                                  : ""}
                              </div>
                              <div className="mb-3">
                                <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                  Phone Number{" "}
                                  <small className="text-soft-grey font-semibold">
                                    (required)
                                  </small>
                                </label>
                                <input
                                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border border-solid border-gray-300 focus:border-main-violate"
                                  type="tel"
                                  name="phone"
                                  id="phone"
                                  value={form.phonenumber}
                                  onChange={(e) =>
                                    handlePhonenumber(e.target.value)
                                  }
                                  placeholder="Phone Number"
                                  required
                                />
                                {typeof validations.phonenumber != "undefined"
                                  ? validations.phonenumber.map((e) => {
                                      return (
                                        <span className="input-error-message">
                                          {e}
                                        </span>
                                      );
                                    })
                                  : ""}
                              </div>
                              <div className="mb-3">
                                <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                  Expected Salary (monthly){" "}
                                  <small className="text-soft-grey font-semibold">
                                    (required)
                                  </small>
                                </label>
                                <input
                                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border border-solid border-gray-300 focus:border-main-violate"
                                  type="tel"
                                  name="expected_salary"
                                  id="expected_salary"
                                  value={form.expected_salary}
                                  onChange={(e) =>
                                    inputIsRupiahFormat(e.target.value) &&
                                    setForm({
                                      ...form,
                                      expected_salary: e.target.value,
                                    })
                                  }
                                  required
                                  placeholder="Expected Salary"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {!image && (
                        <>
                          <div className="flex mt-5 justify-center ml-[-8px]">
                            <button
                              className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                              type="submit"
                              disabled={saving || uploading}
                            >
                              {saving ? <BtnSpinner /> : "Save"}
                            </button>
                          </div>
                          <div className="flex justify-center ml-[-8px]">
                            <button
                              className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                              onClick={() => setShowModal(false)}
                            >
                              Back to Profile
                            </button>
                          </div>
                        </>
                      )}
                    </form>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {personalLoading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="md:px-5">
            <div className="flex flex-col md:flex-row text-sm">
              <div className="flex justify-center py-8 lg:border-r border-grey-200 w-[100%] md:w-[30%]">
                <div>
                  <div className="h-[80px] rounded-full flex items-center justify-center mb-2">
                    <Avatar
                      size={"lg"}
                      alt="User settings"
                      img={personal?.avatar}
                      rounded={true}
                    />
                    {/* <img
                                            src={personal.avatar}
                                            alt="profile"
                                            className="h-[80px] w-[80px] profile-image"
                                        /> */}
                  </div>
                  <p className="text-black text-[16px] font-semibold leading-[140%] text-center mb-2">
                    {personal?.fullname}
                  </p>
                  {/* <p className="text-main-violate text-[14px] font-semibold leading-[140%] text-center mb-2">
                                        {personal.resume_file ? personal.resume_file.replace(/^.*[\\\/]/, "") : ""}
                                    </p> */}
                </div>
              </div>
              <div className="mt-7 w-[100%] md:w-[70%]">
                <table className="table-auto">
                  <tbody>
                    {/* <tr>
                      <td className="px-4 py-2 font-semibold">Username</td>
                      <td className="px-4 py-2">: {account?.username}</td>
                    </tr> */}
                    {/* <tr>
                      <td className="px-4 py-2 font-semibold">
                        Expected Salary*
                      </td>
                      <td className="px-4 py-2">
                        :{" "}
                        {personal?.expected_salary
                          ? rupiahFormat(personal.expected_salary) + " /month"
                          : "-"}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="px-4 py-2 font-semibold">Email*</td>
                      <td className="px-4 py-2">: {personal?.email}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">Phone Number*</td>
                      <td className="px-4 py-2">: {personal?.phonenumber}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 font-semibold">
                        Expected Salary *
                      </td>
                      <td className="px-4 py-2">
                        :{" "}
                        {personal?.expected_salary
                          ? rupiahFormat(personal.expected_salary) + " /month"
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalSalarySection;
