import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage/session';
// import storage from 'redux-persist/lib/storage';
import profileReducer from './features/profile/profileSlice';
import assessmentReducer from './features/assessment/assessmentSlice';
import faqReducer from './features/faq/faqSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['assessment'] // only assessment will be persisted
};

const rootReducer = combineReducers({
  profile: profileReducer,
  assessment: assessmentReducer,
  faq: faqReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;