import React, { Component } from "react";
// import "./index.css";
import moment from "moment";
import domtoimage from "dom-to-image";
import AssessmentContentJson from "../AssessmentContentJson";

export class AssessmentGroup extends React.Component {
  constructor() {
    super();
    this.state = {
      group_id: "",
      answer: "",
      questionImage: null,
    };
    this.questionRef = React.createRef(null);
  }

  componentDidMount() {
    this.convertToImage();
  }
  convertToImage() {
    const self = this;
    const element = this.questionRef.current;
    if (element) {
      domtoimage
        .toPng(element)
        .then(function (dataUrl) {
          self.setState({ questionImage: dataUrl });
        })
        .catch(function (error) {
          // Handle error
          // console.error("Error converting element to image:", error);
        });
    }
  }

  setAnswer(answer, questionId, multiple = false, checked = false) {
    let tempAnswers;
    let increment = 0;
    const questionIndex = this.props.question.questions.findIndex(
      (q) => q.id == questionId
    );
    if (
      typeof this.props.question.questions[questionIndex]["answer"] ===
      "undefined"
    ) {
      increment = 1;
    }

    this.props.question.questions[questionIndex]["answer"] = answer;
    this.props.question.questions[questionIndex]["question_id"] = questionId;
    if (this.props.question.group_id != this.state.group_id) {
      tempAnswers = "";
      this.setState({ id: this.props.question.group_id, answer: "" }, () => {});
    } else {
      tempAnswers = this.state.answer;
    }

    this.setState({ answer: tempAnswers });
    this.props.answer(this.props.question, increment);
  }

  setMultipleAnswer(answer, questionId, checked = false) {
    let tempAnswers;
    let increment = 0;
    const questionIndex = this.props.question.questions.findIndex(
      (q) => q.id == questionId
    );
    if (
      typeof this.props.question.questions[questionIndex]["answer"] ===
      "undefined"
    ) {
      increment = 1;
    }
    if (
      typeof this.props.question.questions[questionIndex]["answer"] ===
      "undefined"
    ) {
      this.props.question.questions[questionIndex]["answer"] = JSON.stringify([answer]);
    } else {
      let tempMultiAnswer = JSON.parse(this.props.question.questions[questionIndex]["answer"]);
      if(checked){
        tempMultiAnswer.push(answer);
      } else{
        tempMultiAnswer = tempMultiAnswer.filter((ans) => ans !== answer);
      }
      this.props.question.questions[questionIndex]["answer"] = JSON.stringify(tempMultiAnswer);
    }

    this.props.question.questions[questionIndex]["question_id"] = questionId;
    if (this.props.question.group_id != this.state.group_id) {
      tempAnswers = JSON.parse("[]");
      this.setState({ id: this.props.question.group_id, answer: "" }, () => {});
    } else {
      tempAnswers = JSON.parse(this.state.answer);
    }

    if (checked) {
      tempAnswers.push(answer);
    } else {
      tempAnswers = tempAnswers.filter((ans) => ans !== answer);
    }

    this.setState({ answer: JSON.stringify(tempAnswers) });
    this.props.answer(this.props.question, increment);
  }

  isOptionChecked(currentAnswer, id, question, option) {
    const answer = currentAnswer
      .find((curr) => curr.id == id)
      ?.questions.find((q) => q.id == question.id)
      ?.answer;
  
    let parsedAnswer;
    try {
      parsedAnswer = answer ? JSON.parse(answer) : null;
    } catch (e) {
      console.error("Failed to parse answer:", e);
      parsedAnswer = null;
    }
  
    return parsedAnswer?.includes(option.id.toString()) || false;
  }

  render() {
    const { id = "", content = "", questions = [] } = this.props.question;
    const currentAnswer = this.props.currentAnswer;
    return (
      <>
        <div className="rounded-[8px] w-full mdb:px-5 mdb:py-5 px-2 py-2 mt-2 mdb:mt-4">
          <div className="mb-5">
            <h2 className="mdb:text-lg text-base font-bold">
              Select options for these questions based on the reading material
            </h2>
          </div>
          {questions.map((question, index) => {
            return (
              <div className="mb-8">
                {question.kind === "text" && (
                  <h2
                    className="break-words max-w-full mdb:break-normal text-[14px] mdb:text-[16px] leading-[140%] no-select"
                    ref={this.questionRef}
                  >
                    {question.real_order}. {question.content}
                  </h2>
                )}
                {question.kind === "json" && (
                  <div className="flex gap-2">
                    <p className="leading-[180%]">{question.real_order}.</p>{" "}
                    <div>
                      <AssessmentContentJson question={question.content} />
                    </div>
                  </div>
                )}

                {question.type === 3 && (
                  <div className="radio-button mt-3">
                    {question.options.map((option, index) => {
                      return (
                        <div className="flex items-center mb-3 no-select">
                          <input
                            id={`purple-radio-${index}`}
                            type="radio"
                            name={`question-${question.id}`}
                            value={option.id}
                            checked={
                              currentAnswer.find((curr) => {
                                return curr.id == id;
                              })
                                ? currentAnswer
                                    .find((curr) => curr.id == id)
                                    .questions.find((q) => q.id == question.id)
                                  ? currentAnswer
                                      .find((curr) => curr.id == id)
                                      .questions.find(
                                        (q) => q.id == question.id
                                      ).answer == option.id
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            onChange={(e) =>
                              this.setAnswer(e.target.value, question.id)
                            }
                            className="w-4 h-4 text-main-violate bg-gray-100 border-gray-300 focus:ring-main-violate dark:focus:ring-main-violate dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 no-select hover:cursor-pointer"
                          />
                          <label
                            htmlFor={`purple-radio-${index}`}
                            className="ml-2 text-[14px] mdb:text-[16px] leading-[140%] "
                          >
                            {option.value}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
                {question.type === 4 && (
                  <div className="radio-button mt-3">
                    {question.options.map((option) => {
                      return (
                        <div className="radio-button mt-3" key={option.id}>
                          <div className="flex items-center mb-3">
                            <input
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                              type="checkbox"
                              id={`option_${option.id}`}
                              checked={this.isOptionChecked(currentAnswer, id, question, option)}
                              value={option.id}
                              name="answer[]"
                              onChange={(e) =>
                                this.setMultipleAnswer(
                                  e.target.value,
                                  question.id,
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              htmlFor={`option_${option.id}`}
                              className="ml-2 text-[16px] leading-[140%]"
                            >
                              {option.value}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {question.type === 5 && (
                  <div className="radio-button mt-3">
                    <div className="rounded-[6px] mt-4">
                      <input
                        className="border w-full p-3 h-[48px] placeholder:text-sm rounded-lg focus:ring-2 focus:outline-none focus:ring-main-violate border border-line-grey"
                        type="text"
                        name="answerr"
                        id="answerr"
                        placeholder={question.fill_answer_hint}
                        value={
                          currentAnswer.find((curr) => curr.id == id)
                            ? currentAnswer.find((curr) => curr.id == id).answer
                            : ""
                        }
                        onChange={(e) =>
                          this.setAnswer(e.target.value, question.id)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            );

            // if (question.type === 5) {
            //   return (
            //     <div className="mb-8">
            //       <h2
            //         className="w-[653px] break-words max-w-content text-[16px] leading-[140%] no-select"
            //         ref={this.questionRef}
            //       >
            //         {question.real_order}. {question.content}
            //       </h2>
            //       <div className="radio-button mt-3">
            //         <div className="rounded-[6px] mt-4">
            //           <input
            //             className="border w-full p-3 h-[48px] placeholder:text-sm rounded-lg focus:ring-2 focus:outline-none focus:ring-main-violate border border-line-grey"
            //             type="text"
            //             name="answerr"
            //             id="answerr"
            //             placeholder={question.fill_answer_hint}
            //             value={
            //               currentAnswer.find((curr) => curr.id == id)
            //                 ? currentAnswer.find((curr) => curr.id == id).answer
            //                 : ""
            //             }
            //             onChange={(e) => this.setAnswer(e.target.value, question.id)}
            //           />
            //         </div>
            //       </div>
            //     </div>
            //   );
            // }
          })}

          {/*  */}
        </div>
      </>
    );
  }
}
