import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlayIcon, PauseIcon, StopIcon } from "@heroicons/react/24/solid";
import { setPlayCount } from "../../features/assessment/assessmentSlice";
import "./style.css";

const CustomAudioPlayer = ({
  src,
  label = "Audio",
  showPause = false,
  preventStop = true,
  maxPlays = 1,
}) => {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tempPlayCount, setTempPlayCount] = useState(0);
  const playCount = useSelector((state) => state.assessment?.playCount);
  const dispatch = useDispatch();

  useEffect(() => {
    const audio = audioRef.current;

    const updateTime = () => {
      setCurrentTime(audio.currentTime);
    };

    const updateDuration = () => {
      setDuration(audio.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    audio?.addEventListener("timeupdate", updateTime);
    audio?.addEventListener("loadedmetadata", updateDuration);
    audio?.addEventListener("ended", handleEnded);

    return () => {
      audio?.removeEventListener("timeupdate", updateTime);
      audio?.removeEventListener("loadedmetadata", updateDuration);
      audio?.removeEventListener("ended", handleEnded);
    };
  }, [src]);

  useEffect(() => {
    if (audioRef?.current) {
      audioRef?.current.load();
    }
    if(playCount[src] === undefined){
        setDisabled(false);
    } else {
        if(playCount[src] < maxPlays){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }
  }, [src, playCount]);
  

  const handlePlay = async () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const handleStop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleProgressClick = (event) => {
    // const progress = event.target;
    // const rect = progress.getBoundingClientRect();
    // const offsetX = event.clientX - rect.left;
    // const newTime = (offsetX / progress.offsetWidth) * duration;
    // audioRef.current.currentTime = newTime;
  };

  useEffect(() => {
    if (duration > 0 && currentTime >= duration) {
      console.log("STOP");
      setCurrentTime(0);
      // setTempPlayCount(tempPlayCount + 1);
      dispatch(
        setPlayCount({
          url: src,
          count: playCount[src] === undefined ? 1 : playCount[src] + 1,
        })
      );
    }
  }, [currentTime]);

  // useEffect(() => {
  //   if (tempPlayCount > 0) {
  //     if (tempPlayCount >= maxPlays) {
  //       setDisabled(true);
  //     }
  //   }
  // }, [tempPlayCount]);

  return (
    <div className="bg-white p-2 rounded-xl w-full">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="flex gap-4 items-center">
        {!disabled ? (
          <>
            <div className="flex items-center gap-2">
              <audio ref={audioRef} src={src} className="hidden" />
              {isPlaying ? (
                <button
                  onClick={handleStop}
                  className="bg-red-500 text-white p-3 rounded-full flex items-center justify-center mt-2 disabled:bg-red-400 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={preventStop}
                  title={preventStop ? "Stop is disabled" : ""}
                >
                  <StopIcon className="w-[20px]" />
                </button>
              ) : (
                <button
                  onClick={handlePlay}
                  className="bg-blue-500 text-white p-3 rounded-full flex items-center justify-center mt-2"
                >
                  <PlayIcon className="w-[20px]" />
                </button>
              )}
              {showPause && isPlaying && (
                <button
                  onClick={handlePause}
                  className="bg-yellow-500 text-white p-3 rounded-full flex items-center justify-center"
                >
                  <PauseIcon className="w-[30px]" />
                </button>
              )}
            </div>
            <div className="flex-1">
              <div className="mt-2 text-sm text-gray-700">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
              <progress
                className="w-full h-[10px] progress-bar"
                value={currentTime}
                max={duration}
                onClick={handleProgressClick}
              ></progress>
            </div>
          </>
        ) : (
            <div className="text-red-500 text-sm">Max plays reached</div>
        )}
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
