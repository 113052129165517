import axios from "axios";
export const getInterview = async () => {
  try {
    const response = await axios.get(`/api/interview`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token || null
        }`,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return error.response.data;
  }
};
export const postInterview = async (form) => {
  try {
    const response = await axios.post(`/api/interview`, form, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token || null
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
