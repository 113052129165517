import axios from "axios";
export const getMyOccupations = async (slug) => {
  try {
    const response = await axios.get(`/api/b2c/riasec-user/${slug}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getDetailOccupation = async (slug, interest_code) => {
  try {
    const response = await axios.get(
      `/api/b2c/riasec-user/${slug}/${interest_code}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
