import axios from "axios";
export const getDashboardSummaries = async () => {
  try {
    const response = await axios.get(`/api/dashboard/summary`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postUploadImpairement = async (formData) => {
  try {
    const response = await axios.post(
      `/api/profile/personal/upload-doc-impairement?save=true`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
