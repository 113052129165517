import React from "react";

export default function ViewGridIcon({
  size = 24, // or any default size of your choice
  color = "#6B7280", // or any color of your choice
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} className="h-6 w-6" fill={color} viewBox="0 0 24 24" stroke={color} strokeWidth="2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
    </svg>
  );
}