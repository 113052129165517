import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import TimeUpAlarm from "../Icons/TimeUpAlarm";
function TimeQuestionUpPopup(props) {
  const { onClose, confirmLabel = 'Continue' } = props;

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  return (
    // Your alert popup component JSX
    // <div className="popup-alert justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
    //   <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate flex flex-col items-center justify-evenly h-[480px] py-2">
          <TimeUpAlarm size={"129px"} color="#CB3A31" />
          <div className="text px-2 px-2">
            <p className="font-bold text-center text-lg mb-4">Question Time Up</p>
            <p className="text-center text-base">
              The time allotted to do this question has run out. Please
              continue to the next question.
            </p>
          </div>

          <div className="flex mt-5 items-center justify-center w-[100%] flex-col">
            <button
              className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
              onClick={handleClose}
            >
              {confirmLabel}
            </button>
            <button
              className="text-grey-98  cursor-pointer  px-4 py-2 text-[16px] leading-[22.4px] text-black font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type="submit"
              onClick={handleClose}
            >
              Report Problem
            </button>
          </div>
        </div>
    //   </div>
    // </div>
  );
}

export default TimeQuestionUpPopup;
