import logo from "../Assets/HumataLogo.png";
export default function Footer() {
  return (
    <div className="flex flex-col">
      <footer className="bg-gray-800  text-white px-7 py-8 md:py-[87px] md:px-[160px] font-thin">
      <div className="flex flex-col md:flex-row">
        <div className="flex-2 flex-col w-full mb-10">
          <div className="flex text-white">
            <img
              src={logo}
              className="-ml-5 h-[70px] w-[70px] "
              alt="Flowbite Logo"
            />
            <p className="py-5">
              <h4 className="text-lg font-semibold ">Highr ID</h4>
            </p>
          </div>
          <div className="text-white">
            <p>
              Behavioral and skills
              <br /> assessment for your
              <br /> company&apos;s candidates.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-20">
          <div className="flex flex-row gap-6 md:gap-20">
            <div className="flex flex-col gap-6 w-full flex-wrap">
              <h4 className="text-lg font-semibold mb-4">Product</h4>
              <a href="#">Online Assessment</a>
              <a href="#">Big 5 Personalities</a>
              <a href="#">Cognitive Abilities</a>
              <a href="#">General Competencies</a>
              <a href="#">Artificial Intelligence</a>
            </div>
            <div className="flex flex-col gap-6 w-full">
              <h4 className="text-lg font-semibold mb-4">Company</h4>
              <a href="#">About Us</a>
              <a href="#">Help Centre</a>
              <a href="#">Contact Us</a>
            </div>
          </div>
          <div className="flex flex-row gap-6 md:gap-20">
            <div className="flex flex-col gap-6 w-full">
              <h4 className="text-lg font-semibold mb-4">Resources</h4>
              <a href="#">Pricing</a>
              <a href="#">Compare Features</a>
              <a href="#">FAQ</a>
            </div>
            <div className="flex flex-col gap-6 w-full">
              <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
              <a href="#">Linkedin</a>
              <a href="#">Instagram</a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full h-[1px] bg-gray-700 mt-10 justify-start md:justify-end">
        <p className="my-2">©Highr ID 2023</p>
      </div>
      </footer>
      
      
    </div>
  );
}
