import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../Assets/bgAuth.webp";
import "../css/login.css";
import config from "../config";
import PasswordInput from "../Component/PasswordInput";
import BtnSpinner from "../Component/BtnSpinner";
import { TextInput } from "flowbite-react";
import { textInputTheme } from "../themes/flowbite-theme";
import { documentTitle, validationError } from "../helpers";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ResetPasswordPage = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [tokenReset, setTokenReset] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formPassed, setFormPassed] = useState(false);
  const [validations, setValidations] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const handlePasswordChange = (password) => {
    setPassword(password);
  };

  const handleConfirmPasswordChange = (password) => {
    // const value = e.target.value || '';
    setConfirmPassword(password);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleRecaptchaChange = (e) => {
    const value = e;
    setRecaptcha(value);
  };

  const handleTokenResetChange = (e) => {
    const value = e.target.value || "";
    setTokenReset(value);
  };

  useEffect(() => {
    documentTitle("Reset Password");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const resetData = JSON.stringify({
      email: email,
      recaptcha: recaptcha,
      password: password,
      confirmpassword: confirmPassword,
      token: tokenReset,
    });
    axios
      .post("/api/forgot-password/reset", resetData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message, {
            toastId: "reset-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
            onClose: () => navigate("/login", { replace: false }),
          });
          setIsSuccess(true);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "reset-failure-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (
      password != "" &&
      confirmPassword != "" &&
      tokenReset != "" &&
      recaptcha != ""
    ) {
      setFormPassed(true);
      const newValidations = { ...validations };
      if (confirmPassword != password) {
        newValidations.confirmPassword = [
          validationError("password_confirmation"),
        ];
      } else {
        newValidations.confirmPassword = [];
      }

      if (password.length < 8) {
        newValidations.password = [validationError("password")];
      } else {
        newValidations.password = [];
      }

      setValidations(newValidations);
      const allValid = Object.values(newValidations).every(
        (validationArray) => validationArray.length === 0
      );
      setFormPassed(allValid);
    } else {
      setFormPassed(false);
    }
  }, [password, confirmPassword, recaptcha, tokenReset]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setEmail(params.get("email"));
  }, []);

  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div style={myStyle}>
      <div className="container mx-auto pt-12 h-full flex flex-col md:justify-center">
        <ToastContainer />
        <div className="mx-auto px-4 flex w-full flex-col md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left mb-6">
            <p className="text-xl md:text-2xl text-white-1 mb-[16px] font-normal">
              Welcome To
            </p>
            <p className="text-xl md:text-6xl text-white-1 leading-[110%] font-light md:max-w-sm">
              Highr ID
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <div className="bg-white rounded-lg shadow w-full lg:w-[483px] mb-[100px] px-10 py-8">
              {!isSuccess ? (
                <>
                  <h1 className="font-bold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                    Reset password
                  </h1>
                  <form
                    className="bg-grey-lightest"
                    name="reset"
                    onSubmit={handleSubmit}
                  >
                    <div className="w-full justify-center">
                      <div className="mb-3">
                        <div>
                          <label className=" text-gray-800 text-sm font-bold mb-4 leading-[240%]">
                            New Password
                          </label>
                          <PasswordInput
                            onChange={handlePasswordChange}
                            onKeyDown={handleKeyDown}
                          />
                          {typeof validations.password != "undefined"
                            ? validations.password.map((e, key) => {
                                return (
                                  <span
                                    key={key}
                                    className="input-error-message"
                                  >
                                    {e}
                                  </span>
                                );
                              })
                            : ""}
                          <p className="text-black text-[14px] leading-[140%] text-gray-500 mt-1">
                            We’ll never share your details. See our{" "}
                            <a href="#" className="text-gray-900 font-medium">
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div>
                          <label className=" text-gray-800 text-sm font-bold mb-4 leading-[240%]">
                            Repeat New Password
                          </label>
                          <PasswordInput
                            onChange={handleConfirmPasswordChange}
                            onKeyDown={handleKeyDown}
                          />
                          {typeof validations.confirmPassword != "undefined"
                            ? validations.confirmPassword.map((e, key) => {
                                return (
                                  <span
                                    key={key}
                                    className="input-error-message"
                                  >
                                    {e}
                                  </span>
                                );
                              })
                            : ""}
                          <p className="text-black text-[14px] leading-[140%] text-gray-500 mt-1">
                            We’ll never share your details. See our{" "}
                            <a href="#" className="text-gray-900 font-medium">
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                      <div className="mb-5">
                        <label className=" text-gray-800 text-sm font-bold mb-4 leading-[240%]">
                          OTP Code
                        </label>
                        <TextInput
                          onKeyDown={handleKeyDown}
                          theme={textInputTheme}
                          type="text"
                          name="token"
                          id="token"
                          value={tokenReset}
                          onChange={handleTokenResetChange}
                          placeholder="Your OTP Code"
                          required
                        />
                        {typeof validations.token != "undefined"
                          ? validations.token.map((e, key) => {
                              return (
                                <span key={key} className="input-error-message">
                                  {e}
                                </span>
                              );
                            })
                          : ""}
                      </div>
                      <div className="flex justify-center">
                        {/* <ReCAPTCHA
                          sitekey={config.RECAPTCHA_SITE_KEY}
                          onChange={handleRecaptchaChange}
                        /> */}
                        <GoogleReCaptchaProvider
                          reCaptchaKey={config.RECAPTCHA_SITE_KEY}
                        >
                          <GoogleReCaptcha onVerify={handleRecaptchaChange} />
                        </GoogleReCaptchaProvider>
                      </div>
                    </div>

                    <div className="flex mt-5 mb-4 justify-center">
                      <button
                        className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-normal h-[46px] rounded-lg mt-5 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                        type="submit"
                        disabled={submitting || !formPassed}
                      >
                        {submitting ? <BtnSpinner /> : "Save new password"}
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <h1 className="font-bold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                    Password updated
                  </h1>
                  <p className="text-black text-[14px] leading-[140%] text-gray-500 mt-1">
                    Your new password has now been set and please login with
                    your new password
                  </p>
                  <div className="flex mt-5 mb-4 justify-center">
                    <button
                      className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-normal h-[46px] rounded-lg mt-5 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      onClick={() => navigate("/login", { replace: false })}
                    >
                      Back to login
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
