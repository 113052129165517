import React from "react";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
function NextAssessmentPopup(props) {
  const { onClose, onContinue } = props;

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  const handleContinue = () => {
    if (typeof onContinue === "function") {
      onContinue();
    }
  };
  return (
    // Your alert popup component JSX

    <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate flex flex-col items-center justify-evenly h-[380px]">
      <CheckBadgeIcon
        width={"130px"}
        height={"130px"}
        className="text-green-1"
      />
      <div className="text px-2 px-2">
        <p className="font-bold text-center text-lg mb-4">Well Done</p>
        <p className="text-center text-base">
          You have finished this assessment. Do you want to continue to the next
          assessment?
        </p>
      </div>

      <div className="flex mt-5 justify-center w-[100%]">
        <button
          className="hover:bg-violet-600 cursor-pointer bg-main-violate px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
          type="submit"
          onClick={handleClose}
        >
          Back to Assessment
        </button>
        {/* <button
          className="border border-line-grey-1 text-grey-98 hover:border-2 cursor-pointer  w-[160px] px-4 py-2 text-[16px] leading-[22.4px] text-black border-line-grey-1 font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed mr-4"
          type="submit"
          onClick={handleClose}
        >
          Take a Rest
        </button>
        <button
          className="hover:bg-violet-600 cursor-pointer bg-main-violate  w-[160px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
          type="submit"
          onClick={handleContinue}
        >
          Continue
        </button> */}
      </div>
    </div>
  );
}

export default NextAssessmentPopup;
