import axios from "axios";
export const getMyPersonal = async () => {
  try {
    const response = await axios.get(`/api/profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postMyPersonal = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/personal",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postAboutPersonal = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/personal-about-update",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAchievement = async () => {
  try {
    const response = await axios.get("/api/profile/achievement", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postAchievement = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/achievement",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCertification = async () => {
  try {
    const response = await axios.get("/api/profile/certificate-and-training", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postCertification = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/certificate-and-training",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getExperiences = async () => {
  try {
    const response = await axios.get("/api/profile/educational", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postExperiences = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/educational",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getLanguages = async () => {
  try {
    const response = await axios.get("/api/profile/language", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postLanguages = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/language",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadLanguages = async (formData) => {
  try {
    const response = await axios.post(
      `/api/profile/language-upload`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getResumes = async () => {
  try {
    const response = await axios.get("/api/profile/resumes", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postResumes = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/resumes",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteResumes = async (id) => {
  try {
    const response = await axios.delete(
      `/api/profile/resumes/${id}`,
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadResumes = async (formData) => {
  try {
    const response = await axios.post(
      `/api/profile/resumes-upload`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getSkills = async () => {
  try {
    const response = await axios.get("/api/profile/skill", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postSkills = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/skill",
      form,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getSocialNetworks = async () => {
  try {
    const response = await axios.get("/api/profile/social-networks", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postSocialNetworks = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/social-networks",
      form,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getWorkExperiences = async () => {
  try {
    const response = await axios.get("/api/profile/experiences", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postWorkExperiences = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/experiences",
      form,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


export const postLocationPreferences = async (form) => {
  try {
    const response = await axios.post(
      "/api/profile/location-preferences",
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            JSON.parse(sessionStorage.getItem("user")).token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getLocationPreferences = async () => {
  try {
    const response = await axios.get("/api/profile/location-preferences", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("user")).token
        }`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};