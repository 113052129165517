import React, { Component } from "react";
import IcOffice from "../../Assets/Office.png";
import PencilSimpleLine from "../../Assets/PencilSimpleLine.png";
import "./index.css";
import moment from "moment";

export class CardAchievement extends React.Component {
  handleEdit = (e,id) => {
    this.props.onShowEdit(id)
  };

  render() {
    return (
      <>
        {this.props.experiences.map((experience, index) => {
          return (
            <div className="flex py-6 px-6 border border-line-gray-200 rounded-md mb-4 px"  style={{ justifyContent: "space-between" }}>
              {/* <div
                className="flex justify-center pt-1 ic-section"
                style={{ minWidth: "100px" }}
              >
                <img src={IcOffice} alt="icon" className="h-[50px] w-[50px]" />
              </div> */}
              <div className="desc-section">
                <p className="font-bold mb-0.5">{experience.title}</p>
                <p className="font-light text-slate-600 mb-0.5">
                  {experience.institution}
                </p>
                <p className="font-light text-slate-500 text-sm mb-2">
                  {moment(experience.issued_date).format("DD/MM/Y") }
                </p>
                {/* <ul className="text-slate-600 list-disc list-inside pl-3">
                  <li>Competitor Research</li>
                </ul> */}
              </div>
              <div className="edit-section">
                <button className="bg-white">
                  <img
                    src={PencilSimpleLine}
                    alt="icon"
                    className="h-[20px] w-[20px]"
                    onClick={(e) => {
                      this.handleEdit(e,experience.id)
                    }}
                  />
                </button>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default CardAchievement;
