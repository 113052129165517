import React from "react";

export default function TimeUpAlarm({
  size = 18, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98 62L62 98"
        stroke="#CB3A31"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98 98L62 62"
        stroke="#CB3A31"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_d_2012_7240)">
        <path
          d="M80 135C110.376 135 135 110.376 135 80C135 49.6243 110.376 25 80 25C49.6243 25 25 49.6243 25 80C25 110.376 49.6243 135 80 135Z"
          stroke="#CB3A31"
          strokeWidth="10"
          strokeMiterlimit="10"
        />
        <path
          d="M122.438 16.375L143.625 37.5625"
          stroke="#CB3A31"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.375 37.5625L37.5625 16.375"
          stroke="#CB3A31"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
