import React from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { Dropdown } from 'flowbite-react';

const DropdownDots = ({ children }) => {
  return (
    <Dropdown
      arrowIcon={false}
      inline={true}
      className='bg-main-violate-50hover:bg-main-violate-600'
      label={<EllipsisVerticalIcon width={20} />}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownDots;
