import React from "react";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
function ForceSubmitAssessmentPopup(props) {
  const { onClose } = props;

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  return (
    // Your alert popup component JSX

    <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate flex flex-col items-center justify-evenly h-[380px]">
      <CheckBadgeIcon
        width={"130px"}
        height={"130px"}
        className="text-green-1"
      />
      <div className="text px-2 px-2">
        <p className="font-bold text-center text-lg mb-4">Done</p>
        <p className="text-center text-base">
          You have force finished this assessment. Thank you.
        </p>
      </div>

      <div className="flex mt-5 justify-center w-[100%]">
        <button
          className="border border-line-grey-1 text-grey-98 hover:border-2 cursor-pointer  w-[160px] px-4 py-2 text-[16px] leading-[22.4px] text-black border-line-grey-1 font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed mr-4"
          type="submit"
          onClick={handleClose}
        >
          Close
        </button>

      </div>
    </div>
  );
}

export default ForceSubmitAssessmentPopup;
