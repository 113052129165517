import React from "react";
import { useState, useEffect } from "react";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GlobeAltIcon, PlusIcon, PencilIcon } from "@heroicons/react/24/solid";
import { getSocialNetworks, postSocialNetworks } from "../../../api";
import { setSocials } from "../../../features/profile/profileSlice";
const SocialNetworkSection = ({passed = false, required = false, onSubmitted}) => {
  const { data: socials, loaded } = useSelector(
    (state) => state.profile.socials
  );
  const dispatch = useDispatch();
  const [, setValidations] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = useState(false);
  // const [socials, setSocials] = useState({});
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    linkedin: null,
    dribbble: null,
    medium: null,
    github: null,
    website: null,
  });

  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const result = await postSocialNetworks(form);
    if (result.success) {
      getSocials();
      onSubmitted();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setSaving(false);
  };

  const getSocials = async () => {
    setLoading(true);
    const result = await getSocialNetworks();
    if (result.success) {
      if (result.data != null) {
        // setSocials(result.data);
        dispatch(setSocials(result.data));
        setForm({
          linkedin: result.data.linkedin,
          dribbble: result.data.dribbble,
          medium: result.data.medium,
          github: result.data.github,
          website: result.data.website,
        });
      } else {
        dispatch(
          setSocials({
            linkedin: null,
            dribbble: null,
            medium: null,
            github: null,
            website: null,
          })
        );
      }
    } else {
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(result.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showModal) setValidations({});
  }, [showModal]);

  useEffect(() => {
    async function getInformationApi() {
      if (!loaded) {
        await getSocials();
      } else {
        setForm({
          linkedin: socials.linkedin,
          dribbble: socials.dribbble,
          medium: socials.medium,
          github: socials.github,
          website: socials.website,
        });
      }
    }

    getInformationApi();
  }, []);
  return (
    <>
      <div className={`${required ? passed ? 'border-2 border-green-1' : 'border-2 border-reed-1' : 'border-grey-200'} border w-full rounded-md mt-4 bg-white`}>
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <GlobeAltIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Social Networks
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            {socials.linkedin != "" &&
            socials.medium != "" &&
            socials.dribble != "" &&
            socials.github != "" &&
            socials.website != "" ? (
              <PencilIcon width={22} />
            ) : (
              <PlusIcon width={22} />
            )}
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate max-w-max lg:w-[680px] lg:max-w-[680px]">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <GlobeAltIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
                        Social Networks
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p- flex-auto">
                      <form
                        className="bg-grey-lightest px-2 py-5"
                        name="signup"
                        onSubmit={submit}
                      >
                        <div className="justify-center max-h-[60vh] overflow-y-auto w-full">
                          <div className="px-2 py-2">
                            <div className="relative mt-2 rounded-md ">
                              <div className="flex ring-1 ring-outset ring-gray-300 rounded-md border-0 mb-4">
                                {/* <select
                                  id="currency"
                                  disabled
                                  name="currency"
                                  className="rounded-md border-0 bg-gray-100 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                >
                                  <option>LinkedIn</option>
                                </select> */}
                                <span className="rounded-md border-0 bg-gray-100 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm flex items-center min-w-[100px]">
                                  Linkedin
                                </span>
                                <input
                                  type="text"
                                  name="price"
                                  id="price"
                                  value={form.linkedin}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      linkedin: e.target.value,
                                    })
                                  }
                                  className="block w-full border-0 border-l border-gray-300 bg-transparent py-1.5 pr-20 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="https://"
                                />
                              </div>
                              <div className="flex ring-1 ring-outset ring-gray-300 rounded-md border-0 mb-4">
                                {/* <select
                                  id="currency"
                                  name="currency"
                                  className="rounded-md border-0 bg-gray-100 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                >
                                  <option>LinkedIn</option>
                                  <option>Medium</option>
                                  <option>Dribbble</option>
                                  <option>Github</option>
                                  <option>Website</option>
                                </select> */}
                                <span className="rounded-md border-0 bg-gray-100 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm flex items-center min-w-[100px]">
                                  Medium
                                </span>
                                <input
                                  type="text"
                                  name="price"
                                  id="price"
                                  value={form.medium}
                                  onChange={(e) =>
                                    setForm({ ...form, medium: e.target.value })
                                  }
                                  className="block w-full border-0 border-l border-gray-300 bg-transparent py-1.5 pr-20 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="https://"
                                />
                              </div>
                              <div className="flex ring-1 ring-outset ring-gray-300 rounded-md border-0 mb-4">
                                <span className="rounded-md border-0 bg-gray-100 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm flex items-center min-w-[100px]">
                                  Dribbble
                                </span>
                                <input
                                  type="text"
                                  name="price"
                                  id="price"
                                  value={form.dribbble}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      dribbble: e.target.value,
                                    })
                                  }
                                  className="block w-full border-0 border-l border-gray-300 bg-transparent py-1.5 pr-20 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="https://"
                                />
                              </div>
                              <div className="flex ring-1 ring-outset ring-gray-300 rounded-md border-0 mb-4">
                                <span className="rounded-md border-0 bg-gray-100 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm flex items-center min-w-[100px]">
                                  Github
                                </span>
                                <input
                                  type="text"
                                  name="price"
                                  id="price"
                                  value={form.github}
                                  onChange={(e) =>
                                    setForm({ ...form, github: e.target.value })
                                  }
                                  className="block w-full border-0 border-l border-gray-300 bg-transparent py-1.5 pr-20 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                                  placeholder="https://"
                                />
                              </div>
                              <div className="flex ring-1 ring-outset ring-gray-300 rounded-md border-0 mb-4">
                                <span className="rounded-md border-0 bg-gray-100 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm flex items-center min-w-[100px]">
                                  Website
                                </span>
                                <input
                                  type="text"
                                  name="price"
                                  id="price"
                                  value={form.website}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      website: e.target.value,
                                    })
                                  }
                                  className="block w-full border-0 border-l border-gray-300 bg-transparent py-1.5 pr-20 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="https://"
                                />
                              </div>

                              {/* 
                              <div className="rounded-lg border px-2 py-2 border-gray-200 w-full mb-2 max-w-max flex items-center cursor-pointer">
                                <PlusIcon width={18} />
                                <p className="text-sm font-medium mr-2 ml-2">Add More Social Networks</p>
                              </div> */}
                            </div>
                          </div>
                          <div className="mb-3">
                            {/* <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="title"
                              id="title"
                              placeholder="Title"
                              required
                              value={form.title}
                              onChange={(e) => setForm({ ...form, title: e.target.value })}
                            />
                            {typeof validations.title != "undefined" ? validations.title.map((e) => {
                              return (<span className="input-error-message">{e}</span>)
                            }) : ""} */}
                          </div>
                        </div>

                        <div className="flex mt-5 justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                            onClick={() => setShowModal(false)}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </form>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {socials.linkedin != "" &&
            socials.medium != "" &&
            socials.dribble != "" &&
            socials.github != "" &&
            socials.website != "" ? (
              <div className="card-mini">
                {socials.linkedin ? (
                  <div className="flex text-[14px] leading-[140%]">
                    <div className="px-4 py-2 font-semibold min-w-[100px]">
                      Linkedin
                    </div>
                    <div className="px-4 py-2">
                      :{" "}
                      <a
                        className="text-blue-700 font-semibold"
                        href={socials.linkedin}
                      >
                        {socials.linkedin}
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {socials.medium ? (
                  <div className="flex text-[14px] leading-[140%]">
                    <div className="px-4 py-2 font-semibold min-w-[100px]">
                      Medium
                    </div>
                    <div className="px-4 py-2">
                      :{" "}
                      <a
                        className="text-blue-700 font-semibold"
                        href={socials.medium}
                      >
                        {socials.medium}
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {socials.dribbble ? (
                  <div className="flex text-[14px] leading-[140%]">
                    <div className="px-4 py-2 font-semibold min-w-[100px]">
                      Dribbble
                    </div>
                    <div className="px-4 py-2">
                      :{" "}
                      <a
                        className="text-blue-700 font-semibold"
                        href={socials.dribbble}
                      >
                        {socials.dribbble}
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {socials.github ? (
                  <div className="flex text-[14px] leading-[140%]">
                    <div className="px-4 py-2 font-semibold min-w-[100px]">
                      Github
                    </div>
                    <div className="px-4 py-2">
                      :{" "}
                      <a
                        className="text-blue-700 font-semibold"
                        href={socials.github}
                      >
                        {socials.github}
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {socials.website ? (
                  <div className="flex text-[14px] leading-[140%]">
                    <div className="px-4 py-2 font-semibold min-w-[100px]">
                      Website
                    </div>
                    <div className="px-4 py-2">
                      :{" "}
                      <a
                        className="text-blue-700 font-semibold"
                        href={socials.website}
                      >
                        {socials.website}
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SocialNetworkSection;
