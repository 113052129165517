import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Sidebar } from "flowbite-react";
import NavbarDashboard from "../Component/NavbarDashboard";
import ViewGridIcon from "../Component/HeroIcons1/Solid/ViewGridIcon";
import {
  InformationCircleIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
  ClipboardDocumentListIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import PageSpinner from "../Component/PageSpinner";
import { handleProgressCheck } from "../features/assessment/assessmentSlice";
import { useDispatch, useSelector } from "react-redux";
import PopupContainer from "../Component/PopUp";
import PromptTourPopup from "../Component/PromptTourPopup";
import { useTour } from "@reactour/tour";
import config from "../config";
const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const { setSteps, setCurrentStep } = useTour();
  const { setIsOpen } = useTour();
  const { checked: progressChecked } = useSelector((state) => state.assessment);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showPromptTour, setShowPromptTour] = useState(false);
  const [user, setUser] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const signOut = () => {
    sessionStorage.removeItem("user");
    window.location.replace("/login");
  };

  const handleSkipTour = (skip) => {
    setIsOpen(!skip);
    if (!skip) {
      localStorage.setItem(
        "onboarding_tour",
        JSON.stringify({
          dashboard: false,
          profile: false,
          assessment: false,
        })
      );
    } else {
      localStorage.setItem(
        "onboarding_tour",
        JSON.stringify({
          dashboard: true,
          profile: true,
          assessment: true,
        })
      );
    }
    setShowPromptTour(false);
  };

  useEffect(() => {
    setCurrentStep(0);
    if (location.pathname == "/dashboard") {
      setSteps([
        {
          selector: ".sidebar-element",
          position: "right",
          content:
            "Ini adalah sidebar. Pada bagian ini kamu dapat menggunakannya sebagai navigasi ke halaman lain.",
        },
        {
          selector: ".dashboard-hero",
          content:
            "Pada bagian ini kamu akan diberikan salam oleh aplikasi serta warna latar akan berubah sesuai dengan status profil dan assessment mu.",
        },
        {
          selector: ".summary-stats",
          content:
            "Bagian ini menunjukan ringkasan dari profil dan assessment mu.",
        },
        {
          selector: ".summary-test-tobe-done",
          content:
            "Bagian ini menunjukan ringkasan berapa assessment yang harus kamu kerjakan. Jika kamu ingin melihat lebih detail, kamu bisa mengakses halaman 'Assessment'",
        },
        {
          selector: ".summary-test-finished",
          content:
            "Bagian ini menunjukan ringkasan berapa assessment yang sudah kamu selesaikan. Jika kamu ingin melihat lebih detail, kamu bisa mengakses halaman 'Assessment'",
        },
        {
          selector: ".summary-profile-completed",
          content:
            "Bagian ini menunjukan ringkasan berapa persen profil yang sudah kamu lengkapi. Jika kamu ingin melihat lebih detail, kamu bisa mengakses halaman 'Profile'",
        },
        {
          selector: ".summary-assessment-list",
          content:
            "Lalu bagian ini menunjukan ringkasan dari assessment yang harus kamu kerjakan, sedang kamu kerjakan, dan yang sudah selesai dikerjakan, serta meberikan berapa persen progress assessment yang telah kamu selesaikan. Jika kamu ingin melihat lebih detail, kamu bisa klik tombol yang tersedia pada tiap box.",
        },
        {
          selector: ".profile-reminder",
          content:
            "Bagian ini menunjukan reminder untuk melengkapi profil kamu. Jika kamu ingin melihat lebih detail, kamu bisa klik tombol 'Update Profile",
        },
        {
          selector: ".help-support-item",
          position: "right",
          content:
            "Terakhir, kamu dapat mengakses halaman 'Help & Support' disini jika kamu membutuhkan bantuan.",
        },
      ]);
    } else if (location.pathname == "/candidate/profile") {
      setSteps([
        {
          selector: ".personal-section",
          content:
            "Bagian ini menunjukan ringkasan dari profil kamu. Kamu bisa melakukan update profil disini dengan menekan tombol '✎'.",
        },
        {
          selector: ".cv-section",
          content:
            "Bagian ini menunjukan informasi dari CV kamu. Kamu bisa melakukan upload CV disini dengan menekan tombol '+'.",
        },
        {
          selector: ".additional-section",
          content:
            "Semua Bagian ini menunjukan informasi tambahan dari profil kamu. Kamu bisa menambah, menghapus, atau mengubah informasi tambahan disini.",
        },
      ]);
    } else if (location.pathname == "/assessment") {
      setSteps([
        {
          selector: ".assessment-summary",
          content:
            "Kamu dapat melihat informasi nama job, deadline, dan progress assessment yang kamu kerjakan disini.",
        },
        {
          selector: ".assessment-accessibility",
          content:
            "Bagian ini menunjukan informasi aksesibilitas assessment yang kamu kerjakan.",
        },
        {
          selector: ".assessment-list",
          content:
            "Terakhir, kamu dapat melihat daftar assessment yang kamu kerjakan, sedang kamu kerjakan, dan yang sudah selesai dikerjakan disini. Kamu dapat masuk ke test dengan menekan tombol 'Start Now' pada tiap box assessment yang tersedia",
        },
      ]);
    }
  }, [location.pathname, setCurrentStep, setSteps]);

  useEffect(() => {
    if (progressChecked) {
      setLoading(false);
    }
  }, [progressChecked]);

  useEffect(() => {
    dispatch(handleProgressCheck());
    if ("onboarding_tour" in localStorage) {
    } else {
      localStorage.setItem(
        "onboarding_tour",
        JSON.stringify({
          dashboard: true,
          profile: true,
          assessment: true,
        })
      );
      setShowPromptTour(true);
    }
  }, []);

  useEffect(() => {
    if (progressChecked) {
      setLoading(false);
    }
  }, [progressChecked]);

  // useEffect(() => {
  //   if(!openSidebar){
  //     document.body.classList.add("overflow-hidden")
  //   } else {
  //     document.body.classList.remove("overflow-hidden")
  //   }
  // }, [openSidebar])
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <PageSpinner />
        <p className="text-gray-500 text-sm font-semibold">Loading App...</p>
      </div>
    );
  } else {
    return (
      <>
        {showPromptTour && (
          <PopupContainer>
            <PromptTourPopup
              onClose={() => handleSkipTour(true)}
              onContinue={() => handleSkipTour(false)}
            />
          </PopupContainer>
        )}
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>    
            </li>      
            <li>
              <Link to={ userData === null ? "/login" : "profile" }>{ userData === null ? "Log In" : "Profile" }</Link>    
            </li>    
            <li>
              <Link to="/signup">Sign Up</Link>
            </li>
          </ul>
        </nav> */}
        {/* <nav class="bg-white shadow-lg w-[100%]">
            <div class="flex justify-start px-2 h-[80px]">
              <a href="#" class="flex items-center py-3 px-2">
                <img src={logo} alt="Logo" class="h-[80px] w-[80px] mr-2 mb-3"/>
              </a>
            </div>
        </nav> */}
        <NavbarDashboard
          openSidebar={(e) => {
            setOpenSidebar(!openSidebar);
          }}
        />
        <Sidebar
          aria-label="Sidebar with multi-level dropdown"
          className="w-full sidebar"
        >
          <Sidebar.Items className="w-full md:flex mt-[70px]">
            <div
              className={`${
                (openSidebar ? " translate-x-[-100%] " : " translate-x-[0%] ") +
                "md:translate-x-[0%] w-[75%] sm:w-[50%] lg:w-[18%] md:w-[25%] lg:max-w-xs py-4 px-2 fixed md:relative  bg-white z-[11] h-[93%] duration-150 ease-out lg:flex-1 flex flex-col sidebar-element"
              }`}
            >
              <div className="flex-1">
                <Sidebar.ItemGroup>
                  <p className="text-sm text-grey-400 font-semibold px-4">
                    Main Menu
                  </p>
                  <Sidebar.Item
                    className={`${
                      (window.location.pathname === "/dashboard"
                        ? " active "
                        : "") + "sidebar-item hover:cursor-pointer px-4"
                    }`}
                    onClick={(e) => {
                      navigate("/dashboard");
                    }}
                    icon={ViewGridIcon}
                    active={window.location.pathname === "/dashboard"}
                  >
                    <p className="text-grey-500">Dashboard</p>
                  </Sidebar.Item>
                  <Sidebar.Item
                    className={`${
                      (window.location.pathname === "/candidate/profile"
                        ? " active "
                        : "") +
                      "sidebar-item hover:cursor-pointer px-4 sidebar-profile-item"
                    }`}
                    onClick={(e) => {
                      navigate("/candidate/profile");
                    }}
                    icon={UserIcon}
                    active={window.location.pathname === "/candidate/profile"}
                  >
                    <p className="text-grey-500">Profile</p>
                  </Sidebar.Item>
                  <Sidebar.Item
                    className={`${
                      (window.location.pathname === "/assessment"
                        ? " active "
                        : "") + "sidebar-item hover:cursor-pointer px-4"
                    }`}
                    icon={ClipboardDocumentListIcon}
                    onClick={(e) => {
                      navigate(`/assessment`);
                    }}
                  >
                    <p className="text-grey-500">Assessment</p>
                  </Sidebar.Item>

                  {/* HANDLE ONLY DEVELOPMENT, SHOW PROD AFTER APPROVED */}
                  {config.APP_ENV === "development" && (
                    <Sidebar.Item
                      className={`${
                        (window.location.pathname === "/interview"
                          ? " active "
                          : "") + "sidebar-item hover:cursor-pointer px-4"
                      }`}
                      icon={VideoCameraIcon}
                      onClick={(e) => {
                        navigate(`/interview`);
                      }}
                    >
                      <p className="text-grey-500">Interview</p>
                    </Sidebar.Item>
                  )}
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup>
                  <p className="text-sm text-grey-400 font-semibold px-4">
                    Other Menu
                  </p>
                  <Sidebar.Item
                    icon={InformationCircleIcon}
                    className={`${
                      (window.location.pathname === "/helps"
                        ? " active "
                        : "") +
                      "sidebar-item hover:cursor-pointer px-4 help-support-item"
                    }`}
                    onClick={(e) => {
                      navigate(`/helps`);
                    }}
                  >
                    <p className="text-grey-500">Help & Support</p>
                  </Sidebar.Item>
                </Sidebar.ItemGroup>
              </div>
              <div className="h-[65px] mb-4 bg-gray-50 px-2 rounded-md block mdb:hidden">
                <div className="flex justify-between items-center h-full">
                  <div className="flex space-x-4 h-full items-center ">
                    <Avatar
                      alt="User settings"
                      className=""
                      img={user.avatar}
                      rounded={true}
                    />
                    <div className="flex flex-col text-left">
                      <p className="text-sm font-semibold">{user.name}</p>
                      <p className="text-sm text-gray-500 font-medium">
                        {user.email}
                      </p>
                    </div>
                  </div>
                  <ArrowRightOnRectangleIcon
                    onClick={() => signOut()}
                    className="text-gray-500 hover-cursor-pointer cursor-pointer"
                    width={30}
                  />
                </div>
              </div>
            </div>
            <div className="content w-[100%] md:w-[75%] sm:w-[100%] lg:w-[92%] overflow-x-hidden flex-1">
              {!openSidebar && (
                <>
                  <div className="overlay fixed w-full bg-gray-500/[.5] h-full z-[10]"></div>
                </>
              )}
              <Outlet />
            </div>
          </Sidebar.Items>
        </Sidebar>
      </>
    );
  }
};

export default MainLayout;
