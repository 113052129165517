import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
  documentTitle,
  inputIsEmailFormat,
  inputIsOnlyAlphabetic,
  inputIsPhoneNumberWithCode,
  validationError,
} from "../../helpers";
import { setFaqs } from "../../features/faq/faqSlice";
import axios from "axios";
import BtnSpinner from "../../Component/BtnSpinner";
import { Accordion } from "flowbite-react";
import PageSpinner from "../../Component/PageSpinner";
import { uploadHelpInquiry } from "../../api";
import TagInputWithSuggest from "../../Component/TagInputWithSuggest/TagInputWithSuggest";
import FaqAutoComplete from "../../Component/FaqAutoComplete";

const HelpsPage = () => {
  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const disptach = useDispatch();
  const { data: faqs, loaded } = useSelector((state) => state.faq.faq);
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const [faqLoading, setFaqLoading] = useState(false);
  // const [faqs, setFaqs] = useState([]);
  const [activeTab, setActiveTab] = useState("faq");
  const [activeFaqTab, setActiveFaqTab] = useState("account");
  const [submitting, setSubmitting] = useState(false);
  const [formPassed, setFormPassed] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [validations, setValidations] = useState({});
  const [fileUpload, setFileUpload] = useState(null);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const showTab = (tabId) => {
    setActiveTab(tabId);
  };
  const showFaqTab = (tabId) => {
    setActiveFaqTab(tabId);
  };
  const [types] = useState(["Error", "Question", "Suggestion", "Other"]);
  const [form, setForm] = useState({
    fullname: "",
    email: "",
    phonenumber: "+62",
    subject: "",
    type: "",
    message: "",
    file_id: "",
  });

  const submitForm = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const upload = await postUpload();
    if (!upload) {
      return;
    }
    const sanitizeForm = form;
    sanitizeForm.file_id = upload.id;
    try {
      const response = await axios.post(
        "/api/help-inquiry",
        JSON.stringify(sanitizeForm),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = response.data;
      if (result.success) {
        resetForm();
        toast.success(result.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setSubmitting(false);
    setUploading(false);
    setFileUpload(null);
  };

  const postUpload = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("image", fileUpload);
    const result = await uploadHelpInquiry({ formData });
    if (result.success) {
      return result.data;
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
  };
  const handleSelectedFaq = (selected) => {
    setSelectedFaq(selected);
  };
  const handleFile = (e) => {
    setFileUpload(e.target.files[0]);
  };
  const getFaq = async () => {
    setFaqLoading(true);
    try {
      const response = await axios.get("/api/faq", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const result = response.data;
      if (result.success) {
        disptach(setFaqs(result.data));
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setFaqLoading(false);
  };
  useEffect(() => {
    documentTitle("Help & Support");
    if (!loaded) {
      getFaq();
    }
  }, []);

  const resetForm = () => {
    setForm({
      fullname: "",
      email: "",
      phonenumber: "+62",
      subject: "",
      type: "",
      message: "",
    });
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    if (selectedFaq) {
      if (activeFaqTab !== selectedFaq.topic) {
        showFaqTab(selectedFaq.topic);
      } else {
        const element = document.getElementById("faq-" + selectedFaq.id);
        element.click();
        setSelectedFaq(null);
      }
    }
  }, [selectedFaq]);

  useEffect(() => {
    if (selectedFaq) {
      const element = document.getElementById("faq-" + selectedFaq.id);
      element.click();
      setSelectedFaq(null);
    }
  }, [activeFaqTab]);
  useEffect(() => {
    if (
      form.fullname !== "" &&
      form.last_name !== "" &&
      form.email !== "" &&
      form.phonenumber !== "" &&
      form.subject !== "" &&
      form.type !== "" &&
      form.message
    ) {
      setFormPassed(true);
      const newValidations = { ...validations };
      if (form.phonenumber.length < 11) {
        newValidations.phonenumber = [validationError("phone")];
      } else {
        newValidations.phonenumber = [];
      }
      if (!inputIsEmailFormat(form.email)) {
        newValidations.email = [validationError("email")];
      } else {
        newValidations.email = [];
      }
      setValidations(newValidations);
      // Cek jika semua properti dalam objek validasi kosong
      const allValid = Object.values(newValidations).every(
        (validationArray) => validationArray.length === 0
      );
      setFormPassed(allValid);
    } else {
      setFormPassed(false);
    }
  }, [form]);

  const myStyle = {
    background: `#FBF3FF`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
  };

  return (
    <div
      style={myStyle}
      className="px-[10px] py-[20px] md:px-[44px] md:py-[24px]"
    >
      <ToastContainer />
      <h2 className="text-lg md:text-xl font-semibold leading-[150%] mb-6">
        Help & Support Center
      </h2>
      <div className="invite-section mb-2">
        <div className="py-5">
          <nav className="border-b border-gray-200">
            <div className="flex space-x-4">
              <a
                href="#"
                className={`${
                  activeTab === "faq"
                    ? "text-[#8F03ED] py-2 px-4 font-semibold border-b-2 border-[#8F03ED]"
                    : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200"
                }`}
                onClick={() => showTab("faq")}
              >
                FAQ
              </a>
              <a
                href="#"
                className={`${
                  activeTab === "help"
                    ? "text-[#8F03ED] py-2 px-4 font-semibold border-b-2 border-[#8F03ED]"
                    : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200"
                }`}
                onClick={() => showTab("help")}
              >
                Help
              </a>
            </div>
          </nav>
        </div>
      </div>
      <div className="mb-6">
        <div className="flex flex-col w-full">
          <div className="px-4 py-5 sm:p-0">
            <div>
              {/* Content for Tab 1 */}
              {activeTab === "faq" && (
                <>
                  <div className="card flex flex-col w-full">
                    <div className="flex flex-col items-center w-full mb-14">
                      <p className="text-lg font-semibold text-center mb-6">
                        How Can We Help You?
                      </p>
                      <div className="relative w-full max-w-[940px]">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                        </div>
                        {/* <input type="search" id="default-search" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search FAQ" required /> */}
                        {/* <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
                        <FaqAutoComplete
                          data={faqs}
                          selected={handleSelectedFaq}
                        />
                      </div>
                    </div>
                    {faqLoading ? (
                      <PageSpinner />
                    ) : (
                      <>
                        <div className="flex flex-col w-full">
                          <div className="invite-section mb-2">
                            <div className="py-5">
                              <nav>
                                <div className="flex">
                                  <a
                                    href="#"
                                    className={`${
                                      activeFaqTab === "account"
                                        ? "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 rounded-lg rounded-r-none bg-gray-200 shadow-md"
                                        : "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 rounded-lg shadow-md rounded-r-none"
                                    }`}
                                    onClick={() => showFaqTab("account")}
                                  >
                                    Account
                                  </a>
                                  <a
                                    href="#"
                                    className={`${
                                      activeFaqTab === "assessment"
                                        ? "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200   bg-gray-200  shadow-md"
                                        : "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200  shadow-md"
                                    }`}
                                    onClick={() => showFaqTab("assessment")}
                                  >
                                    Assessment
                                  </a>
                                  <a
                                    href="#"
                                    className={`${
                                      activeFaqTab === "profile"
                                        ? "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition duration-200 rounded-lg rounded-l-none bg-gray-200 shadow-md"
                                        : "text-gray-500 hover:text-indigo-600 py-4 px-4 font-semibold transition rounded-l-none rounded-lg shadow-md"
                                    }`}
                                    onClick={() => showFaqTab("profile")}
                                  >
                                    Profile
                                  </a>
                                </div>
                              </nav>
                            </div>
                          </div>
                        </div>
                        <div>
                          {activeFaqTab === "account" && (
                            <>
                              <Accordion collapseAll>
                                {faqs
                                  .filter((faq) => faq.topic == "account")
                                  .map((faq) => {
                                    return (
                                      <Accordion.Panel>
                                        <Accordion.Title id={"faq-" + faq.id}>
                                          {faq.title}
                                        </Accordion.Title>
                                        <Accordion.Content className="bg-main-violate-50">
                                          <p className="mb-2 text-gray-500 dark:text-gray-400">
                                            {faq.content}
                                          </p>
                                        </Accordion.Content>
                                      </Accordion.Panel>
                                    );
                                  })}
                              </Accordion>
                            </>
                          )}
                          {activeFaqTab === "assessment" && (
                            <>
                              <Accordion collapseAll>
                                {faqs
                                  .filter((faq) => faq.topic == "assessment")
                                  .map((faq) => {
                                    return (
                                      <Accordion.Panel>
                                        <Accordion.Title id={"faq-" + faq.id}>
                                          {faq.title}
                                        </Accordion.Title>
                                        <Accordion.Content className="bg-main-violate-50">
                                          <p className="mb-2 text-gray-500 dark:text-gray-400">
                                            {faq.content}
                                          </p>
                                        </Accordion.Content>
                                      </Accordion.Panel>
                                    );
                                  })}
                              </Accordion>
                            </>
                          )}
                          {activeFaqTab === "profile" && (
                            <>
                              <Accordion collapseAll>
                                {faqs
                                  .filter((faq) => faq.topic == "profile")
                                  .map((faq) => {
                                    return (
                                      <Accordion.Panel>
                                        <Accordion.Title id={"faq-" + faq.id}>
                                          {faq.title}
                                        </Accordion.Title>
                                        <Accordion.Content className="bg-main-violate-50">
                                          <p className="mb-2 text-gray-500 dark:text-gray-400">
                                            {faq.content}
                                          </p>
                                        </Accordion.Content>
                                      </Accordion.Panel>
                                    );
                                  })}
                              </Accordion>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {/* Content for Tab 2 */}
              {activeTab === "help" && (
                <>
                  <div className="card flex flex-col w-full">
                    <form
                      className="bg-grey-lightest"
                      name="create_candidate"
                      onSubmit={submitForm}
                    >
                      <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
                        <div>
                          <div className="mb-6">
                            <label className="text-gray-800 text-sm font-bold leading-[140%]">
                              Fullname
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                              type="text"
                              name="fullname"
                              id="fullname"
                              placeholder="Type your first name.."
                              value={form.fullname}
                              onChange={(e) => {
                                if (inputIsOnlyAlphabetic(e.target.value)) {
                                  setForm({
                                    ...form,
                                    fullname: e.target.value,
                                  });
                                }
                              }}
                              required
                            />
                            {typeof validations.fullname != "undefined"
                              ? validations.fullname.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div className="mb-6">
                            <label className="text-gray-800 text-sm font-bold leading-[140%]">
                              Email Address
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Type your email address.."
                              value={form.email}
                              onChange={(e) => {
                                // if (inputIsOnlyAlphabetic(e.target.value)) {
                                setForm({ ...form, email: e.target.value });
                                // }
                              }}
                              required
                            />
                            {typeof validations.email != "undefined"
                              ? validations.email.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div>
                            <label className="text-gray-800 text-sm font-bold leading-[140%]">
                              Phone Number
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                              type="tel"
                              name="phonenumber"
                              id="phonenumber"
                              placeholder="Type your phone number.."
                              value={form.phonenumber}
                              onChange={(e) => {
                                if (
                                  inputIsPhoneNumberWithCode(e.target.value)
                                ) {
                                  setForm({
                                    ...form,
                                    phonenumber: e.target.value,
                                  });
                                } else {
                                  setForm({ ...form, phonenumber: "+62" });
                                }
                              }}
                              required
                            />
                            {typeof validations.phonenumber != "undefined"
                              ? validations.phonenumber.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                        <div>
                          <div className="mb-6">
                            <label className="text-gray-800 text-sm font-bold leading-[140%]">
                              Subject
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                              type="text"
                              name="subject"
                              id="subject"
                              placeholder="Type your subject.."
                              value={form.subject}
                              onChange={(e) => {
                                if (inputIsOnlyAlphabetic(e.target.value)) {
                                  setForm({ ...form, subject: e.target.value });
                                }
                              }}
                              required
                            />
                            {typeof validations.subject != "undefined"
                              ? validations.subject.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div className="mb-6">
                            <label className="text-gray-800 text-sm font-bold leading-[140%]">
                              Type
                            </label>
                            <select
                              className="form-select appearance-none
                                    block
                                    w-full
                                    px-3
                                    py-2
                                    text-gray-800
                                    text-[14px]
                                    bg-white bg-clip-padding bg-no-repeat
                                    border border-solid border-gray-300
                                    rounded-md
                                    mt-2
                                    transition
                                    ease-in-out
                                    focus:outline-none focus:border-main-violate border border-line-grey"
                              aria-label="Default select example"
                              value={form.type}
                              onChange={(e) => {
                                setForm({ ...form, type: e.target.value });
                              }}
                            >
                              <option selected disabled value="">
                                Choose your type...
                              </option>
                              {types.map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="text-gray-800 text-sm font-bold leading-[140%]">
                              Message Body
                            </label>
                            <textarea
                              className="border w-full p-3 h-[136px] placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                              type="text"
                              name="message"
                              id="message"
                              placeholder="Choose your type.."
                              value={form.message}
                              onChange={(e) => {
                                // if (inputIsOnlyAlphabetic(e.target.value)) {
                                setForm({ ...form, message: e.target.value });
                                // }
                              }}
                              required
                            ></textarea>
                            {typeof validations.message != "undefined"
                              ? validations.message.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div>
                            <label className="text-gray-800 text-sm font-bold leading-[140%]">
                              Attachment
                            </label>
                            {uploading ? (
                              <PageSpinner padding={"30px"} />
                            ) : fileUpload ? (
                              <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                                <span className="block text-soft-grey text-[14px]">
                                  {fileUpload.name}{" "}
                                  {/* {profile.resume_file.replace(/^.*[\\\/]/, "")} */}
                                </span>
                                <button
                                  onClick={() => {
                                    setFileUpload(null);
                                  }}
                                >
                                  <TrashIcon width={20} />
                                </button>
                              </div>
                            ) : (
                              <input
                                className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={(e) => handleFile(e)}
                              />
                            )}

                            <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                              Maximum <b>Image</b> size 2 Mb
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <button
                          className=" bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                          type="submit"
                          disabled={!formPassed || submitting}
                        >
                          {submitting ? (
                            <>
                              <BtnSpinner />
                            </>
                          ) : (
                            <>Send</>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpsPage;
