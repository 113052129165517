import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPreferences } from "../../../features/profile/profileSlice";
import Plus from "../../../Assets/Plus.png";
import ContryStateCities from "../../../Component/ContryStateCities";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import { toast } from "react-toastify";
import { AcademicCapIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { getLocationPreferences, postExperiences, postLocationPreferences } from "../../../api";
import { MapPinIcon } from "@heroicons/react/24/solid";
import PencilSimpleLine from "../../../Assets/PencilSimpleLine.png";
import cities from "../../../data/cities.json";
import states from "../../../data/states.json";
import countries from "../../../data/countries.json";

const PreferenceSection = ({
  passed = false,
  required = false,
  onSubmitted,
}) => {
  const { data: preferences, loaded } = useSelector(
    (state) => state.profile.preferences
  );
  const dispatch = useDispatch();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const [validations, setValidations] = useState({});
  const [showModalWT, setShowModalWT] = React.useState(false);
  const [saving, setSaving] = useState(false);
  // const [educationals, setEducationals] = useState([]);
  const [loadingExp, setLoadingExp] = useState(false);
  const [form, setForm] = useState({
    id: null,
    country: "",
    province: "",
    city: "",
  });
  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const result = await postLocationPreferences(form);
    if (result.success) {
      setSaving(false);
      setForm({
        id: null,
        country: "",
        province: "",
        city: "",
      });
      setShowModalWT(false);
      fetchPreferences();
      onSubmitted();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      setSaving(false);
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    
  };
  const fetchPreferences = async () => {
    setLoadingExp(true);
    const result = await getLocationPreferences();
    if (result.success) {
      if (result.data != null) {
        dispatch(setPreferences(result.data));
      }
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoadingExp(false);
  };

  useEffect(() => {
    if (!showModalWT) setValidations({});
  }, [showModalWT]);

  useEffect(() => {
    async function getInformationApi() {
      if (!loaded) await fetchPreferences();
    }

    getInformationApi();
  }, []);
  return (
    <>
      <div
        className={`${
          required
            ? passed
              ? "border-2 border-green-1"
              : "border-2 border-reed-1"
            : "border-grey-200"
        } border w-full rounded-md mt-4 bg-white`}
      >
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <MapPinIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Location Preferences
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => setShowModalWT(true)}
          >
            <img src={Plus} alt="icon" className="h-[24px] w-[24px]" />
          </button>
          {showModalWT ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate max-w-max lg:w-[680px] lg:max-w-[680px]">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <AcademicCapIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3">
                        Educational History
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <form
                        className="bg-grey-lightest flex flex-col items-center"
                        name="work_experience"
                        onSubmit={submit}
                      >
                        <div className="max-h-[60vh] overflow-y-auto w-full max-w-lg">
                          <ContryStateCities
                            onCountryChange={(e) =>
                              setForm({
                                ...form,
                                country: e,
                              })
                            }
                            onStateChange={(e) =>
                              setForm({
                                ...form,
                                province: e,
                              })
                            }
                            onCityChange={(e) =>
                              setForm({
                                ...form,
                                city: e,
                              })
                            }
                            countryValue={form.country}
                            stateValue={form.province}
                            cityValue={form.city}
                            hideZipCode={true}
                          />
                        </div>
                        <div className="flex mt-5 justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                            onClick={() => {
                              setShowModalWT(false);
                              setForm({
                                id: null,
                                country: "",
                                province: "",
                                city: "",
                              });
                            }}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </form>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loadingExp ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {preferences.length > 0 ? (
              <>
                {preferences.map((preference, index) => (
                  <div
                    className="flex py-2 px-6 border border-line-gray-200 rounded-md mb-4 items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="desc-section text-sm">{cities.find((city) => city.id == preference.city)?.name}, {states.find((state) => state.id == preference.province)?.name}, {countries.find((country) => country.id == preference.country)?.name}</p>
                    <div className="edit-section flex gap-3">
                      <PencilIcon className="cursor-pointer" width={14} onClick={() => {
                        setForm(preference)
                        setShowModalWT(true)
                      }}/>
                      {/* <TrashIcon width={14} onClick={() => {
                        
                      }}/> */}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PreferenceSection;
