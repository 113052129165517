import React, { Component } from "react";
// import "./index.css";
import moment from "moment";
import domtoimage from "dom-to-image";
import { inputIsUrlFormat } from "../../helpers";

export class AssessmentBlank extends React.Component {
  constructor() {
    super();
    this.state = {
      answer: "",
      questionImage: null,
    };

    this.questionRef = React.createRef(null);
  }

  componentDidMount() {
    this.convertToImage();
  }
  convertToImage() {
    const self = this;
    const element = this.questionRef.current;
    if (element) {
      domtoimage
        .toPng(element)
        .then(function (dataUrl) {
          self.setState({ questionImage: dataUrl });
        })
        .catch(function (error) {
          // Handle error
          // console.error("Error converting element to image:", error);
        });
    }
  }
  setAnswer(answer) {
    if (inputIsUrlFormat(answer)) {
      alert("URL INPUT FORBIDDEN!");
    } else {
      this.setState({ answer: answer });
      this.props.answer({ ...this.props.question, answer: answer });
    }
  }

  render() {
    const {
      id = "",
      content = "",
      order = "",
      type = null,
      kind = "",
      score = "",
      times = "",
      options = [],
    } = this.props.question;
    const currentAnswer = this.props.currentAnswer;
    return (
      <>
        <div className="rounded-[8px] w-full mdb:px-5 mdb:py-5 px-2 py-2 mt-2 mdb:mt-4">
          <div className="mdb:mb-5 mb-2">
            <h2 className="text-lg font-bold">Fill in the blank</h2>
          </div>
          <div className="rounded-[6px] mt-4">
            <input
              className="border w-full p-3 h-[48px] placeholder:text-sm rounded-lg focus:ring-2 focus:outline-none focus:ring-main-violate border border-line-grey"
              type="text"
              name="answerr"
              id="answerr"
              placeholder="Type Your Answer"
              value={
                currentAnswer.find((curr) => curr.id == id)
                  ? currentAnswer.find((curr) => curr.id == id).answer
                  : ""
              }
              onChange={(e) => this.setAnswer(e.target.value)}
            />
          </div>
        </div>
      </>
    );
  }
}
