import React from "react";
import { useState, useEffect } from "react";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import { toast } from "react-toastify";
import { PencilIcon, PlusIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { postAboutPersonal } from "../../../api";
const AboutSection = ({ about, loading = false, passed = false, required = false, onSubmitted }) => {
  const [validations, setValidations] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = useState(false);
  // const [loading] = useState(false);
  const [form, setForm] = useState({
    about: "",
  });
  //
  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const result = await postAboutPersonal(form);
    if (result.success) {
      onSubmitted();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      toast.error(e.response.data.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setSaving(false);
  };

  const handleShowModal = () => {
    setForm({ ...form, about: about });
    setShowModal(true);
  };

  useEffect(() => {
    setForm({ ...form, about: about });
  }, [about]);

  useEffect(() => {
    if (!showModal) setValidations({});
  }, [showModal]);
  return (
    <>
      <div className={`${required ? passed ? 'border-2 border-green-1' : 'border-2 border-reed-1' : 'border-grey-200'} border w-full rounded-md mt-4 bg-white`}>
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <SparklesIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              About
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => handleShowModal()}
          >
            {form.about ? <PencilIcon width={22} /> : <PlusIcon width={22} />}
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate max-w-max lg:w-[680px] lg:max-w-[680px]">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <SparklesIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
                        About
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <form
                        className="bg-grey-lightest px-2 py-5"
                        name="signup"
                        onSubmit={submit}
                      >
                        <div className=" justify-center max-h-[60vh] overflow-y-auto">
                          <div className="mb-3">
                            <label className="text-gray-800 text-sm font-medium mb-4 leading-[140%] ">
                              You can write about your years of experience,
                              industry, or skills. People also talk about their
                              achievements or previous job experiences
                            </label>
                            <textarea
                              className="border w-full p-3 h-[100px] placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey mt-3"
                              type="text"
                              name="title"
                              id="title"
                              placeholder="Describes"
                              required
                              value={form.about}
                              onChange={(e) =>
                                setForm({ ...form, about: e.target.value })
                              }
                            ></textarea>

                            {typeof validations.title != "undefined"
                              ? validations.title.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                        </div>

                        <div className="flex mt-5 justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                            onClick={() => setShowModal(false)}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </form>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loading && <PageSpinner padding="10px 0px" />}
        {!loading && (
          <div className="mt-5 mb-3 px-5">
            {form.about ? (
              <div className="mt-4">
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border-inherit"
                  type="text"
                  name="phone"
                  id="phone"
                  value={form.about}
                  placeholder="About"
                  disabled
                />
              </div>
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AboutSection;
