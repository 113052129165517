import React, { Component } from "react";
import { inputIsOnlyAlphabetic } from "../helpers";

const countriesData = require("../data/countries.json");
const statesData = require("../data/states.json");
const citiesData = require("../data/cities.json");

export class ContryStateCities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StateId: "",
      CountryId: "",
      CityId: "",
      CountryData: [],
      StateData: [],
      CityData: [],
    };
  }

  componentDidMount() {
    this.setState({
      CountryData: countriesData,
    });
    if (this.props.countryValue) {
      const filteredStates = statesData.filter((state) => {
        return state.country_id == this.props.countryValue;
      });
      this.setState({
        StateData: filteredStates,
        CountryId: this.props.countryValue,
      });
    }
    if (this.props.stateValue) {
      const filteredCities = citiesData.filter((city) => {
        return (
          city.country_id == this.props.countryValue &&
          city.state_id == this.props.stateValue
        );
      });
      this.setState({
        StateId: this.props.stateValue,
        CityData: filteredCities,
      });
    }
  }

  OnCountryChange = (e) => {
    const filteredStates = statesData.filter((state) => {
      return state.country_id == e.target.value;
    });
    this.setState({
      CountryId: e.target.value,
      StateId: "",
      CityId: "",
      StateData: filteredStates,
      CityData: [],
    });
    this.props.onCountryChange(e.target.value);
  };

  OnStateChange = (e) => {
    const filteredCities = citiesData.filter((city) => {
      return (
        city.country_id == this.state.CountryId &&
        city.state_id == e.target.value
      );
    });
    this.setState({
      StateId: e.target.value,
      CityId: "",
      CityData: filteredCities,
    });
    this.props.onStateChange(e.target.value);
  };

  OnCityChange = (e) => {
    this.setState({
      CityId: e.target.value,
    });
    this.props.onCityChange(e.target.value);
  };

  OnZipCodeChange = (e) => {
    this.props.onZipCodeChange(e.target.value);
  };

  render() {
    return (
      <div>
        <div className="flex justify-between flex-col md:flex-row">
          <div className="mb-3 w-full">
            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
              Country
            </label>

            <select
              className="form-select appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      text-gray-800
                      text-[14px]
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      rounded-md
                      transition
                      ease-in-out
                      m-0
                      focus:border-main-violate"
              aria-label="Default select example"
              name="country"
              value={this.props.countryValue}
              onChange={(e) => this.OnCountryChange(e)}
              required
            >
              <option selected>Country</option>
              {this.state.CountryData.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          {!this.props.hideZipCode && (
            <div className="mb-3 w-full md:ml-4 ml-0 ">
              <label className="text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                Zip Code
              </label>
              <div>
                <input
                  className="border p-3 h-10 placeholder:text-sm text-gray-800 rounded-lg border border-solid border-gray-300 focus:border-main-violate w-full"
                  type="number"
                  name="fullname"
                  id="fullname"
                  placeholder="Zip code"
                  onChange={(e) => {
                    this.OnZipCodeChange(e);
                  }}
                  value={this.props.zipCodeValue}
                  required
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between flex-col md:flex-row">
          <div className="mb-3 w-full">
            <label className="text-gray-800 text-sm font-bold mb-4 leading-[140%]">
              Province
            </label>
            <select
              className="form-select appearance-none
                        block
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        text-[14px]
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded-md
                        transition
                        ease-in-out
                        m-0
                        focus:border-main-violate"
              aria-label="Default select example"
              name="state"
              value={this.props.stateValue}
              onChange={(e) => this.OnStateChange(e)}
              required
            >
              <option selected>Province</option>
              {this.state.StateData.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 w-full md:ml-4 ml-0 ">
            <label className="text-gray-800 text-sm font-bold mb-4 leading-[140%]">
              City
            </label>
            <select
              className="form-select appearance-none
                        block
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        text-[14px]
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded-md
                        transition
                        ease-in-out
                        m-0
                        focus:border-main-violate"
              aria-label="Default select example"
              name="city"
              value={this.props.cityValue}
              required
              onChange={(e) => this.OnCityChange(e)}
            >
              <option selected>City</option>
              {this.state.CityData.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
export default ContryStateCities;
