import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../Assets/bgCandidate.webp";
import config from "../config";
import "../css/login.css";
import Footer from "../Component/Footer";
import BtnSpinner from "../Component/BtnSpinner";
import PasswordInput from "../Component/PasswordInput";

const SignupPage = () => {
  let navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fullname, setFullname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [registering, setRegistering] = useState(false);

  const handleEmailChange = (e) => {
    const value = e.target.value || "";
    setEmail(value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value || "";
    setPhone(value);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value || "";
    setUsername(value);
  };

  const handleFullnameChange = (e) => {
    const value = e.target.value || "";
    setFullname(value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
  };

  const handleRecaptchaChange = (e) => {
    const value = e;
    setRecaptcha(value);
  };

  const handleSignup = (e) => {
    if (e.success) {
      toast.success(e.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
        onClose: () => navigate("/login", { replace: false }),
      });
    } else {
      toast.error(e.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRegistering(true);
    const signupData = JSON.stringify({
      username: username,
      phone: phone,
      recaptcha: recaptcha,
      email: email,
      name: fullname,
      password: password,
      confirmpassword: confirmPassword,
    });
    axios
      .post("/api/register", signupData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        handleSignup(response.data);
      })
      .catch((e) => {
        setRegistering(false);
        handleSignup(e.response.data);
      });
  };

  const myStyle = {
    backgroundImage: `url(${bgLogin})`,
    height: "179vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    // <div classNameName="container">
    //   <ToastContainer />
    //   <h1>Create Your Account</h1>
    //   <form name="signup" onSubmit={handleSubmit}>
    //       <input type="email" name="email" id="email" placeholder="Email Address" value={email} onChange={handleEmailChange} required />
    //       <input type="text" name="fullname" id="fullname" placeholder="Full Name" value={fullname} onChange={handleFullnameChange} required />
    //       <input type="password" name="password" id="password" placeholder="Password" value={password} onChange={handlePasswordChange} required />
    //       <input type="password" name="confirmpassword" id="confirmpassword" placeholder="Confirm Password" value={confirmPassword} onChange={handleConfirmPasswordChange} required />
    //       <input type="submit" />
    //   </form>
    // </div>

    <div style={myStyle}>
      <div className="container mx-auto pt-12">
        <ToastContainer />
        <div className="mx-auto max-w-lg px-4 justify-center">
          <div className="bg-white rounded-lg shadow lg:w-[476px] mb-[40px] ">
            <h1 className="mt-2 pt-[32px] font-bold text-black text-center text-[20px] tracking-[.01em] leading-[125%]">
              Create Account
            </h1>
            <p className="mt-3 text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal ">
              Hello candidate!
            </p>
            <p className="text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal px-4">
              Please input your information to create your account.
            </p>
            <form
              className="bg-grey-lightest px-10 py-5"
              name="signup"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className=" lg:w-[320px] justify-center lg:ml-10">
                <div className="mb-3">
                  <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                    Email
                  </label>
                  <input
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    required
                    autoComplete="email"
                  />
                </div>
                <div className="mb-3">
                  <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                    Full Name
                  </label>
                  <input
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                    type="text"
                    name="fullname"
                    id="fullname"
                    value={fullname}
                    onChange={handleFullnameChange}
                    placeholder="Full Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                    Username
                  </label>
                  <input
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="Username"
                    required
                    autoComplete="username"
                  />
                </div>
                <div className="mb-3">
                  <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                    Phone
                  </label>
                  <input
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                    Password
                  </label>
                  <PasswordInput onChange={(value) => handlePasswordChange(value)} />
                </div>
                <div className="mb-[16px]">
                  <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                    Confirm Password
                  </label>
                  <PasswordInput placeholder="Confirm Password" onChange={(value) => handleConfirmPasswordChange(value)} />
                </div>
                <div className="mb-4">
                  <div className="flex justify-start">
                    <div className="form-check">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none focus:border-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        name="agree1"
                        type="checkbox"
                        value="agree1"
                        id="agree1"
                        required
                      />
                    </div>
                    <div>
                      <label
                        className="form-check-label inline-block text-gray-700 text-[12px] leading-[16.8px] tracking-[.01em] text-left"
                        htmlFor="agree1"
                      >
                        I agree that is my responsibility to carry out the test
                        honestly and without the direct/ Indirect assistance of
                        others. I accept consequences in any form from the party
                        if I am proven to have committed a violation.
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start mt-3 items-center">
                    <div className="form-check">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none focus:border-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        value="agree2"
                        name="agree2"
                        id="flexCheckDefault"
                        required
                      />
                    </div>
                    <div>
                      <label
                        className="form-check-label inline-block text-gray-700 text-[12px] leading-[140%] text-left"
                        htmlFor="flexCheckDefault"
                      >
                        I agree on
                      </label>
                      <label
                        className="form-check-label inline-block text-gray-800 text-[12px] font-semibold leading-[140%]  ml-1 underline text-left"
                        htmlFor="flexCheckDefault"
                      >
                        Terms & Conditions
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <ReCAPTCHA
                    // sitekey="6LfLBpsiAAAAABCRKUWgJiCMBTp-ith_OzLI6Bws"
                    sitekey={config.RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </div>
              </div>

              <div className="flex mt-5 mb-4 justify-center">
                <button
                  className="bg-main-violate  w-[320px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                  type="submit"
                  style={{ textAlign: "-webkit-center" }}
                  disabled={registering}
                >
                  {registering ? <BtnSpinner /> : "Register"}
                </button>
              </div>
              <h4 className="text-gray-700 text-center text-sm tracking-normal mt-3 mb-[40px]">
                Already have an account?
                <Link
                  to="/login"
                  className="ml-1 text-gray-700 text-center text-sm tracking-normal font-semibold underline"
                >
                  Login
                </Link>
              </h4>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupPage;
