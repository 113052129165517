// profileSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personal: {
    data: {
      fullname: "",
      email: "",
      phonenumber: "",
      gender: "male",
      marital: "married",
      date_of_birth: "",
      address: "",
      country: "",
      zip_code: "",
      province: "",
      city: "",
      avatar: "",
      avatar_url: "",
      resume_file: "",
    },
    loaded: false,
  },
  account: {
    data: {
      email: "",
      name: "",
      role: "",
      username: "male",
    },
    loaded: false,
  },
  resumes: {
    data: [],
    loaded: false,
  },
  skills: {
    data: [],
    loaded: false,
  },
  experiences: {
    data: [],
    loaded: false,
  },
  certifications: {
    data: [],
    loaded: false,
  },
  languages: {
    data: [],
    loaded: false,
  },
  educationals: {
    data: [],
    loaded: false,
  },
  achievements: {
    data: [],
    loaded: false,
  },
  socials: {
    data: [],
    loaded: false,
  },
  preferences: {
    data: [],
    loaded: false,
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setResumes: (state, action) => {
      state.resumes.data = action.payload;
      state.resumes.loaded = true;
    },
    setSkills: (state, action) => {
      state.skills.data = action.payload;
      state.skills.loaded = true;
    },
    setExperiences: (state, action) => {
      state.experiences.data = action.payload;
      state.experiences.loaded = true;
    },
    setCertifications: (state, action) => {
      state.certifications.data = action.payload;
      state.certifications.loaded = true;
    },
    setLanguages: (state, action) => {
      state.languages.data = action.payload;
      state.languages.loaded = true;
    },
    setEducationals: (state, action) => {
      state.educationals.data = action.payload;
      state.educationals.loaded = true;
    },
    setAchievements: (state, action) => {
      state.achievements.data = action.payload;
      state.achievements.loaded = true;
    },
    setSocials: (state, action) => {
      state.socials.data = action.payload;
      state.socials.loaded = true;
    },
    setPersonal: (state, action) => {
      state.personal.data = action.payload;
      state.personal.loaded = true;
    },
    setAccount: (state, action) => {
      state.account.data = action.payload;
      state.personal.loaded = true;
    },
    setPreferences: (state, action) => {
      state.preferences.data = action.payload;
      state.preferences.loaded = true;
    },
  },
});

export const {
  setResumes,
  setSkills,
  setExperiences,
  setCertifications,
  setLanguages,
  setEducationals,
  setPreferences,
  setAchievements,
  setSocials,
  setPersonal,
  setAccount,
} = profileSlice.actions;
export default profileSlice.reducer;
