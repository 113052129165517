import React, { Component } from "react";
import moment from "moment";
import domtoimage from "dom-to-image";

export class AssessmentMultipleAnswer extends React.Component {
  constructor() {
    super();
    this.state = {
      id: "",
      answer: "[]",
      questionImage: null,
    };

    this.questionRef = React.createRef(null);
  }

  componentDidMount() {
    this.convertToImage();
  }

  convertToImage() {
    const self = this;
    const element = this.questionRef.current;
    if (element) {
      domtoimage
        .toPng(element)
        .then(function (dataUrl) {
          self.setState({ questionImage: dataUrl });
        })
        .catch(function (error) {
          // Handle error
          // console.error("Error converting element to image:", error);
        });
    }
  }

  setAnswer(e) {
    const value = e.target.value;
    const checked = e.target.checked;
    let tempAnswers;
    if (this.props.question.id !== this.state.id) {
      tempAnswers = JSON.parse("[]");
      this.setState({ id: this.props.question.id, answer: "[]" }, () => {});
    } else {
      tempAnswers = JSON.parse(this.state.answer);
    }
    if (checked) {
      tempAnswers.push(value);
    } else {
      tempAnswers = tempAnswers.filter((ans) => ans !== value);
    }
    this.setState({ answer: JSON.stringify(tempAnswers) });
    this.props.answer({
      ...this.props.question,
      answer: JSON.stringify(tempAnswers),
    });
  }

  render() {
    const { id = "", options = [] } = this.props.question;
    const currentAnswer = this.props.currentAnswer;
    return (
      <>
        <div className="rounded-[8px] w-full mdb:px-5 mdb:py-5 px-2 py-2 mt-2 mdb:mt-4">
          <div className="mdb:mb-5 mb-2">
            <h2 className="text-lg font-bold">Select an Option</h2>
          </div>
          <div className="radio-button mt-3 no-select">
            {options.map((option) => {
              return (
                <div className="radio-button mt-3" key={option.id}>
                  <div className="flex items-center mb-3">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                      type="checkbox"
                      id={`option_${option.id}`}
                      checked={
                        currentAnswer.find((curr) => curr.id === id)
                          ? currentAnswer
                              .find((curr) => curr.id === id)
                              .answer.includes(option.id.toString())
                          : false
                      }
                      value={option.id}
                      name="answer[]"
                      onChange={(e) => this.setAnswer(e)}
                    />
                    <label
                      htmlFor={`option_${option.id}`}
                      className="ml-2 text-[16px] leading-[140%]"
                    >
                      {option.value}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
