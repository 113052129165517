import { createContext } from 'react';

export const QuestionContext = createContext({
  question: {},
  setQuestion: () => { },
  assessment: {},
  setAssessment: () => { },
  loadingQuestion: false,
  candidateTestId: {},
  forceFinish: false,
  setForceFinish: () => { },
  assessmentLoading: true,
  setCloseTab: () => {
    alert("CLOSED");
  }
});