import React, { Component, useState } from "react";
import logo from "../Assets/HumataLogo.png";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import SidebarLanding from "./SidebarLanding";
import MenuIcon from "../Assets/menu.svg";
import CloseIcon from "../Assets/x.svg";

export default function NavbarLanding() {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <Navbar fluid={true} rounded={true} className="border-b md:border-none">
        <div className="flex flex-1 justify-start align-center">
          <Navbar.Brand href="/">
            <img src={logo} className="h-[70px] w-[70px]" alt="Flowbite Logo" />
            Highr ID
          </Navbar.Brand>
        </div>

        {/* Desktop Version */}
        {/* <Navbar.Toggle className="focus:ring-0 focus:ring-white hover:bg-white"/> */}
        <Navbar.Collapse className="hidden md:flex">
          <div
            className={`h-screen md:h-fit flex flex-col md:gap-8 md:flex-row  `}
          >
            <Navbar.Link href="/landing" activeClassName="active">
              Home
            </Navbar.Link>
            <Navbar.Link href="/landing/solutions" activeClassName="active">
              Solutions
            </Navbar.Link>
            <Navbar.Link href="/landing/pricing" activeClassName="active">
              Pricing
            </Navbar.Link>
            <Navbar.Link href="/landing/company" activeClassName="active">
              Company
            </Navbar.Link>
            <Navbar.Link href="/landing/contact_us" activeClassName="active">
              Contact
            </Navbar.Link>
          </div>
        </Navbar.Collapse>

        {/* Mobile Navbar */}
        <button className="p-4 md:hidden" onClick={toggleSidebar}>
          {isCollapsed ? (
            <img src={MenuIcon} alt="menu" />
          ) : (
            <img src={CloseIcon} alt="close" />
          )}
        </button>

        {/* Search bar */}
        <div className="flex-1 justify-center hidden md:flex md:justify-end align-center">
          <form className="max-w-sm px-4">
            <div className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <input
                type="text"
                placeholder="Search"
                className="w-full py-1 pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-indigo-600"
              />
            </div>
          </form>
        </div>
      </Navbar>

      <div
        className={`bg-white z-50 list-none md:hidden ${
          isCollapsed
            ? "transform translate-x-full transition-transform duration-300 ease-in-out"
            : `transform translate-x-0 transition-transform duration-300 ease-in-out h-screen`
        }`}
      >
        <Navbar.Link href="/landing">Home</Navbar.Link>
        <Navbar.Link href="/landing/solutions">Solutions</Navbar.Link>
        <Navbar.Link href="/landing/pricing">Pricing</Navbar.Link>
        <Navbar.Link href="/landing/company">Company</Navbar.Link>
        <Navbar.Link href="/landing/contact_us">Contact</Navbar.Link>
      </div>
    </div>
  );
}
