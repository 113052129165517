import axios from 'axios';
export const uploadHelpInquiry = async ({ formData }) => {
    try {
        const response = await axios
            .post(`/api/help-inquiry/d/upload`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token || null}`,
                },
            })

        return response.data;
    } catch (error) {
        return false;
    }
}