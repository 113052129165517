import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguages as setCertifications } from "../../../features/profile/profileSlice";
import Plus from "../../../Assets/Plus.png";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import { toast } from "react-toastify";
import { LanguageIcon } from "@heroicons/react/24/solid";
import FileUploadIcon from "../../../Component/Icons/FileUploadIcon";
import CardLanguageCertificate from "../../../Component/CardLanguageCertificate/CardLanguageCertificate";
import { getLanguages, postLanguages, uploadLanguages } from "../../../api";
const LanguageSection = () => {
  const { data: certifications, loaded } = useSelector(
    (state) => state.profile.languages
  );
  const dispatch = useDispatch();
  const [validations, setValidations] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [form, setForm] = useState({
    id: null,
    title: "",
    institution: "",
    issued_date: "",
    expired_date: "",
    is_lifetime: 0,
    credential_id: "",
    credential_url: "",
    certification_file_id: null,
  });

  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const result = await postLanguages(form);
    if (result.success) {
      setForm({
        id: null,
        title: "",
        institution: "",
        issued_date: "",
        expired_date: "",
        is_lifetime: 0,
        credential_id: "",
        credential_url: "",
        certification_file_id: null,
      });
      setShowModal(false);
      fetchLanguages();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(result.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setSaving(false);
  };

  const handleFile = async (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("certification_file", e.target.files[0]);
    const result = await uploadLanguages(formData);
    if (result.success) {
      setFileUpload(result.data);
      setForm({ ...form, certification_file_id: result.data.id });
      toast.success(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setUploading(false);
  };
  const fetchLanguages = async () => {
    setLoading(true);
    const result = await getLanguages();
    if (result.success) {
      if (result.data != null) {
        dispatch(setCertifications(result.data));
      }
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showModal) setValidations({});
  }, [showModal]);

  useEffect(() => {
    async function getInformationApi() {
      if (!loaded) {
        await fetchLanguages();
      }
    }

    getInformationApi();
  }, []);
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <LanguageIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Language
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            <img src={Plus} alt="icon" className="h-[24px] w-[24px]" />
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate ">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate max-w-max lg:w-[680px] lg:max-w-[680px]">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <LanguageIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3">
                        Language
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <form
                        className="bg-grey-lightest flex flex-col items-center"
                        name="work_experience"
                        onSubmit={submit}
                      >
                        <div className="max-h-[60vh] overflow-y-auto w-full max-w-lg">
                          <div className="mb-4">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Title
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="title"
                              id="title"
                              placeholder="Ex: IELTS"
                              required
                              value={form.title}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  title: e.target.value,
                                })
                              }
                            />
                            {typeof validations.title != "undefined"
                              ? validations.title.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div className="mb-4">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Instituion Name
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="title"
                              id="title"
                              placeholder="Ex: British Council"
                              required
                              value={form.institution}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  institution: e.target.value,
                                })
                              }
                            />
                            {typeof validations.institution != "undefined"
                              ? validations.institution.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div className="grid grid-cols-2 gap-4 mb-1">
                            <div className="mb-3 md:mb-0">
                              <label className="text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Issued Date
                              </label>
                              <input
                                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                type="date"
                                name="softskill"
                                id="softskill"
                                placeholder="Select Date"
                                required
                                value={form.issued_date}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    issued_date: e.target.value,
                                  })
                                }
                              />
                              {typeof validations.issued_date != "undefined"
                                ? validations.issued_date.map((e) => {
                                    return (
                                      <span className="input-error-message">
                                        {e}
                                      </span>
                                    );
                                  })
                                : ""}
                            </div>
                            <div className="">
                              <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Expired Date
                              </label>

                              <input
                                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
                                type="date"
                                name="softskill"
                                id="softskill"
                                placeholder="Select Date"
                                required
                                disabled={form.is_lifetime == 1 ? true : false}
                                value={
                                  form.is_lifetime == 1
                                    ? null
                                    : form.expired_date
                                }
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    expired_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="flex justify-start mb-4">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="1"
                                checked={form.is_lifetime}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    is_lifetime: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                              <label
                                className="form-check-label inline-block text-grey-darkest text-[12px] leading-[16.8px] mb-px"
                                htmlFor="inlineCheckbox1"
                              >
                                This credential does not expire
                              </label>
                            </div>
                          </div>
                          <div className="mb-4">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Credential ID (Optional)
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="credentialURL"
                              id="credentialURL"
                              placeholder="Credential ID (Optional)"
                              value={form.credential_id}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  credential_id: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="mb-4">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Credential Link (Optional)
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="credentialURL"
                              id="credentialURL"
                              placeholder="Credential URL (Optional)"
                              value={form.credential_url}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  credential_url: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Upload File (Optional)
                            </label>
                            {uploading ? (
                              <PageSpinner padding={"30px"} />
                            ) : fileUpload ? (
                              <div className="mt-3 mb-3 relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                                <span className="block text-soft-grey text-[14px]">
                                  {fileUpload.original_filename}{" "}
                                  {/* {profile.resume_file.replace(/^.*[\\\/]/, "")} */}
                                </span>
                                <button
                                  onClick={() => {
                                    setFileUpload(null);
                                  }}
                                >
                                  <FileUploadIcon />
                                </button>
                              </div>
                            ) : (
                              <input
                                className="border mt-3 mb-3 w-full placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                type="file"
                                accept=".pdf"
                                onChange={(e) => handleFile(e)}
                              />
                            )}

                            <p className="text-[14px] text-grey-500  leading-[1.5] mb-4">
                              Maximum <b>PDF</b> size 3 Mb
                            </p>
                          </div>
                        </div>

                        <div className="flex mt-5 justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                            onClick={() => setShowModal(false)}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </form>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {certifications.length > 0 ? (
              <CardLanguageCertificate
                experiences={certifications}
                onShowEdit={(id) => {
                  const selectedCertification = certifications.find(
                    (cert) => cert.id == id
                  );
                  setForm(selectedCertification);
                  setShowModal(true);
                }}
              />
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default LanguageSection;
