import { React, useState, useEffect } from 'react'
// import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bgLogin from "../Assets/bgCandidate.webp";
import IconCv from "../Assets/IconCv.png";
import ContryStateCities from "../Component/ContryStateCities";
import Footer from "../Component/Footer";

const PersonalInformationPage = () => {

  let navigate = useNavigate();
  
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", {replace: true});
    }
  });

  const myStyle={
    backgroundImage: `url(${bgLogin})`,
    height:'183vh',
    marginTop:'-10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div style={myStyle}>
      <div className="container mx-auto pt-12">
        <ToastContainer />
        <div className="mx-auto max-w-lg justify-center">
            <div className="bg-white rounded-lg shadow lg:w-[724px] mb-[40px] lg:ml-[-110px]">
                <h1 className="mt-2 pt-[32px] font-bold text-black text-center text-[20px] tracking-[.01em] leading-[125%]">
                    Personal Information
                </h1>
                <p className="mt-3 text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal">Please enter your personal information to continue.</p>
                <form className="bg-grey-lightest px-10 py-5" name="signup"  >
                    <div className=" lg:w-[476px] justify-center lg:ml-[80px]">
                        <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Full Name
                            </label>
                            <input className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-main-violate" type="text" name="fullname" id="fullname"  placeholder="Full Name" required/>
                        </div>
                        <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Email
                            </label>
                            <input className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-main-violate" type="email" name="email" id="email" placeholder="Email" required />
                        </div>
                        <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Phone Number
                            </label>
                            <input className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-main-violate" type="text" name="phone" id="phone" placeholder="Phone Number" required/>
                        </div>
                        <div className="flex justify-between">
                              <div className="mb-3">
                                <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                    Gender
                                </label>
                                <div className="flex justify-center">
                                      <div className="w-[230px]">
                                        <select className="form-select appearance-none
                                          block
                                          w-full
                                          px-3
                                          py-2
                                          text-soft-grey
                                          text-[14px]
                                          bg-white bg-clip-padding bg-no-repeat
                                          border border-solid border-gray-300
                                          rounded-md
                                          transition
                                          ease-in-out
                                          m-0
                                          focus:text-gray-700 focus:bg-white  focus:outline-main-violate" aria-label="Default select example">
                                            <option selected>Gender</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                      </div>
                                </div> 
                              </div>
                              <div className="mb-3 ml-4">
                                  <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                      Marital Status
                                  </label>
                                  
                                  <div className="flex justify-center">
                                      <div className="w-[230px]">
                                        <select className="form-select appearance-none
                                          block
                                          w-full
                                          px-3
                                          py-2
                                          text-soft-grey
                                          text-[14px]
                                          bg-white bg-clip-padding bg-no-repeat
                                          border border-solid border-gray-300
                                          rounded-md
                                          transition
                                          ease-in-out
                                          m-0
                                          focus:text-gray-700 focus:bg-white  focus:outline-main-violate" aria-label="Default select example">
                                            <option selected>Marital Status</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                      </div>
                                  </div>
                              </div>
                        </div>
                        <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Date of Birth
                            </label>
                            <input className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-main-violate" type="date" name="fullname" id="fullname"  placeholder="Select Date of Birth" required/>
                        </div>
                        <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Addres
                            </label>
                            <textarea className="border w-full p-3 h-[63px] placeholder:text-sm rounded-lg focus:outline-main-violate" type="text" name="phone" id="phone" placeholder="Address" required></textarea>
                        </div>
                        <ContryStateCities />
                        <div className="mb-5">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Cv
                            </label>
                            <div className="relative h-[80px] rounded-lg border bg-white flex justify-center items-center hover:outline-none">
                                <div className="absolute">
                                    <div className="flex flex-col items-center "> 
                                      <img src={IconCv} alt="icon" className="h-[24px] w-[24px]"/>
                                      <span className="block text-soft-grey text-[14px] leading-[140%] mt-3">Upload or Drag your CV here</span> 
                                    </div>
                                </div> <input type="file" className="h-full w-full opacity-0" name="uploadcv"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex mt-5 justify-center ml-[-8px]">
                        <button className="bg-main-violate  w-[320px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 hover:bg-dark-violate" type="submit">
                            Save
                        </button>
                    </div>
                    <div className="flex justify-center ml-[-8px]">
                        <Link to="/login" className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2">
                        Log Out
                        </Link>
                    </div> 
                </form>
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default PersonalInformationPage;
