import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAchievements } from "../../../features/profile/profileSlice";
import Plus from "../../../Assets/Plus.png";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import { toast } from "react-toastify";
import { CardAchievement } from "../../../Component/CardAchievement/CardAchievement";
import { StarIcon } from "@heroicons/react/24/solid";
import { getAchievement, postAchievement } from "../../../api/profileApi";
const AchievementSection = () => {
  const { data: achievements, loaded } = useSelector(
    (state) => state.profile.achievements
  );
  const dispatch = useDispatch();
  const [validations, setValidations] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = useState(false);
  // const [achievements, setAchievements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    id: 1,
    user_id: "",
    title: "",
    institution: "",
    issued_date: "",
  });

  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const result = await postAchievement(form);
    if (result.success) {
      setForm({
        id: 1,
        user_id: "",
        title: "",
        institution: "",
        issued_date: "",
      });
      setShowModal(false);
      fetchAchievements();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setSaving(false);
  };

  const fetchAchievements = async () => {
    setLoading(true);
    const result = await getAchievement();
    if (result.success) {
      if (result.data != null) {
        dispatch(setAchievements(result.data));
      }
    } else {
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(result.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showModal) setValidations({});
  }, [showModal]);

  useEffect(() => {
    async function getInformationApi() {
      if (!loaded) await fetchAchievements();
    }

    getInformationApi();
  }, []);
  return (
    <>
      <div className="border border-grey-200 w-full rounded-md mt-4 bg-white">
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <StarIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Achievement
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            <img src={Plus} alt="icon" className="h-[24px] w-[24px]" />
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <StarIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
                        Achievement
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <form
                        className="bg-grey-lightest px-2 py-5 max-w-[580px]"
                        name="signup"
                        onSubmit={submit}
                      >
                        <div className="max-w-[580px] justify-center max-h-[60vh] overflow-y-auto">
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Title
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="title"
                              id="title"
                              placeholder="Title"
                              required
                              value={form.title}
                              onChange={(e) =>
                                setForm({ ...form, title: e.target.value })
                              }
                            />
                            {typeof validations.title != "undefined"
                              ? validations.title.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Institution Name
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="institationName"
                              id="institationName"
                              placeholder="Institution Name"
                              required
                              value={form.institution}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  institution: e.target.value,
                                })
                              }
                            />
                            {typeof validations.institution != "undefined"
                              ? validations.institution.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Issued Date
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                              type="date"
                              name="softskill"
                              id="softskill"
                              placeholder="Select Date"
                              required
                              value={form.issued_date}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  issued_date: e.target.value,
                                })
                              }
                            />
                            {typeof validations.issued_date != "undefined"
                              ? validations.issued_date.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                        </div>

                        <div className="flex mt-5 justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                            onClick={() => setShowModal(false)}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </form>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {achievements.length > 0 ? (
              <CardAchievement
                experiences={achievements}
                onShowEdit={(id) => {
                  const selectedAchievements = achievements.find(
                    (cert) => cert.id == id
                  );
                  setForm(selectedAchievements);
                  setShowModal(true);
                }}
              />
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AchievementSection;
