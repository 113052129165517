// profileSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    faq: {
        data: [],
        loaded: false,
    },
};

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        setFaqs: (state, action) => {
            state.faq.data = action.payload;
            state.faq.loaded = true;
        },
    },
});

export const { setFaqs } = faqSlice.actions;
export default faqSlice.reducer;