import React, { Component } from "react";
// import "./index.css";
import moment from "moment";
import domtoimage from "dom-to-image";

export class AssessmentMultiple extends React.Component {
  constructor() {
    super();
    this.state = {
      answer: "",
      questionImage: null,
    };
    this.questionRef = React.createRef(null);
  }

  componentDidMount() {
    this.convertToImage();
  }
  convertToImage() {
    const self = this;
    const element = this.questionRef.current;
    if (element) {
      domtoimage
        .toPng(element)
        .then(function (dataUrl) {
          self.setState({ questionImage: dataUrl });
        })
        .catch(function (error) {
          // Handle error
          // console.error("Error converting element to image:", error);
        });
    }
  }

  setAnswer(answer) {
    this.setState({ answer: answer });
    this.props.answer({ ...this.props.question, answer: answer });
  }

  render() {
    const {
      id = "",
      content = "",
      order = "",
      options = [],
    } = this.props.question;
    const currentAnswer = this.props.currentAnswer;
    return (
      <>
        <div className="rounded-[8px] w-full mdb:px-5 mdb:py-5 px-2 py-2 mt-2 mdb:mt-4">
          <div className="mdb:mb-5 mb-2">
            <h2 className="text-lg font-bold">Select an Option</h2>
          </div>
          <div className="radio-button mt-3">
            {options.map((option, index) => {
              return (
                <div className="flex items-center mb-3 no-select ">
                  <input
                    id={`purple-radio-${index}`}
                    type="radio"
                    name="multiple"
                    value={option.id}
                    checked={
                      currentAnswer.find((curr) => curr.id == id)
                        ? currentAnswer.find((curr) => curr.id == id).answer ==
                          option.id
                          ? true
                          : false
                        : false
                    }
                    onChange={(e) => this.setAnswer(e.target.value)}
                    className="w-4 h-4 text-main-violate bg-gray-100 border-gray-300 focus:ring-main-violate hover:cursor-pointer dark:focus:ring-main-violate dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor={`purple-radio-${index}`}
                    className="ml-2 text-[16px] leading-[140%] hover:cursor-pointer"
                  >
                    {option.value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
