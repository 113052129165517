import { Component } from 'react'
import SimpleTable from './SimpleTable';
class PaginationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };
    }

    paginate = (page, fromNavigate = false, type = '') => {
        if (this.state.currentPage === this.props.totalPages && fromNavigate && type == 'next') return;
        if (this.state.currentPage === 1 && fromNavigate && type == 'previous') return;
        if (this.state.currentPage === page) return;
        this.setState({ currentPage: page })
        this.props.currentPage(page);
    }

    render() {
        const { currentPage,
            itemsPerPage,
            totalItems,
            totalPages,
            itemsFrom,
            itemsTo,
            content,
            headers,
            filters,
            search,
            checkbox,
            onClearFilter,
            isSticky = false,
            stickyHeader = [],
            stickyContent = null
        } = this.props
        const buttons = [];
        if (totalPages <= 5) {
            for (let page = 1; page <= totalPages; page++) {
                buttons.push(
                    <a
                        key={page}
                        href="#"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === this.state.currentPage
                            ? 'bg-main-violate-50 text-main-violate-600'
                            : 'text-gray-900'
                            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                        onClick={() => this.paginate(page)}
                    >
                        {page}
                    </a>
                );
            }
        } else {
            if (this.state.currentPage <= 3) {
                // Render first 4 pages
                for (let page = 1; page <= 4; page++) {
                    buttons.push(
                        <a
                            key={page}
                            href="#"
                            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === this.state.currentPage
                                ? 'bg-main-violate-50 text-main-violate-600'
                                : 'text-gray-900'
                                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                            onClick={() => this.paginate(page)}
                        >
                            {page}
                        </a>
                    );
                }

                // Render ellipsis
                buttons.push(
                    <span
                        key="ellipsis"
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
                    >
                        ...
                    </span>
                );

                // Render last 2 pages
                for (let page = totalPages - 1; page <= totalPages; page++) {
                    buttons.push(
                        <a
                            key={page}
                            href="#"
                            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === this.state.currentPage
                                ? 'bg-main-violate-50 text-main-violate-600'
                                : 'text-gray-900'
                                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                            onClick={() => this.paginate(page)}
                        >
                            {page}
                        </a>
                    );
                }
            } else if (this.state.currentPage >= totalPages - 2) {
                // Render first page
                buttons.push(
                    <a
                        key={1}
                        href="#"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${1 === this.state.currentPage
                            ? 'bg-main-violate-50 text-main-violate-600'
                            : 'text-gray-900'
                            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                        onClick={() => this.paginate(1)}
                    >
                        1
                    </a>
                );

                // Render ellipsis
                buttons.push(
                    <span
                        key="ellipsis"
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
                    >
                        ...
                    </span>
                );

                // Render last 4 pages
                for (let page = totalPages - 3; page <= totalPages; page++) {
                    buttons.push(
                        <a
                            key={page}
                            href="#"
                            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === this.state.currentPage
                                ? 'bg-main-violate-50 text-main-violate-600'
                                : 'text-gray-900'
                                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                            onClick={() => this.paginate(page)}
                        >
                            {page}
                        </a>
                    );
                }
            } else {
                // Render first page
                buttons.push(
                    <a
                        key={1}
                        href="#"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${1 === this.state.currentPage
                            ? 'bg-main-violate-50 text-main-violate-600'
                            : 'text-gray-900'
                            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                        onClick={() => this.paginate(1)}
                    >
                        1
                    </a>
                );

                // Render ellipsis
                buttons.push(
                    <span
                        key="ellipsis"
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
                    >
                        ...
                    </span>
                );

                // Render current page and the next 2 pages
                for (let page = this.state.currentPage - 1; page <= this.state.currentPage + 1; page++) {
                    buttons.push(
                        <a
                            key={page}
                            href="#"
                            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === this.state.currentPage
                                ? 'bg-main-violate-50 text-main-violate-600'
                                : 'text-gray-900'
                                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                            onClick={() => this.paginate(page)}
                        >
                            {page}
                        </a>
                    );
                }

                // Render ellipsis
                buttons.push(
                    <span
                        key="ellipsis"
                        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
                    >
                        ...
                    </span>
                );

                // Render last page
                buttons.push(
                    <a
                        key={totalPages}
                        href="#"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${totalPages === this.state.currentPage
                            ? 'bg-main-violate-50 text-main-violate-600'
                            : 'text-gray-900'
                            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                        onClick={() => this.paginate(totalPages)}
                    >
                        {totalPages}
                    </a>
                );
            }
        }

        return (
            <>
                <SimpleTable checkbox={checkbox} filters={filters} headers={headers} content={content} search={search} onClearFilter={onClearFilter} isSticky={isSticky} stickyHeader={stickyHeader} stickyContent={stickyContent} />
                <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                    <div className="flex flex-1 justify-between sm:hidden">
                        <a disabled={this.state.currentPage === 1} onClick={(e) => {e.preventDefault(); this.paginate(this.state.currentPage - 1, true, 'previous')}} href="#" className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${this.state.currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>Previous</a>
                        <a disabled={this.state.currentPage === totalPages} onClick={(e) => {e.preventDefault(); this.paginate(this.state.currentPage + 1, true, 'next')}} href="#" className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${this.state.currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}>Next</a>
                    </div>
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Showing
                                <span className="font-md"> {itemsFrom} </span>
                                -
                                <span className="font-md"> {itemsTo} </span>
                                of
                                <span className="font-md"> {totalItems} </span>
                            </p>
                        </div>
                        <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                <a
                                    href="#"
                                    className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${this.state.currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={(e) => {e.preventDefault(); this.paginate(this.state.currentPage - 1, true, 'previous')}}
                                    disabled={this.state.currentPage === 1}
                                >
                                    <span className="sr-only">Previous</span>
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                    </svg>
                                </a>
                                {buttons}
                                <a
                                    href="#"
                                    className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${this.state.currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={(e) => {e.preventDefault(); this.paginate(this.state.currentPage + 1, true, 'next')}}
                                    disabled={this.state.currentPage === totalPages}
                                >
                                    <span className="sr-only">Next</span>
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                    </svg>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </>


        )
    }
}

export default PaginationTable;