import { useEffect, useState } from "react";
import './index.css';

const JustTest = () => {
  const [scrollOffset, setScrollOffset] = useState(0);

  const handleScroll = () => {
    setScrollOffset(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="parallax-container">
      <div className="parallax-bg" style={{ transform: `translateY(-${scrollOffset * 0.5}px)` }} />
      <div className="parallax-content">
        <h1>Halaman dengan Efek Paralaks</h1>
        <p>Ini adalah konten halaman dengan efek paralaks.</p>
      </div>
    </div>
  );
};

export default JustTest;
