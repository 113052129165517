import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingLayout from "./Layouts/LandingLayout";
import Footer from "./Component/FooterLanding.js";
import NavbarB2C from "./Component/NavbarB2C";
import CareerProfilePage from "./B2CPages/CareerProfilePage";
import ReportsPage from "./B2CPages/ReportsPage";
import PrintPage from "./B2CPages/ReportsPage/PrintPage";

const AppB2C = () => {
  return (
    <div className="relative">
      <Routes>
        <Route
          path=":slug/reports-print/:interest_code"
          element={<PrintPage />}
        />
        <Route path=":slug" element={<LandingLayout />}>
          <Route path="career-profile" element={<CareerProfilePage />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path="reports/:interest_code" element={<ReportsPage />} />
          {/* <Route path="pricing" element={<PricingPage />} />
          <Route path="contact_us" element={<ContactUs />} />
          <Route path="solutions" element={<SolutionPage />} />
          <Route path="company" element={<CompanyPage />} /> */}
          {/* <Route path="/" element={<HomePage />} /> */}
        </Route>
      </Routes>
    </div>
  );
};

export default AppB2C;
