import axios from "axios";
import { useEffect, useState } from "react";
import { getInterview, postInterview } from "../../api";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import PopUp from "../../Component/PopUp";
import CalendarEventPopup from "./Popup/CalendarEventPopup";
import PageSpinner from "../../Component/PageSpinner";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
const InterviewPage = () => {
  const [interview, setInterview] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [schedules, setSchedules] = useState(null);
  const [invitation, setInvitation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [showAddEventPopup, setShowAddEventPopup] = useState(false);
  const daysOfWeek = [
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ];

  const fetchInterview = async () => {
    setLoading(true);
    const response = await getInterview();
    if (response.success) {
      setInterview(response.data.active_interview);
      setSchedules(response.data.schedules);
      setInvitation(response.data.invitation_interview);
    } else {
      toast.error(response.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  const handleEvent = async (calendar) => {
    setSelectedCalendar(calendar);
    setShowAddEventPopup(true);
  };

  const handleApplyEvent = async (calendar, time) => {
    setSubmitting(true);
    const form = {
      schedule_id: time.id,
    };

    const response = await postInterview(form);
    if (response.success) {
      toast.success(response.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      setSelectedCalendar(null);
      setShowAddEventPopup(false);
      window.location.reload();
    } else {
      toast.error(response.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    fetchInterview();
  }, []);

  useEffect(() => {
    if (schedules) {
      const getDatesInRange = (start, end) => {
        const startDt = new Date(start);
        const endDt = new Date(end);
        const dates = [];

        while (startDt <= endDt) {
          dates.push(new Date(startDt));
          startDt.setDate(startDt.getDate() + 1);
        }

        return dates;
      };

      const getMonthName = (month) => {
        const monthNames = [
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "November",
          "Desember",
        ];
        return monthNames[month - 1];
      };

      const firstDayOfWeek = (new Date(schedules.start_date).getDay() + 6) % 7;
      const dates = getDatesInRange(schedules.start_date, schedules.end_date);
      const totalDays = dates.length + firstDayOfWeek;
      const newCalendar = Array(totalDays).fill(null);

      for (
        let i = firstDayOfWeek, dateIndex = 0;
        i < newCalendar.length && dateIndex < dates.length;
        i++
      ) {
        const date = dates[dateIndex];
        const dateInString = moment(date).format("YYYY-MM-DD");
        // FIND EVENT FROM INTERVIEW SCHEDULE
        const event = schedules.schedules.find(
          (schedule) => schedule.date == dateInString
        );
        const dayOfMonth = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        newCalendar[i] = {
          id: dateIndex,
          data: `${dayOfMonth.toString().padStart(2, "0")} ${getMonthName(
            month
          )} ${year}`,
          events: event ? event.times : [], // contoh data event
        };
        dateIndex++;
      }
      setCalendars(newCalendar);
    }
  }, [schedules]);

  const myStyle = {
    background: `#FBF3FF`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
  };
  return (
    <div
      style={myStyle}
      className="px-[10px] py-[20px] md:px-[44px] md:py-[24px]"
    >
      <ToastContainer />
      {showAddEventPopup && (
        <PopUp>
          <CalendarEventPopup
            onClose={() => setShowAddEventPopup(false)}
            calendar={selectedCalendar}
            onApply={(calendar, time) => handleApplyEvent(calendar, time)}
            submitting={submitting}
          />
        </PopUp>
      )}
      {loading && (
        <div className="card flex flex-col w-full mb-6">
          <PageSpinner />
        </div>
      )}
      {!loading && (
        <>
          {interview && (
            <div className="card flex flex-col w-full mb-6">
              <div className="flex justify-between w-full">
                <div className="flex flex-col w-full">
                  <>
                    <p className="text-lg md:text-xl text-gray-900 font-bold mb-2">
                      {interview
                        ? interview.interview.name
                        : "Active Interview"}
                    </p>
                    {interview && (
                      <>
                        <div className="mb-4">
                          <p className="text-sm md:text-base text-grey-600 mb-2">
                            Your interview scheduled at{" "}
                            <b>
                              {moment(interview.date).format("D MMMM YYYY")},{" "}
                              {moment(interview.start_time, "HH:mm:ss").format(
                                "HH:mm"
                              )}{" "}
                              -{" "}
                              {moment(interview.end_time, "HH:mm:ss").format(
                                "HH:mm"
                              )}
                            </b>
                          </p>
                        </div>
                        <button
                          onClick={(e) => {
                            window.open(interview.meeting_link, '_blank');
                          }}
                          className="max-w-max hover:bg-violet-600 cursor-pointer bg-[#128BDB] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed flex items-center gap-2"
                        >
                          Join to Google Meet <ChevronRightIcon width={16} />
                        </button>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          )}
          {!interview && (
            <div className="card flex flex-col w-full mb-6">
              <div className="flex justify-between w-full">
                <div className="flex flex-col w-full">
                  <>
                    <p className="text-lg md:text-xl text-gray-900 font-bold mb-2">
                      Active Invitation
                    </p>
                    {invitation && (
                      <>
                        <div>
                          <p className="text-sm md:text-base text-grey-600 mb-2">
                            <b>Congratulation</b>, you have been invited to
                            interview{" "}
                          </p>
                          <p className="text-sm md:text-base text-grey-600 mb-2">
                            Interview : {invitation.interview.name}
                          </p>
                          <p className="text-sm md:text-base text-grey-600">
                            Please choose your schedule
                          </p>
                        </div>
                      </>
                    )}
                    {!invitation && (
                      <>
                        <p className="text-sm md:text-base text-grey-600">
                          You have no active interview invitation
                        </p>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          )}
          <div className="card flex flex-col w-full">
            <div className="flex justify-between w-full">
              <div className="flex flex-col w-full">
                <p className="text-lg md:text-xl text-gray-900 font-bold mb-5">
                  Schedules
                </p>
                {calendars.length <= 0 && (
                  <>
                    <div>
                      <p className="text-grey-600">No schedules</p>
                    </div>
                  </>
                )}
                {calendars.length > 0 && (
                  <>
                    <div>
                      <div style={styles.container}>
                        <div
                          className="bg-main-violate rounded-md"
                          style={styles.header}
                        >
                          {daysOfWeek.map((day) => (
                            <div
                              className="text-white"
                              key={day}
                              style={styles.headerBox}
                            >
                              {day}
                            </div>
                          ))}
                        </div>
                        <div style={styles.calendar}>
                          {calendars.map((calendar, index) => (
                            <div
                              className={`${
                                calendar?.events?.length > 0
                                  ? calendar.events.filter(
                                      (event) => event.attendee
                                    ).length == calendar?.events?.length
                                    ? "bg-main-violate"
                                    : " bg-green-1"
                                  : "bg-soft-grey-2"
                              } h-[100px] border border-1 border-soft-gray hover:cursor-pointer hover:border-green-1`}
                              key={index}
                              style={styles.dayBox}
                              onClick={() => {
                                if (calendar.events.length > 0) {
                                  handleEvent(calendar);
                                }
                              }}
                            >
                              {calendar && (
                                <>
                                  {calendar.events.length > 0 && (
                                    <>
                                      <p
                                        className={`${
                                          calendar?.events?.length > 0 &&
                                          "text-white"
                                        }`}
                                      >
                                        {calendar.data}
                                      </p>
                                      <p
                                        className={`${
                                          calendar?.events?.length > 0 &&
                                          "text-white"
                                        } mt-2`}
                                      >
                                        {
                                          calendar.events.filter(
                                            (event) => event.attendee
                                          ).length
                                        }
                                        /{calendar.events.length} Slot
                                      </p>
                                    </>
                                  )}
                                  {calendar.events.length == 0 && (
                                    <>
                                      <p>{calendar.data}</p>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    margin: "0 auto",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "10px",
    padding: "10px 0",
  },
  headerBox: {
    textAlign: "center",
    fontWeight: "bold",
  },
  calendar: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "10px",
    padding: "10px 0",
  },
  dayBox: {
    borderRadius: "4px",
    padding: "10px",
    textAlign: "center",
    fontSize: "0.9em",
  },
  "@media (max-width: 600px)": {
    header: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "5px",
      padding: "5px 0",
    },
    calendar: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: "5px",
      padding: "5px 0",
    },
    dayBox: {
      padding: "5px",
      fontSize: "0.8em",
    },
  },
};
export default InterviewPage;
