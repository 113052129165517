import React, { Component } from "react";

export class FooterGuest extends Component {

  render() {
    return (
      <div className="fixed w-full h-[96px] bottom-0 invisible md:visible">
        <footer className="text-center h-[96px] flex justify-around bg-[#111928] items-center" >
          <div className="text-gray-800 text-white text-center text-[14px] tracking-normal leading-[140%]">
            Candidate Dashboard v2.00 © 2023
          </div>
          <div className="text-gray-800 text-white text-center text-[14px] tracking-normal leading-[140%]">
            Need Help? Contact Us
          </div>
          <div className="text-gray-800 text-white text-center text-[14px] tracking-normal leading-[140%]">
            <a className="text-gray-800 text-white text-center text-[14px] tracking-normal leading-[140%] ml-1" href="https://tailwind-elements.com/">Powered by Highr ID</a>
          </div>
        </footer>
      </div>
    );
  }
}
export default FooterGuest;