import React, { Component } from "react";
// import "./index.css";
import moment from "moment";

export class AssessmentTypeB extends React.Component {
  constructor() {
    super();
    this.state = {
      id: "",
      answer: "",
    };
  }
  // CHANGE
  setAnswer(answer) {
    let tempAnswers;
    if (this.props.question.id !== this.state.id) {
      tempAnswers = "";
      this.setState({ id: this.props.question.id, answer: "" }, () => {});
    } else {
      tempAnswers = this.state.answer;
    }
    this.setState({ answer: tempAnswers });
    this.props.answer({ ...this.props.question, answer: answer });
  }

  render() {
    const {
      id = "",
      content = "",
      order = "",
      type = null,
      kind = "",
      score = "",
      options = [],
    } = this.props.question;
    const currentAnswer = this.props.currentAnswer;
    return (
      <>
        <div className="rounded-[8px] w-full mdb:px-5 mdb:py-5 px-2 py-2 mt-2 mdb:mt-4">
          <div className="mdb:mb-5 mb-2">
            <h2 className="text-lg font-bold">
              Choose the most close answer to the statement
            </h2>
          </div>
          {/* <h2
            className="w-full text-[16px] leading-[140%] flex no-select"
            ref={this.questionRef}
          >
            {order}. {content}
          </h2> */}

          <div className="flex mt-0 lg:mt-[40px] ml-0  mb-4">
            <p className="text-[14px] leading-[140%] mr-4">
              {options.find((option) => option.indicator == "min").value}
            </p>
            <div className="flex">
              <button
                className={`border border-gray-300 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4 ${
                  this.state.answer == 1
                    ? "bg-main-violate"
                    : currentAnswer.find((curr) => curr.id == id)
                    ? currentAnswer.find((curr) => curr.id == id).answer == 1
                      ? "bg-main-violate"
                      : ""
                    : ""
                }`}
                type="button"
                onClick={(e) => this.setAnswer(1)}
              ></button>
              <button
                className={`border border-gray-300 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4 ${
                  this.state.answer == 2
                    ? "bg-main-violate"
                    : currentAnswer.find((curr) => curr.id == id)
                    ? currentAnswer.find((curr) => curr.id == id).answer == 2
                      ? "bg-main-violate"
                      : ""
                    : ""
                }`}
                type="button"
                onClick={(e) => this.setAnswer(2)}
              ></button>
              <button
                className={`border border-gray-300 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4 ${
                  this.state.answer == 3
                    ? "bg-main-violate"
                    : currentAnswer.find((curr) => curr.id == id)
                    ? currentAnswer.find((curr) => curr.id == id).answer == 3
                      ? "bg-main-violate"
                      : ""
                    : ""
                }`}
                type="button"
                onClick={(e) => this.setAnswer(3)}
              ></button>
              <button
                className={`border border-gray-300 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4 ${
                  this.state.answer == 4
                    ? "bg-main-violate"
                    : currentAnswer.find((curr) => curr.id == id)
                    ? currentAnswer.find((curr) => curr.id == id).answer == 4
                      ? "bg-main-violate"
                      : ""
                    : ""
                }`}
                type="button"
                onClick={(e) => this.setAnswer(4)}
              ></button>
              <button
                className={`border border-gray-300 w-[20px] h-[20px] md:w-[20px] md:h-[20px] rounded-full ml-4 ${
                  this.state.answer == 5
                    ? "bg-main-violate"
                    : currentAnswer.find((curr) => curr.id == id)
                    ? currentAnswer.find((curr) => curr.id == id).answer == 5
                      ? "bg-main-violate"
                      : ""
                    : ""
                }`}
                type="button"
                onClick={(e) => this.setAnswer(5)}
              ></button>
            </div>

            <p className="text-[14px] leading-[140%] ml-4">
              {options.find((option) => option.indicator == "max").value}
            </p>
          </div>
        </div>
      </>
    );
  }
}
