import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setExperiences } from "../../../features/profile/profileSlice";
import Plus from "../../../Assets/Plus.png";
import ContryStateCities from "../../../Component/ContryStateCities";
import {
  getEmploymentStatus,
  getIndustryFields,
  getJobSkills,
} from "../../../helpers";
import BtnSpinner from "../../../Component/BtnSpinner";
import CardWorkExperiences from "../../../Component/CardWorkExperiences/CardWorkExperiences";
import PageSpinner from "../../../Component/PageSpinner";
import { toast } from "react-toastify";
import { BriefcaseIcon } from "@heroicons/react/24/solid";
import TagInput from "../../../Component/TagInput";
import { getWorkExperiences, postWorkExperiences } from "../../../api";

const WorkExperienceSection = ({passed = false, required = false, onSubmitted}) => {
  const { data: experiences, loaded } = useSelector(
    (state) => state.profile.experiences
  );
  const dispatch = useDispatch();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const [validations, setValidations] = useState({});
  const [showModalWT, setShowModalWT] = React.useState(false);
  const [industries, setIndustries] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [jobSkills, setJobSkills] = useState([]);
  const [saving, setSaving] = useState(false);
  // const [experiences, setExperiences] = useState([]);
  const [loadingExp, setLoadingExp] = useState(false);
  const [formExperience, setFormExperience] = useState({
    id: null,
    job_title: "",
    company_name: "",
    country: "",
    zip_code: "",
    province: "",
    city: "",
    industry: "",
    employement_status: "",
    start_date: "",
    end_date: "",
    is_current: 1,
    related_skill: "",
    job_description: "",
  });
  useEffect(() => {
    if (!showModalWT) setValidations({});
  }, [showModalWT]);

  const handleRelatedSkills = (val) => {
    var newFormExperience = { ...formExperience, related_skill: val };
    setFormExperience(newFormExperience);
  };

  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const sanitizedForm = formExperience.related_skill
      .split(",")
      .filter((item) => item !== null && item !== undefined && item !== "");
    formExperience.related_skill = sanitizedForm.join(",");
    const result = await postWorkExperiences(formExperience);
    if (result.success) {
      setFormExperience({
        id: null,
        job_title: "",
        company_name: "",
        country: "",
        zip_code: "",
        province: "",
        city: "",
        industry: "",
        employement_status: "",
        start_date: "",
        end_date: "",
        is_current: 1,
        related_skill: "",
        job_description: "",
      });
      setShowModalWT(false);
      getExperiences();
      onSubmitted();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(result.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setSaving(false);
  };

  // const handleValidation = () => {

  // }
  const minimalDate = () => {
    if (formExperience.start_date) {
      const minDate = new Date(formExperience.start_date);
      const minDateString = minDate.toISOString().split("T")[0];
      return minDateString;
    }
  };

  const maximalDate = () => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() - 1);
    const maxDateString = maxDate.toISOString().split("T")[0];
    return maxDateString;
  };

  const getExperiences = async () => {
    setLoadingExp(true);
    const result = await getWorkExperiences();
    if (result.success) {
      if (result.data != null) {
        dispatch(setExperiences(result.data));
      }
    } else {
      toast.error(e.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoadingExp(false);
  };

  useEffect(() => {
    getEmploymentStatus(user).then((res) => {
      setEmployments(res.data);
    });
    getIndustryFields(user).then((res) => {
      setIndustries(res.data);
    });
    getJobSkills(user).then((res) => {
      const array = res.data.map((item) => item.name);
      setJobSkills(array);
    });

    async function getInformationApi() {
      if (!loaded) {
        await getExperiences();
      }
    }

    getInformationApi();
  }, []);
  return (
    <>
     <div className={`${required ? passed ? 'border-2 border-green-1' : 'border-2 border-reed-1' : 'border-grey-200'} border w-full rounded-md mt-4 bg-white`}>
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <BriefcaseIcon width={24} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Work Experience
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => setShowModalWT(true)}
          >
            <img src={Plus} alt="icon" className="h-[24px] w-[24px]" />
          </button>
          {showModalWT ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate max-w-max lg:w-[680px] lg:max-w-[680px]">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <BriefcaseIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3">
                        Work Experience
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <form
                        className="bg-grey-lightest flex flex-col items-center"
                        name="signup"
                        onSubmit={submit}
                      >
                        <div className="max-h-[60vh] overflow-y-auto w-full max-w-lg">
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Job Title
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-grey-200"
                              type="text"
                              name="jobTitle"
                              id="title"
                              placeholder="Job Title"
                              required
                              value={formExperience.job_title}
                              onChange={(e) =>
                                setFormExperience({
                                  ...formExperience,
                                  job_title: e.target.value,
                                })
                              }
                            />
                            {typeof validations.job_title != "undefined"
                              ? validations.job_title.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Company Name
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-grey-200"
                              type="text"
                              name="companyName"
                              id="institationName"
                              placeholder="Company Name"
                              required
                              value={formExperience.company_name}
                              onChange={(e) =>
                                setFormExperience({
                                  ...formExperience,
                                  company_name: e.target.value,
                                })
                              }
                            />
                            {typeof validations.company_name != "undefined"
                              ? validations.company_name.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <ContryStateCities
                            onCountryChange={(e) =>
                              setFormExperience({
                                ...formExperience,
                                country: e,
                              })
                            }
                            onStateChange={(e) =>
                              setFormExperience({
                                ...formExperience,
                                province: e,
                              })
                            }
                            onCityChange={(e) =>
                              setFormExperience({
                                ...formExperience,
                                city: e,
                              })
                            }
                            onZipCodeChange={(e) =>
                              setFormExperience({
                                ...formExperience,
                                zip_code: e,
                              })
                            }
                            countryValue={formExperience.country}
                            stateValue={formExperience.province}
                            cityValue={formExperience.city}
                            zipCodeValue={formExperience.zip_code}
                          />
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Industry
                            </label>

                            <div className="flex justify-center">
                              <div className="w-full">
                                <select
                                  className="form-select appearance-none
                                                      block
                                                      w-full
                                                      px-3
                                                      py-2
                                                      text-gray-800
                                                      text-[14px]
                                                      bg-white bg-clip-padding bg-no-repeat
                                                      border border-solid border-gray-300
                                                      rounded-md
                                                      transition
                                                      ease-in-out
                                                      m-0 focus:outline-none focus:border-main-violate border border-grey-200"
                                  aria-label="Default select example"
                                  value={formExperience.industry}
                                  onChange={(e) =>
                                    setFormExperience({
                                      ...formExperience,
                                      industry: e.target.value,
                                    })
                                  }
                                >
                                  <option selected disabled value="">
                                    Pilih salah satu
                                  </option>
                                  {industries.map((industry) => {
                                    return (
                                      <option value={industry.id}>
                                        {industry.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {typeof validations.industry != "undefined"
                                  ? validations.industry.map((e) => {
                                      return (
                                        <span className="input-error-message">
                                          {e}
                                        </span>
                                      );
                                    })
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Employment Status
                            </label>

                            <div className="flex justify-center">
                              <div className="w-full">
                                <select
                                  className="form-select appearance-none
                                                      block
                                                      w-full
                                                      px-3
                                                      py-2
                                                      text-gray-800
                                                      text-[14px]
                                                      bg-white bg-clip-padding bg-no-repeat
                                                      border border-solid border-gray-300
                                                      rounded-md
                                                      transition
                                                      ease-in-out
                                                      m-0
                                                      focus:outline-none focus:border-main-violate border border-grey-200"
                                  aria-label="Default select example"
                                  value={formExperience.employement_status}
                                  onChange={(e) =>
                                    setFormExperience({
                                      ...formExperience,
                                      employement_status: e.target.value,
                                    })
                                  }
                                >
                                  <option selected disabled value="">
                                    Pilih salah satu
                                  </option>
                                  {employments.map((employment) => {
                                    return (
                                      <option value={employment.id}>
                                        {employment.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {typeof validations.employement_status !=
                                "undefined"
                                  ? validations.employement_status.map((e) => {
                                      return (
                                        <span className="input-error-message">
                                          {e}
                                        </span>
                                      );
                                    })
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-between flex-col md:flex-row mb-1">
                            <div className="mb-3 md:mb-0">
                              <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Start Date
                              </label>

                              <input
                                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-grey-200"
                                type="date"
                                name="softskill"
                                id="softskill"
                                placeholder="Select Date"
                                required
                                max={maximalDate()}
                                value={formExperience.start_date}
                                onChange={(e) =>
                                  setFormExperience({
                                    ...formExperience,
                                    start_date: e.target.value,
                                  })
                                }
                              />
                              {typeof validations.start_date != "undefined"
                                ? validations.start_date.map((e) => {
                                    return (
                                      <span className="input-error-message">
                                        {e}
                                      </span>
                                    );
                                  })
                                : ""}
                            </div>
                            <div className="">
                              <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                End Date
                              </label>
                              <input
                                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-grey-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
                                type="date"
                                name="softskill"
                                id="softskill"
                                placeholder="Select Date"
                                required
                                min={minimalDate()}
                                max={maximalDate()}
                                disabled={
                                  formExperience.is_current == 1 ? true : false
                                }
                                value={
                                  formExperience.is_current == 1
                                    ? null
                                    : formExperience.end_date
                                }
                                onChange={(e) =>
                                  setFormExperience({
                                    ...formExperience,
                                    end_date: e.target.value,
                                  })
                                }
                              />
                              {typeof validations.is_current != "undefined"
                                ? validations.is_current.map((e) => {
                                    return (
                                      <span className="input-error-message">
                                        {e}
                                      </span>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                          <div className="flex justify-start mb-3">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="1"
                                checked={formExperience.is_current}
                                onChange={(e) =>
                                  setFormExperience({
                                    ...formExperience,
                                    is_current: e.target.checked ? 1 : 0,
                                  })
                                }
                              />
                              <label
                                className="form-check-label inline-block text-grey-darkest text-[12px] leading-[16.8px] mb-px"
                                htmlFor="inlineCheckbox1"
                              >
                                I am currently working in this role
                              </label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Job Related Skill
                            </label>
                            {/* <TagInputWithSuggest
                              tags={handleRelatedSkills}
                              data={jobSkills}
                              currentTags={formExperience.related_skill}
                            /> */}

                            <TagInput
                              currentTags={formExperience.related_skill}
                              tags={handleRelatedSkills}
                              suggestions={jobSkills}
                            />
                            {typeof validations.related_skill != "undefined"
                              ? validations.related_skill.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                            {/* <TagInputWithSuggest /> */}
                          </div>
                          <div className="mb-3">
                            <label className="text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Job Description (Optional)
                            </label>
                            <textarea
                              className="w-full p-3 h-[63px] placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-grey-200"
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder="Describe"
                              value={formExperience.job_description}
                              onChange={(e) =>
                                setFormExperience({
                                  ...formExperience,
                                  job_description: e.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-[16px]"
                            onClick={() => setShowModalWT(false)}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </form>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loadingExp ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {experiences.length > 0 ? (
              <CardWorkExperiences
                experiences={experiences}
                onShowEdit={(id) => {
                  const selectedExperience = experiences.find(
                    (exp) => exp.id == id
                  );
                  const mapping = {
                    ...selectedExperience,
                    industry: selectedExperience.industry.id,
                    employement_status:
                      selectedExperience.employement_status.id,
                    related_skill: selectedExperience.related_skill_desc,
                  };
                  setFormExperience(mapping);
                  setShowModalWT(true);
                }}
              />
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default WorkExperienceSection;
