import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSkills } from "../../../features/profile/profileSlice";
import { toast } from "react-toastify";
import PageSpinner from "../../../Component/PageSpinner";
import BtnSpinner from "../../../Component/BtnSpinner";
import TagInput from "../../../Component/TagInput";
import { PuzzlePieceIcon, PencilIcon } from "@heroicons/react/24/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import { getSkills, postSkills } from "../../../api";
const SkillSection = ({passed = false, required = false, onSubmitted}) => {
  const { data: skill, loaded } = useSelector((state) => state.profile.skills);
  const dispatch = useDispatch();
  const [validations, setValidations] = useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    soft_skills: "",
  });

  const fetchSkills = async () => {
    setLoading(true);
    const result = await getSkills();
    if (result.success) {
      dispatch(setSkills(result.data));
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const sanitizedForm = form.soft_skills
      .split(",")
      .filter((item) => item !== null && item !== undefined && item !== "");
    const result = await postSkills(
      JSON.stringify({ soft_skills: sanitizedForm.join(",") })
    );
    if (result.success) {
      setForm({
        skills: "",
      });
      setShowModal(false);
      fetchSkills();
      onSubmitted();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(result.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setSaving(false);
  };

  useEffect(() => {
    if (!showModal) setValidations({});
  }, [showModal]);

  useEffect(() => {
    async function getInformationApi() {
      if (!loaded) {
        await fetchSkills();
      }
    }
    getInformationApi();
  }, []);

  return (
    <>
      <div className={`${required ? passed ? 'border-2 border-green-1' : 'border-2 border-reed-1' : 'border-grey-200'} border w-full rounded-md mt-4 bg-white`}>
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            {/* <img src={Lightbulb} alt="icon" className="h-[28px] w-[28px]" /> */}
            <PuzzlePieceIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Skills & Specializations
            </h4>
          </div>
          <button
            className="bg-white"
            onClick={() => {
              if (skill) {
                setForm({
                  soft_skills: skill.soft_skills,
                });
              }
              setShowModal(true);
            }}
          >
            {skill ? <PencilIcon width={22} /> : <PlusIcon width={24} />}
          </button>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0">
                  {/*content*/}
                  <div className="bg-white rounded-[8px] shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <PuzzlePieceIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3">
                        Skills & Specializations
                      </h4>
                    </div>
                    <form
                      className="bg-grey-lightest flex flex-col items-center p-6"
                      name="skills"
                      onSubmit={(e) => submit(e)}
                    >
                      <div className="max-w-lg w-full px-2">
                        <div className="mb-3">
                          <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                            Skills
                          </label>
                          <TagInput
                            currentTags={form.soft_skills}
                            tags={(val) => {
                              setForm({ ...form, soft_skills: val });
                            }}
                          />
                          {typeof validations.soft_skills != "undefined"
                            ? validations.soft_skills.map((e) => {
                                return (
                                  <span className="input-error-message">
                                    {e}
                                  </span>
                                );
                              })
                            : ""}
                        </div>
                        {/* <div className="mb-3">
                          <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                            Hard Skill
                          </label>
                          <TagInput
                            currentTags={form.hard_skills}
                            tags={(val) => {
                              setForm({ ...form, hard_skills: val });
                            }}
                          />
                          {typeof validations.hard_skills != "undefined" ? validations.hard_skills.map((e) => {
                            return (<span className="input-error-message">{e}</span>)
                          }) : ""}
                        </div> */}
                      </div>
                      <div className="flex mt-5 justify-center ml-[-8px]">
                        <button
                          className="bg-main-violate  w-[320px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
                          type="submit"
                          disabled={saving}
                        >
                          {saving ? <BtnSpinner /> : "Save"}
                        </button>
                      </div>
                      <div className="flex justify-center ml-[-8px]">
                        <button
                          className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                          onClick={() => setShowModal(false)}
                        >
                          Back to Profile
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>

        {loading ? (
          <PageSpinner padding="10px 0px" />
        ) : skill != null ? (
          <>
            <div className="mt-5 mb-3 px-5">
              <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                Soft Skill
              </label>
              <div className="mt-4">
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border-inherit"
                  type="text"
                  name="phone"
                  id="phone"
                  value={skill.soft_skills}
                  placeholder="Soft Skill"
                  disabled
                />
              </div>
            </div>
            {/* <div className="mt-5 mb-3 px-5">
              <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                Hard Skill
              </label>
              <div className="mt-4">
                <input
                  className="border w-full p-3 h-10 placeholder:text-sm rounded-lg border-inherit"
                  type="text"
                  name="phone"
                  id="phone"
                  value={skill.hard_skills}
                  placeholder="Hard Skill"
                  disabled
                />
              </div>
            </div> */}
          </>
        ) : (
          <div className="mt-5 mb-3 px-5">
            <p className="font-semibold text-slate-400 text-center">
              <i>No Data</i>{" "}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SkillSection;
