import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../Component/FooterDashboard";
import { documentTitle } from "../../helpers";
import CertificationAndTrainingSection from "./Sections/CertificationAndTrainingSection";
import AchievementSection from "./Sections/AchievementSection";
import WorkExperienceSection from "./Sections/WorkExperienceSection";
import EducationalSection from "./Sections/EducationalSection";
import SkillSection from "./Sections/SkillSection";
import AboutSection from "./Sections/AboutSection";
import LanguageSection from "./Sections/LanguageSection";
import ResumeSection from "./Sections/ResumeSection";
import SocialNetworkSection from "./Sections/SocialNetworkSection";
import PersonalSection from "./Sections/PersonalSection";
import { getMyPersonal } from "../../api";
import { setAccount, setPersonal } from "../../features/profile/profileSlice";
import { useTour } from "@reactour/tour";
import PreferenceSection from "./Sections/PreferenceSection";
const DashboardProfilePage = () => {
  let navigate = useNavigate();

  const { setIsOpen, isOpen } = useTour();
  // const profileData = useSelector((state) => state.profile.profileData);
  const dispatch = useDispatch();
  const { data: personal, loaded: personalLoaded } = useSelector(
    (state) => state.profile.personal
  );
  const { data: account, loaded: accountLoaded } = useSelector(
    (state) => state.profile.account
  );
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const [loading, setLoading] = useState(true);
  const [resumeRunning] = useState(false);

  // check if logged in /test
  useEffect(() => {
    documentTitle("Profile");
    if (!user) {
      navigate("/login", { replace: true });
    }

    if (!personalLoaded) {
      myPersonal();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      if ("onboarding_tour" in localStorage) {
        const onboarding = JSON.parse(localStorage.getItem("onboarding_tour"));
        if (!onboarding.profile) {
          setIsOpen(true);
          // Update the local storage
          localStorage.setItem(
            "onboarding_tour",
            JSON.stringify({ ...onboarding, profile: true })
          );
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    if (isOpen) {
      const onboarding = JSON.parse(localStorage.getItem("onboarding_tour"));
      localStorage.setItem(
        "onboarding_tour",
        JSON.stringify({ ...onboarding, profile: true })
      );
    }
  }, [isOpen]);

  const myPersonal = async () => {
    setLoading(true);
    const result = await getMyPersonal();
    if (result.success) {
      if (result.data != null) {
        dispatch(setPersonal(result.data.personal));
        dispatch(setAccount(result.data.account));
      }
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(false);
  };

  const myStyle = {
    background: `#FBF3FF`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
  };

  return (
    <>
      <div
        style={myStyle}
        className="px-[10px] py-[20px] md:px-[44px] md:py-[24px]"
      >
        <ToastContainer />
        <div className="mx-auto max-w-[1440px]">
          <h2 className="text-lg md:text-xl font-semibold leading-[150%] mb-6">
            Profile
          </h2>
          <div className="justify-center md:px-2 md:px-0">
            <PersonalSection
              personalLoading={loading}
              personal={personal}
              account={account}
              onUpdate={() => myPersonal()}
            />
            <AboutSection loading={loading} about={personal.about} />
            <ResumeSection running={resumeRunning} />
            <div className="additional-section">
              <PreferenceSection />
              <SocialNetworkSection />
              <SkillSection />
              <WorkExperienceSection />
              <CertificationAndTrainingSection />
              <LanguageSection />
              <EducationalSection />
              <AchievementSection />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default DashboardProfilePage;
