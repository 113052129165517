import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setEducationals } from "../../../features/profile/profileSlice";
import Plus from "../../../Assets/Plus.png";
import ContryStateCities from "../../../Component/ContryStateCities";
import TagInputWithSuggest from "../../../Component/TagInputWithSuggest/TagInputWithSuggest";
import {
  getEducationDegrees,
  getEducationMajors,
  getEducationSkills,
  inputMaxDate,
} from "../../../helpers";
import BtnSpinner from "../../../Component/BtnSpinner";
import PageSpinner from "../../../Component/PageSpinner";
import { toast } from "react-toastify";
import CardEducational from "../../../Component/CardEducational/CardEducational";
import { AcademicCapIcon } from "@heroicons/react/24/solid";
import { getExperiences, postExperiences } from "../../../api";
const EducationalSection = ({passed = false, required = false, onSubmitted}) => {
  const { data: educationals, loaded } = useSelector(
    (state) => state.profile.educationals
  );
  const dispatch = useDispatch();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const [validations, setValidations] = useState({});
  const [showModalWT, setShowModalWT] = React.useState(false);
  const [majorList, setMajorList] = useState([]);
  const [degreeList, setDegreeList] = useState([]);
  const [skillList, setSkills] = useState([]);
  const [saving, setSaving] = useState(false);
  // const [educationals, setEducationals] = useState([]);
  const [loadingExp, setLoadingExp] = useState(false);
  const [form, setForm] = useState({
    id: null,
    user_id: "",
    educational_degree_id: null,
    educational_major_id: null,
    institution: "",
    country: "",
    zip_code: "",
    province: "",
    city: "",
    start_date: "",
    end_date: "",
    gpa: "",
    educational_skills: "",
    educational_description: "",
  });
  const handleSkills = (val) => {
    var newForm = { ...form, educational_skills: val };
    setForm(newForm);
  };
  const submit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const result = await postExperiences(form);
    if (result.success) {
      setForm({
        id: null,
        user_id: "",
        educational_degree_id: null,
        educational_major_id: null,
        institution: "",
        country: "",
        zip_code: "",
        province: "",
        city: "",
        start_date: "",
        end_date: "",
        gpa: "",
        educational_skills: "",
        educational_description: "",
      });
      setShowModalWT(false);
      fetchExperiences();
      onSubmitted();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    } else {
      if (result.status_code === 422) {
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setValidations(result.message);
      } else {
        toast.error(response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setSaving(false);
  };
  const fetchExperiences = async () => {
    setLoadingExp(true);
    const result = await getExperiences();
    if (result.success) {
      if (result.data != null) {
        dispatch(setEducationals(result.data));
      }
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoadingExp(false);
  };

  useEffect(() => {
    if (!showModalWT) setValidations({});
  }, [showModalWT]);

  useEffect(() => {
    getEducationDegrees(user).then((res) => {
      setDegreeList(res.data);
    });
    getEducationMajors(user).then((res) => {
      setMajorList(res.data);
    });
    getEducationSkills(user).then((res) => {
      setSkills(res.data);
    });

    async function getInformationApi() {
      if (!loaded) await fetchExperiences();
    }

    getInformationApi();
  }, []);
  return (
    <>
      <div className={`${required ? passed ? 'border-2 border-green-1' : 'border-2 border-reed-1' : 'border-grey-200'} border w-full rounded-md mt-4 bg-white`}>
        <div className="border-b border-grey-200 px-6 py-5 flex justify-between">
          <div className="flex justify-start">
            <AcademicCapIcon width={28} className="text-orange-500" />
            <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3 mt-1">
              Educational History
            </h4>
          </div>
          <button
            className="bg-white"
            type="submit"
            onClick={() => setShowModalWT(true)}
          >
            <img src={Plus} alt="icon" className="h-[24px] w-[24px]" />
          </button>
          {showModalWT ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate">
                <div className="relative w-auto mx-auto max-w-3xl w-full px-2 md:px-0 max-w-lg">
                  {/*content*/}
                  <div className="bg-white rounded-lg shadow w-full outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate max-w-max lg:w-[680px] lg:max-w-[680px]">
                    {/*header*/}
                    <div className="border-b border-grey-200 px-6 py-5 flex justify-start">
                      <AcademicCapIcon width={28} className="text-orange-500" />
                      <h4 className="text-[16px] font-semibold text-black leading-[140%] ml-3">
                        Educational History
                      </h4>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <form
                        className="bg-grey-lightest flex flex-col items-center"
                        name="work_experience"
                        onSubmit={submit}
                      >
                        <div className="max-h-[60vh] overflow-y-auto w-full max-w-lg">
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Educational Degree
                            </label>
                            <div className="flex justify-center">
                              <div className="w-full">
                                <select
                                  className="form-select appearance-none
                                                        block
                                                        w-full
                                                        px-3
                                                        py-2
                                                        text-soft-grey
                                                        text-[14px]
                                                        bg-white bg-clip-padding bg-no-repeat
                                                        border border-solid border-gray-300
                                                        rounded-md
                                                        transition
                                                        ease-in-out
                                                        m-0
                                                        focus:outline-none focus:border-main-violate border border-line-grey"
                                  aria-label="Default select example"
                                  value={form.educational_degree_id}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      educational_degree_id: e.target.value,
                                    })
                                  }
                                >
                                  <option selected disabled value="">
                                    Pilih salah satu
                                  </option>
                                  {degreeList.map((degree) => {
                                    return (
                                      <option value={degree.id}>
                                        {degree.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {typeof validations.educational_degree_id !=
                                "undefined"
                                  ? validations.educational_degree_id.map(
                                      (e) => {
                                        return (
                                          <span className="input-error-message">
                                            {e}
                                          </span>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Educational Major
                            </label>
                            <div className="flex justify-center">
                              <div className="w-full">
                                <select
                                  className="form-select appearance-none
                                                        block
                                                        w-full
                                                        px-3
                                                        py-2
                                                        text-soft-grey
                                                        text-[14px]
                                                        bg-white bg-clip-padding bg-no-repeat
                                                        border border-solid border-gray-300
                                                        rounded-md
                                                        transition
                                                        ease-in-out
                                                        m-0
                                                        focus:outline-none focus:border-main-violate border border-line-grey"
                                  aria-label="Default select example"
                                  value={form.educational_major_id}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      educational_major_id: e.target.value,
                                    })
                                  }
                                >
                                  <option selected disabled value="">
                                    Pilih salah satu
                                  </option>
                                  {majorList.map((major) => {
                                    return (
                                      <option value={major.id}>
                                        {major.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {typeof validations.educational_major_id !=
                                "undefined"
                                  ? validations.educational_major_id.map(
                                      (e) => {
                                        return (
                                          <span className="input-error-message">
                                            {e}
                                          </span>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Institution Name
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="jobTitle"
                              id="title"
                              placeholder="Instituion"
                              required
                              value={form.institution}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  institution: e.target.value,
                                })
                              }
                            />
                            {typeof validations.institution != "undefined"
                              ? validations.institution.map((e) => {
                                  return (
                                    <span className="input-error-message">
                                      {e}
                                    </span>
                                  );
                                })
                              : ""}
                          </div>
                          <ContryStateCities
                            onCountryChange={(e) =>
                              setForm({
                                ...form,
                                country: e,
                              })
                            }
                            onStateChange={(e) =>
                              setForm({
                                ...form,
                                province: e,
                              })
                            }
                            onCityChange={(e) =>
                              setForm({
                                ...form,
                                city: e,
                              })
                            }
                            onZipCodeChange={(e) =>
                              setForm({
                                ...form,
                                zip_code: e,
                              })
                            }
                            countryValue={form.country}
                            stateValue={form.province}
                            cityValue={form.city}
                            zipCodeValue={form.zip_code}
                          />
                          <div className="flex justify-between flex-col md:flex-row mb-1">
                            <div className="mb-3 md:mb-0">
                              <label className="text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                Start Date
                              </label>
                              <input
                                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                type="date"
                                name="softskill"
                                id="softskill"
                                placeholder="Select Date"
                                required
                                max={inputMaxDate(-1)}
                                value={form.start_date}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    start_date: e.target.value,
                                  })
                                }
                              />
                              {typeof validations.start_date != "undefined"
                                ? validations.start_date.map((e) => {
                                    return (
                                      <span className="input-error-message">
                                        {e}
                                      </span>
                                    );
                                  })
                                : ""}
                            </div>
                            <div className="mb-3 md:mb-0">
                              <label className="text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                                End Date (or Expected)
                              </label>
                              <input
                                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                                type="date"
                                name="softskill"
                                id="softskill"
                                placeholder="Select Date"
                                max={inputMaxDate(-1)}
                                value={form.end_date}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    end_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              GPA (Optional)
                            </label>
                            <input
                              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="gpa"
                              id="title"
                              placeholder="Your GPA"
                              value={form.gpa}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  gpa: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Education Skill
                            </label>
                            <TagInputWithSuggest
                              tags={handleSkills}
                              data={skillList}
                              currentTags={form.educational_skills}
                            />
                            {/* <TagInputWithSuggest /> */}
                          </div>
                          <div className="mb-3">
                            <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                              Education Description (Optional)
                            </label>
                            <textarea
                              className="border w-full p-3 h-[63px] placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder="Describe"
                              value={form.educational_description}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  educational_description: e.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                        </div>
                        <div className="flex mt-5 justify-center ml-[-8px]">
                          <button
                            className="bg-main-violate  w-[120px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                            type="submit"
                            disabled={saving}
                          >
                            {saving ? <BtnSpinner /> : "Save"}
                          </button>
                        </div>
                        <div className="flex justify-center ml-[-8px]">
                          <button
                            className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                            onClick={() => setShowModalWT(false)}
                          >
                            Back to Profile
                          </button>
                        </div>
                      </form>
                    </div>
                    {/*footer*/}
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
        {loadingExp ? (
          <PageSpinner padding="10px 0px" />
        ) : (
          <div className="mt-5 mb-3 px-5">
            {educationals.length > 0 ? (
              <CardEducational
                experiences={educationals}
                onShowEdit={(id) => {
                  const selectedEducation = educationals.find(
                    (exp) => exp.id == id
                  );
                  const mapping = {
                    ...selectedEducation,
                    educational_skills:
                      selectedEducation.educational_skills_desc,
                    educational_degree_id:
                      selectedEducation.educational_degree.id,
                    educational_major_id:
                      selectedEducation.educational_major.id,
                  };
                  setForm(mapping);
                  setShowModalWT(true);
                }}
              />
            ) : (
              <p className="font-semibold text-slate-400 text-center">
                <i>No Data</i>{" "}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EducationalSection;
