import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ProfilePage = () => {

  let navigate = useNavigate();

  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const [userFullname] = useState(() => {
    if (user) {
      return user.useraccount_fullname || "";
    }
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  return (
    <div className="container">
      <h1>Profile Page</h1>
      <h3>Welcome, {userFullname}!</h3>
      <button className="bg-main-violate  w-[100px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg" type="submit">
        <Link to="/logout">Logout</Link>
      </button>
    </div>
  )


}

export default ProfilePage;