import React, { useState } from "react";

const DropdownMultipleChoice = ({ options, selectedOptions, onChange, disabled = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        const isSelected = selectedOptions.includes(option);

        if (isSelected) {
            // Remove the option if it's already selected
            onChange(selectedOptions.filter((selectedOption) => selectedOption !== option));
        } else {
            // Add the option if it's not selected
            onChange([...selectedOptions, option]);
        }
    };

    return (
        <div className="relative">
            <button
                className="block w-full px-2 py-3 text-left text-[14px] border border-line-grey rounded-lg focus:outline-none focus:border-main-violate disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-grey-500"
                disabled={disabled}
                onClick={handleToggle}
            >
                {selectedOptions.length === 0
                    ? "Please select"
                    : selectedOptions.join(", ")}
            </button>

            {isOpen && (
                <div className="absolute top-full w-full bg-white rounded-lg shadow">
                    {options.map((option) => (
                        <label
                            key={option.name}
                            className="block px-4 py-2 cursor-pointer last:border-b-0 flex items-center"
                        >
                            <input
                                type="checkbox"
                                className="mr-2 leading-tight rounded border border-gray-300 bg-gray-50 p-2 focus:ring-0 checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 cursor-pointer"
                                checked={selectedOptions.includes(option.name)}
                                onChange={() => handleOptionClick(option.name)}
                            />
                            <div>
                                <p className="text-sm">{option.name}</p>
                                <small className="text-xs">{option.caption}</small>
                            </div>

                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownMultipleChoice;
