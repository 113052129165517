import { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/HumataLogo.png";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import config from "../config";
const Layout = () => {
  const [user, setUser] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const location = useLocation();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (window.location.hostname != config.APP_URL) {
  //     window.location.replace(config.APP_URL + location.pathname);
  //   }
  // }, []);
  useEffect(() => {
    if (user) {
      if (location.pathname == "/login") {
        navigate("/dashboard", { replace: true });
      }
    }
  }, [location]);
  return (
    <>
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>    
          </li>      
          <li>
            <Link to={ userData === null ? "/login" : "profile" }>{ userData === null ? "Log In" : "Profile" }</Link>    
          </li>    
          <li>
            <Link to="/signup">Sign Up</Link>
          </li>
        </ul>
      </nav> */}
      {/* <nav class="bg-white shadow-lg w-[100%]">
          <div class="flex justify-start px-2 h-[80px]">
            <a href="#" class="flex items-center py-3 px-2">
              <img src={logo} alt="Logo" class="h-[80px] w-[80px] mr-2 mb-3"/>
            </a>
          </div>
		  </nav> */}
      {/* <Navbar fluid={true} rounded={true} style={{ padding: "0px 10px" }}> */}
      {/* <Navbar.Brand href="https://highr.id/"> */}
      {/* <img
            src={logo}
            className="mr-3 h-[70px] w-[70px] "
            alt="Flowbite Logo"
          /> */}
      {/* <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            Flowbite  
          </span> */}
      {/* </Navbar.Brand> */}
      {/* <Navbar.Collapse>
          <Navbar.Link href="/navbars" active={true}>
            Home
          </Navbar.Link>
          <Navbar.Link href="/navbars">About</Navbar.Link>
          <Navbar.Link href="/navbars">Services</Navbar.Link>
          <Navbar.Link href="/navbars">Pricing</Navbar.Link>
          <Navbar.Link href="/navbars">Contact</Navbar.Link>
        </Navbar.Collapse> */}
      {/* </Navbar> */}
      <Outlet />
    </>
  );
};

export default Layout;
