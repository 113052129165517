import {
  useNavigate,
  useParams,
} from "react-router-dom";
import IconCv from "../../Assets/IconCv.png";
import React, { useEffect, useState } from "react";
import Navbar from "../../Component/NavbarDashboard";
import { ToastContainer, toast } from "react-toastify";
import PageSpinner from "../../Component/PageSpinner";
import axios from "axios";
import BtnSpinner from "../../Component/BtnSpinner";

const Accessibility = () => {
  const { company_slug, test_id } = useParams();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const [getting, setGetting] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [showAdditional, setShowAdditional] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const myStyle = {
    height: "177vh",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  let navigate = useNavigate();
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const getSetup = async () => {
    await axios
      .get(`/api/assessment/company/${company_slug}/setup`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        setGetting(false);
        const result = response.data;
        if (result.success) {
          setQuestions(result.data);
          return result;
        }
      })
      .catch(() => {
        setGetting(false);
        return false;
      });
  };

  const upload = (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios
      .post(`/api/assessment/company/${company_slug}/setup/docs-upload`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        setUploading(false);
        if (result.success) {
            setFile(result.data);
            toast.success(result.message, {
              toastId: "login-success-toast",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1250,
            });
        }
      })
      .catch((e) => {
        setUploading(false);
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const clearFile = () => {
    setFile(null);
  };
  const handleContinue = () => {
    if(!showAdditional){
        setShowAdditional(true);
    }else{
        navigate(`/assessment/${company_slug}/test/${test_id}/setup/thank-you`)
    }
  };

  useEffect(() => {
    getSetup();
  }, []);

  return (
    <>
      <Navbar />
      <div style={myStyle}>
        <div className="container mx-auto pt-12">
          <ToastContainer />
          <div className="mx-auto max-w-[980px] px-2 md:px-0">
            <div className="bg-white rounded-lg shadow w-full mb-[40px]">
              <div className="rounded-t-lg">
                <div className="px-6 py-5 ">
                  {getting ? (
                    <PageSpinner />
                  ) : (
                    <>
                      <div>
                        <h2 className="font-bold text-xl mb-2">
                          Assessment Setup
                        </h2>
                        <p className="text-slate-600">
                          To make sure that everyone is fairly treated, we might
                          need to adjust your assessment setup. You can help us
                          do that by answering the following questions:
                        </p>
                        {questions.map((value, index) => {
                          return (
                            <div className="mt-4">
                              <div className="w-[100%]  text-[16px] leading-[140%] flex no-select">
                                <div className="mr-2">{value.order}. </div>
                                <div>
                                  {value.question
                                    .split("\\n")
                                    .map((line, index) => {
                                      return <p key={index}>{line}</p>;
                                    })}
                                  <div className="radio-button mt-3">
                                    {value.options.map((option) => {
                                      return (
                                        <div className="flex items-center mb-3 no-select">
                                          <input
                                            id={`purple-radio-${option.id}`}
                                            type="radio"
                                            name={`question-${index}`}
                                            value={option.id}
                                            onChange={(e) =>
                                              handleSetAnswer(e.target.value)
                                            }
                                            className="w-4 h-4 text-main-violate bg-gray-100 border-gray-300 focus:ring-main-violate dark:focus:ring-main-violate dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                          />
                                          <label
                                            htmlFor="purple-radio1"
                                            className="ml-2 text-[16px] leading-[140%] "
                                          >
                                            {option.value}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className={showAdditional ? `mt-4` : `mt-4 hidden`}>
                          <div className="w-[100%]  text-[16px] leading-[140%] no-select">
                            <div>
                              <p>
                                Please clarify your condition and tell us what
                                accomodation you require. Our Team will validate
                                your request and configure your assessment
                                within 24 business hours.
                              </p>
                              <div className="mt-3">
                                <textarea
                                  className="border w-full p-3 h-[100px] placeholder:text-sm rounded-lg focus:ring-2 focus:outline-none focus:ring-main-violate border border-line-grey"
                                  type="text"
                                  name="answerr"
                                  id="answerr"
                                  placeholder="Please clarify..."
                                  //   onChange={(e) =>
                                  //     this.setAnswer(e.target.value)
                                  //   }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={showAdditional ? `mt-4` : `mt-4 hidden`}>
                          <div className="w-[100%]  text-[16px] leading-[140%] no-select">
                            <div>
                              <p>
                                Please add your document to support your
                                statement (optional).
                              </p>
                              <div className="mb-5 mt-3">
                                {file ? (
                                  <div className="relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                                    <span className="block text-soft-grey text-[14px]">
                                      {file.replace(/^.*[\\\/]/, "")}
                                    </span>
                                    <button onClick={clearFile}>
                                      <svg
                                        className="h-5 w-5 text-gray-500"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        {" "}
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="4" y1="7" x2="20" y2="7" />{" "}
                                        <line x1="10" y1="11" x2="10" y2="17" />{" "}
                                        <line x1="14" y1="11" x2="14" y2="17" />{" "}
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                      </svg>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="relative h-[80px] rounded-lg border bg-white flex justify-center items-center hover:outline-none ">
                                    <div className="absolute">
                                      <div className="flex flex-col items-center ">
                                        {uploading ? (
                                          <>
                                            <BtnSpinner /> Uploading
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={IconCv}
                                              alt="icon"
                                              className="h-[24px] w-[24px]"
                                            />
                                            <span className="block text-soft-grey text-[14px] leading-[140%] mt-3">
                                              Upload or Drag your Document here
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <input
                                      type="file"
                                      className="h-full w-full opacity-0 cursor-pointer"
                                      name="uploadcv"
                                      onChange={upload}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <button
                            className="bg-main-violate  w-[160px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg ml-3 mt-4 hover:bg-violet-600"
                            type="submit"
                            onClick={() => handleContinue()}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accessibility;
