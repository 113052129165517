import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AcademicCapIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  CubeIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import moment from "moment";
import PageSpinner from "../../Component/PageSpinner";
import styles from "./dashboard.module.css";
import { documentTitle } from "../../helpers";
import {
  getAssessments,
  getDashboardSummaries,
  postUploadImpairement,
} from "../../api";
import { useTour } from "@reactour/tour";
const DashboardPage = () => {
  let navigate = useNavigate();
  const { setIsOpen, isOpen } = useTour();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  const impairementDocRef = useRef(null);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [impairementFileUpload, setImpairementFileUpload] = useState(null);
  const [impairementUploading, setImpairementUploading] = useState(false);
  const [assessmentLoading, setAssessmentLoading] = useState(true);
  const [summaries, setSummaries] = useState({});
  const [assessmentSummaries, setAssessmentSummaries] = useState({});
  const [assessments, setAssessments] = useState([]);
  const [assessmentFinishedPercentage, setAssessmentFinishedPercentage] =
    useState(0);
  const [personalTab, setPersonalTab] = useState({
    sc: true,
    pi: true,
  });

  useEffect(() => {
    if (!assessmentLoading && !summaryLoading) {
      if ("onboarding_tour" in localStorage) {
        const onboarding = JSON.parse(localStorage.getItem("onboarding_tour"));
        if (!onboarding.dashboard) {
          setIsOpen(true);
        }
      }
    }
  }, [assessmentLoading, summaryLoading]);

  useEffect(() => {
    if (isOpen) {
      const onboarding = JSON.parse(localStorage.getItem("onboarding_tour"));
      localStorage.setItem(
        "onboarding_tour",
        JSON.stringify({ ...onboarding, dashboard: true })
      );
    }
  }, [isOpen]);
  const getSummaries = async () => {
    setSummaryLoading(true);
    const result = await getDashboardSummaries();
    if (result.success) {
      setSummaries(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setSummaryLoading(false);
  };

  const handleAssessments = (summary, assessments) => {
    const mappingAssessments = assessments.map((assessment) => {
      let total_times = 0;
      assessment.tests.map((test) => {
        total_times += parseInt(test.times);
      });
      assessment.total_times = total_times;
      return assessment;
    });
    setAssessmentFinishedPercentage(
      (summary.finished_assessment / summary.total_assessment) * 100
    );
    setAssessmentSummaries(summary);
    setAssessments(mappingAssessments);
  };

  const fetchAssessments = async () => {
    setAssessmentLoading(true);
    const result = await getAssessments();
    if (result.success) {
      handleAssessments(result.data.summary, result.data.assessments);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setAssessmentLoading(false);
  };

  const uploadImpairement = async (e) => {
    setImpairementUploading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const result = await postUploadImpairement(formData);
    if (result.success) {
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setImpairementFileUpload(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setImpairementUploading(false);
  };

  const startAssessment = (assessment_id = null) => {
    navigate("/assessment" + (assessment_id ? `?focus=${assessment_id}` : ""));
  };

  const HeroSection = () => {
    let backgroundClass = "default";
    if (summaries.finished_tests >= summaries.total_tests) {
      backgroundClass = "finished";
    } else if (summaries.finished_tests > 0) {
      backgroundClass = "ongoing";
    }
    return (
      <>
        <div
          className={`${backgroundClass + " dashboard-hero rounded-lg mb-8"}`}
        >
          <div className="px-10 py-10">
            <h2 className="text-white md:text-[30px] font-bold leading-[150%] mb-3 text-lg">
              Welcome!
            </h2>
            <p className="text-white text-sm md:text-[16px] font-normal leading-[140%] mb-[2px]">
              Welcome to Highr ID Candidate Assessment Dashboard! <br />
              Complete your profile first to begin your journey towards personal
              and professional development now!
            </p>
          </div>
        </div>
      </>
    );
  };
  useEffect(() => {
    documentTitle("Dashboard");
    getSummaries();
    fetchAssessments();
    // setIsOpen(true)
  }, []);

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  // personal tab cache
  useEffect(() => {
    if ("dashboard.personalTab" in localStorage) {
      const personalTabCache = JSON.parse(
        localStorage.getItem("dashboard.personalTab")
      );
      setPersonalTab(personalTabCache);
    }
  }, []);
  // personal tab changing
  useEffect(() => {
    localStorage.setItem("dashboard.personalTab", JSON.stringify(personalTab));
  }, [personalTab.pi, personalTab.sc]);

  const myStyle = {
    background: `#FBF3FF`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
  };

  return (
    <div
      style={myStyle}
      className="px-[10px] py-[20px] md:px-[44px] md:py-[24px]"
    >
      <ToastContainer />
      <h2 className="text-lg md:text-xl font-semibold leading-[150%] mb-6">
        Dashboard
      </h2>
      <HeroSection />
      <div className="summary-stats grid-cols-1 lg:grid-cols-3 grid gap-4 mb-6">
        <div className="summary-test-tobe-done card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div
            className={
              styles["total-test"] +
              " h-[86px] w-[86px] mr-6 rounded-[12px] flex items-center justify-center "
            }
          >
            <ClipboardDocumentListIcon width={32} color={"#F5F5F5"} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Total tests to be done</p>
            <div className="flex">
              {summaryLoading ? (
                <PageSpinner size={"6"} />
              ) : (
                <>
                  <p className="text-xl mr-1">{summaries.unfinished_tests}</p>
                  <small className="relative top-[12px]">
                    from {summaries.total_tests} tests
                  </small>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="summary-test-finished card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div
            className={
              styles["finished-test"] +
              " h-[86px] w-[86px] mr-6 rounded-[12px] flex items-center justify-center "
            }
          >
            <CubeIcon width={32} color={"#F5F5F5"} />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Total finished tests</p>
            <div className="flex">
              {summaryLoading ? (
                <PageSpinner size={"6"} />
              ) : (
                <>
                  <p className="text-xl mr-1">{summaries.finished_tests}</p>
                  <small className="relative top-[12px]">
                    from {summaries.total_tests} tests
                  </small>
                </>
              )}
            </div>

            {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
          </div>
        </div>
        <div className="summary-profile-completed card flex w-full bg-white px-6 py-6 rounded-[12px]">
          <div
            className={
              styles["profile-percentage"] +
              " h-[86px] w-[86px] mr-6 rounded-[12px] flex items-center justify-center "
            }
          >
            <AcademicCapIcon width={32} color={"#F5F5F5"} />
          </div>
          {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
          <div className="flex flex-col justify-center">
            <p className="text-grey-600 mb-1">Profile</p>
            <div className="flex">
              {summaryLoading ? (
                <PageSpinner size={"6"} />
              ) : (
                <>
                  <p className="text-xl mr-1">{summaries.profile_completed}%</p>
                  <small className="relative top-[12px]">completed</small>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="summary-assessment-list bg-white px-6 py-6 rounded-[12px] mb-6">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full">
            <div className="flex flex-col w-full">
              <p className="text-lg md:text-xl text-gray-900 font-bold mb-2">
                Assessment
              </p>
              {assessmentLoading ? (
                <PageSpinner />
              ) : (
                <>
                  <p className="text-sm md:text-base text-grey-600 mb-2">
                    Finish your assessment before{" "}
                    <b>
                      {moment(assessmentSummaries.deadline).format(
                        "D MMMM YYYY"
                      )}
                    </b>
                  </p>
                  <div className="w-full flex flex-col mb-6">
                    <small className="text-gray-500 text-end">
                      {assessmentFinishedPercentage.toFixed(0)}%
                    </small>
                    <div className="w-full h-[7px] bg-white-1 rounded-full mt-2">
                      <div
                        className={`${
                          (assessmentFinishedPercentage == 100
                            ? "bg-green-400"
                            : "bg-orange-secondary-400") +
                          " h-full text-center text-xs text-white rounded-full"
                        }`}
                        style={{
                          width: `${assessmentFinishedPercentage}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="test-container flex overflow-x-auto flex-nowrap w-[100%]">
                    {assessmentSummaries.is_expired && (
                      <div className=" flex flex-col">
                        <p className="text-red-500 font-semibold">
                          This Job has expired
                        </p>
                        <p className="text-gray-500">
                          You can no longer take the assessment for this job
                        </p>
                      </div>
                    )}
                    {!assessmentSummaries.is_expired && (
                      <>
                        {assessments.map((assessment) => {
                          if (assessment.status == "finished") {
                            return (
                              <div className="card-assessment finished rounded w-[360px] mr-5 flex-shrink-0">
                                <div className="title font-bold  h-[48px] bg-gradient-to-br from-green-700 to-green-400 flex items-center px-4 mb-">
                                  <p className="text-white">
                                    {assessment.name}
                                  </p>
                                </div>
                                <div className="content px-4 py-4">
                                  <p className="bg-green-100 text-green-800 font-medium px-[10px] py-1 rounded rounded-[6px] mb-4">
                                    Finished
                                  </p>
                                  <span className="flex mb-4">
                                    <ClipboardDocumentListIcon
                                      width={16}
                                      className="mr-2"
                                    />{" "}
                                    <p>
                                      {assessment.finished_test}/
                                      {assessment.total_test} Test Done
                                    </p>
                                  </span>
                                  <span className="flex mb-4">
                                    <ClockIcon width={16} className="mr-2" />{" "}
                                    <p>
                                      {moment
                                        .duration(
                                          assessment.total_times,
                                          "seconds"
                                        )
                                        .humanize()}
                                    </p>
                                  </span>

                                  <button
                                    className="mb-1 bg-main-violate-50 w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate hover:text-main-violate-50 disabled:bg-green-50 disabled:cursor-not-allowed disabled:text-green-600"
                                    type=""
                                    disabled={true}
                                  >
                                    Completed
                                  </button>
                                </div>
                              </div>
                            );
                          } else if (assessment.status == "ongoing") {
                            return (
                              <div className="card-assessment ongoning rounded w-[360px] mr-5 flex-shrink-0">
                                <div className="header font-bold  h-[48px] flex items-center px-4 mb-">
                                  <p className="text-white">
                                    {assessment.name}
                                  </p>
                                </div>
                                <div className="content px-4 py-4">
                                  <p className="bg-orange-100 text-orange-800 font-medium px-[10px] py-1 rounded rounded-[6px] mb-4">
                                    In Progress
                                  </p>
                                  <span className="flex mb-4">
                                    <ClipboardDocumentListIcon
                                      width={16}
                                      className="mr-2"
                                    />{" "}
                                    <p>
                                      {assessment.finished_test}/
                                      {assessment.total_test} Test Done
                                    </p>
                                  </span>
                                  <span className="flex mb-4">
                                    <ClockIcon width={16} className="mr-2" />{" "}
                                    <p>
                                      {moment
                                        .duration(
                                          assessment.total_times,
                                          "seconds"
                                        )
                                        .humanize()}
                                    </p>
                                  </span>

                                  <button
                                    className="mb-1 bg-orange-100 text-orange-600 w-full px-4 text-[16px] leading-[22.4px] font-semibold h-[46px] rounded-lg hover:bg-orange-300 hover:cursor-pointer"
                                    type=""
                                    onClick={() =>
                                      startAssessment(assessment.assessment_id)
                                    }
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="card-assessment default rounded w-[360px] mr-5 flex-shrink-0">
                                <div className="title font-bold  h-[48px] bg-gradient-to-br from-purple-800 via-purple-600 to-purple-400 flex items-center px-4 mb-">
                                  <p className="text-white">
                                    {assessment.name}
                                  </p>
                                </div>
                                <div className="content px-4 py-4">
                                  <p className="bg-red-50 text-red-800 font-medium px-[10px] py-1 rounded rounded-[6px] mb-5">
                                    Not Started
                                  </p>
                                  <span className="flex mb-4">
                                    <ClipboardDocumentListIcon
                                      width={16}
                                      className="mr-2"
                                    />{" "}
                                    <p>
                                      {assessment.finished_test}/
                                      {assessment.total_test} Test Done
                                    </p>
                                  </span>
                                  <span className="flex mb-4">
                                    <ClockIcon width={16} className="mr-2" />{" "}
                                    <p>
                                      {moment
                                        .duration(
                                          assessment.total_times,
                                          "seconds"
                                        )
                                        .humanize()}
                                    </p>
                                  </span>
                                  <button
                                    className="mb-1 bg-main-violate-50 w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate-100 hover:bg-main-violate-100 disabled:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400"
                                    disabled={assessment.status != "ready"}
                                    onClick={() =>
                                      startAssessment(assessment.assessment_id)
                                    }
                                  >
                                    Start Now
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>
                </>
              )}
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          (!personalTab.pi && !personalTab.sc ? " hidden " : "") +
          "profile-reminder bg-white px-6 py-6 rounded-[12px]"
        }`}
      >
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-8">
            <div className="flex flex-col w-full">
              <p className="text-lg md:text-xl text-gray-900 font-bold mb-2">
                Complete your profile
              </p>
              <div className="w-full flex flex-col">
                <small className="text-gray-500 text-end">
                  {summaries.profile_completed}%
                </small>
                <div className="w-full h-[7px] bg-white-1 rounded-full mt-2">
                  <div
                    className="h-full text-center text-xs text-white bg-main-violate rounded-full "
                    style={{
                      width: `${summaries.profile_completed}%`,
                    }}
                  ></div>
                </div>
              </div>

              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
          </div>
          <div className="section flex gap-2 flex-col md:flex-row">
            <div
              className={`${
                (personalTab.sc ? "" : " hidden ") +
                "card w-full md:w-1/2 border border-lg rounded-[8px] border-gray-200 px-4 py-4"
              }`}
            >
              <div className="flex justify-between items-center mb-4">
                <p className="text-grey-900 font-semibold text-base md:text-lg">
                  Special Conditions
                </p>
                <button
                  className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
                  onClick={() => {
                    setPersonalTab({ ...personalTab, sc: false });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <p className="rounded-lg text-gray-scale-100 text-base mb-14 max-w-max">
                {impairementFileUpload
                  ? "You document has been uploaded, thank you."
                  : "Please add your document to support your special condition statement (optional)"}
              </p>
              {impairementFileUpload ? (
                ""
              ) : (
                <>
                  <button
                    className="mb-4 bg-main-violate-50 px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate hover:text-main-violate-50"
                    type=""
                    disabled={impairementUploading}
                    onClick={(e) => {
                      e.preventDefault();
                      impairementDocRef.current.click();
                    }}
                  >
                    {impairementUploading ? (
                      <PageSpinner size={"6"} />
                    ) : (
                      "Upload Document"
                    )}
                  </button>
                  <input
                    type="file"
                    className="h-full w-full opacity-0 cursor-pointer hidden"
                    name="imparement_doc"
                    accept=".pdf"
                    onChange={uploadImpairement}
                    ref={impairementDocRef}
                  />
                </>
              )}
            </div>
            <div
              className={`${
                (personalTab.pi ? "" : " hidden ") +
                "card w-full md:w-1/2 border border-lg rounded-[8px] border-gray-200 px-4 py-4"
              }`}
            >
              <div className="flex justify-between items-center mb-4">
                <p className="text-grey-900 font-semibold text-base md:text-lg">
                  Personal Information
                </p>
                <button
                  className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none"
                  onClick={() => {
                    setPersonalTab({ ...personalTab, pi: false });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <p className="rounded-lg text-gray-scale-100 text-base mb-14 max-w-max">
                Please add your document to support your special condition
                statement (optional)
              </p>
              <button
                className="mb-4 bg-main-violate-50 px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate hover:text-main-violate-50"
                type=""
                onClick={() => navigate("/candidate/profile")}
              >
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
