import {
  useNavigate,
  useParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import Navbar from "../../Component/NavbarDashboard";
import { ToastContainer } from "react-toastify";

const ThankYou = () => {
  let navigate = useNavigate();
  const { company_slug, test_id } = useParams();
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    height: "177vh",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <Navbar />
      <div style={myStyle}>
        <div className="container mx-auto pt-12">
          <ToastContainer />
          <div className="mx-auto max-w-[980px] px-2 md:px-0">
            <div className="bg-white rounded-lg shadow w-full mb-[40px]">
              <div className="rounded-t-lg">
                <div className="px-[68px] py-5 ">
                  <div className="text-center">
                    <h2 className="font-bold text-xl mb-4">Thank You</h2>
                    <p className="text-slate-600 mb-2">
                      Our Team is currently reviewing your condition. We need up
                      to 24 business hours to prepare the right digital
                      environment for you.
                    </p>
                    <p className="text-slate-600 mb-10">
                      We will send you an email when you can take the
                      assessment. Thank you for your patience.
                    </p>
                    <button
                      className="bg-main-violate w-[200px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg ml-3 mt-4 hover:bg-violet-600"
                      type="submit"
                      onClick={() => navigate(`/assessment/${company_slug}`)}
                    >
                      Back To Dashboard
                    </button>
                    <button
                      className="bg-main-violate w-[200px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg ml-3 mt-4 hover:bg-violet-600"
                      type="submit"
                      onClick={() => navigate(`/assessment/${company_slug}/test/${test_id}/preparation`)}
                    >
                      Go To Preparation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
