import { ArrowDownIcon, ShareIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";
import EmptyContent from "../../Component/Tables/EmptyContent";
import TableSpinner from "../../Component/TableSpinner";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetailOccupation } from "../../api";
import PageSpinner from "../../Component/PageSpinner";
import Ilustrate1 from "../../Assets/b2c_pitch_meeting.svg";
import Ilustrate2 from "../../Assets/b2c_factory_illustration.svg";
import Ilustrate3 from "../../Assets/b2c_research.svg";
import Ilustrate4 from "../../Assets/b2c_keep_problem.svg";
import Ilustrate5 from "../../Assets/b2c_self_love.svg";
import Ilustrate6 from "../../Assets/b2c_design_collapse.svg";
const ReportsPage = () => {
  const [tableLoading] = useState(false);
  const [data, setData] = useState(null);
  const boxStyle = {
    background: "linear-gradient(180deg, #B638FF 0%, #FFF 100%)",
  };
  const [loading, setLoading] = useState(true);
  const { slug, interest_code } = useParams();
  const occupations = [
    {
      id: 1,
      code: "IRE",
      occupation_code: "11-2021.00",
      occupation: "Marketing Manager",
    },
  ];

  const result = {
    data: occupations,
    itemsPerPage: 1,
    itemsFrom: 1,
    itemsTo: 1,
    totalItems: 1,
    currentPage: 1,
  };

  const fetchDetailOccupation = async () => {
    const result = await getDetailOccupation(slug, interest_code);
    if (result.success) {
      setData(result.data);
      setLoading(false);
      setTimeout(() => {
        window.print();
      }, 1000);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    fetchDetailOccupation();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="min-h-[680px] w-full px-3" style={boxStyle}>
        <div className="lg:max-w-[90%] xl:max-w-[70%] mx-auto pt-11">
          {loading && (
            <>
              <div className="border border-main-violate bg-main-violate-100 rounded-md p-6 mb-6">
                <PageSpinner />
              </div>
            </>
          )}
          {!loading && (
            <>
              <p className="text-[25px] mdb:text-[50px] font-extrabold text-white break-words mb-2">
                {/* {data.bridge.replace(/_/g, ", ").toUpperCase()} */}
                {data.name}
              </p>
              <div className="border border-main-violate bg-main-violate-100 rounded-md p-6 mb-6">
                <p className="mb-2">Occupation code: {data.code}</p>
                <p className="mb-4 text-lg">Also called: {data.tags}</p>
                <div className="flex space-x-5">
                  
                </div>
              </div>
              <div className="border border-main-violate bg-main-violate-50 rounded-md mb-6 flex justify-between">
                <div className="ilustrate hidden mdb:w-[50%]  mdb:block bg-white flex justify-center">
                  <img src={Ilustrate1} className="max-w-[480px]"></img>
                </div>
                <div className="w-[100%] mdb:w-[50%] p-6">
                  <p className="mb-2 text-main-violate text-xl font-bold">
                    What they do
                  </p>
                  <p>{data.responsibility ? data.responsibility : "-"}</p>
                </div>
              </div>
              <div className="border border-main-violate bg-main-violate-50 rounded-md mb-6 flex justify-between">
                <div className="ilustrate hidden mdb:w-[50%]  mdb:block bg-white flex justify-center">
                  <img src={Ilustrate2} className="max-w-[480px]"></img>
                </div>
                <div className="w-[100%] mdb:w-[50%] p-6">
                  <p className="mb-2 text-main-violate text-xl font-bold">
                    Job Description
                  </p>
                  <ul className="list-disc list-inside">
                    {data.description.length > 0 &&
                      data.description.map((item) => <li>{item}</li>)}
                  </ul>
                </div>
              </div>

              <div className="border border-main-violate bg-white rounded-md mb-6">
                <div className="py-3 px-5 b2c reports-knowledge rounded-tl-md rounded-tr-md">
                  <p className="text-white text-xl font-bold">Knowledge</p>
                </div>
                <div className="flex">
                  <div className="py-3 px-5 b2c w-[100%] mdb:w-[50%]">
                    {data.knowledge.map((value, index) => (
                      <div className="mb-4">
                        <>
                          <p
                            key={index}
                            className="text-main-violate text-lg font-bold mb-2"
                          >
                            {value.title}
                          </p>
                          <ul className="list-disc list-inside px-2">
                            {value.description.map((value) => (
                              <>
                                <li>{value}</li>
                              </>
                            ))}
                          </ul>
                        </>
                      </div>
                    ))}
                  </div>
                  <div className="hidden mdb:block mdb:w-[50%] flex justify-center">
                    <img src={Ilustrate3} className="max-w-[480px]"></img>
                  </div>
                </div>
              </div>
              <div className="border border-[#E8750E] bg-white rounded-md mb-6">
                <div className="py-3 px-5 b2c reports-skills rounded-tl-md rounded-tr-md">
                  <p className="text-white text-xl font-bold">Skills</p>
                </div>
                <div className="flex">
                  <div className="py-3 px-5 b2c w-[100%] mdb:w-[50%]">
                    {data.skills.map((value, index) => (
                      <div className="mb-4">
                        <>
                          <p
                            key={index}
                            className="text-[#E8750E] text-lg font-bold mb-2"
                          >
                            {value.title}
                          </p>
                          <ul className="list-disc list-inside px-2">
                            {value.description.map((value) => (
                              <>
                                <li>{value}</li>
                              </>
                            ))}
                          </ul>
                        </>
                      </div>
                    ))}
                  </div>
                  <div className="hidden mdb:block mdb:w-[50%] flex justify-center">
                    <img src={Ilustrate4} className="max-w-[480px]"></img>
                  </div>
                </div>
              </div>
              <div className="border border-[#31C48D] bg-white rounded-md mb-6">
                <div className="py-3 px-5 b2c reports-abilities rounded-tl-md rounded-tr-md">
                  <p className="text-white text-xl font-bold">Abilities</p>
                </div>
                <div className="flex">
                  <div className="py-3 px-5 b2c w-[100%] mdb:w-[50%]">
                    {data.abilities.map((value, index) => (
                      <div className="mb-4">
                        <>
                          <p
                            key={index}
                            className="text-[#31C48D] text-lg font-bold mb-2"
                          >
                            {value.title}
                          </p>
                          <ul className="list-disc list-inside px-2">
                            {value.description.map((value) => (
                              <>
                                <li>{value}</li>
                              </>
                            ))}
                          </ul>
                        </>
                      </div>
                    ))}
                  </div>
                  <div className="hidden mdb:block mdb:w-[50%] flex justify-center">
                    <img src={Ilustrate6} className="max-w-[480px]"></img>
                  </div>
                </div>
              </div>
              <div className="border border-[#D61F69] bg-white rounded-md mb-6">
                <div className="py-3 px-5 b2c reports-personalities rounded-tl-md rounded-tr-md">
                  <p className="text-white text-xl font-bold">Personality</p>
                </div>
                <div className="flex">
                  <div className="py-3 px-5 b2c w-[100%] mdb:w-[50%]">
                    {data.personality.map((value, index) => (
                      <div className="mb-4">
                        <>
                          <p
                            key={index}
                            className="text-[#D61F69] text-lg font-bold mb-2"
                          >
                            {value.title}
                          </p>
                          <ul className="list-disc list-inside px-2">
                            {value.description.map((value) => (
                              <>
                                <li>{value}</li>
                              </>
                            ))}
                          </ul>
                        </>
                      </div>
                    ))}
                  </div>
                  <div className="hidden mdb:block mdb:w-[50%] flex justify-center">
                    <img src={Ilustrate5} className="max-w-[480px]"></img>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportsPage;
