import { useEffect, useState } from "react";
import { textInputTheme } from "../themes/flowbite-theme";
import config from "../config";
import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../Assets/bgAuth.webp";
import BtnSpinner from "../Component/BtnSpinner";
import FooterGuest from "../Component/FooterGuest";
import { TextInput } from "flowbite-react";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { documentTitle, inputIsEmailFormat, validationError } from "../helpers";
import { postForgotPassword } from "../api";

const ForgotPasswordPage = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formPassed, setFormPassed] = useState(false);
  const [validations, setValidations] = useState({});
  const handleEmailChange = (e) => {
    const value = e.target.value || "";
    setEmail(value);
  };

  const handleRecaptchaChange = (e) => {
    const value = e;
    setRecaptcha(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const forgotData = JSON.stringify({
      recaptcha: recaptcha,
      email: email,
    });
    const result = await postForgotPassword(forgotData);
    if (result.success) {
      toast.success(result.message, {
        toastId: "forgot-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
        onClose: () => navigate("/login", { replace: false }),
      });
    } else {
      toast.error(e.response.data.message, {
        toastId: "forgot-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (email != "" && recaptcha != "") {
      setFormPassed(true);
      const newValidations = { ...validations };
      if (!inputIsEmailFormat(email)) {
        newValidations.email = [validationError("email")];
      } else {
        newValidations.email = [];
      }
      setValidations(newValidations);
      const allValid = Object.values(newValidations).every(
        (validationArray) => validationArray.length === 0
      );
      setFormPassed(allValid);
    } else {
      setFormPassed(false);
    }
  }, [email, recaptcha]);
  useEffect(() => {
    documentTitle("Forgot Password");
  }, []);
  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div style={myStyle}>
      <div className="container mx-auto pt-12 h-full flex flex-col md:justify-center">
        <ToastContainer />
        <div className="mx-auto px-4 flex w-full flex-col md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left mb-6">
            <p className="text-xl md:text-2xl text-white-1 mb-[16px] font-normal">
              Welcome To
            </p>
            <p className="text-xl md:text-6xl text-white-1 leading-[110%] font-light md:max-w-sm">
              Highr ID
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <div className="bg-white rounded-lg shadow w-full lg:w-[483px] mb-[100px] px-10 py-8">
              <h1 className="font-bold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                Forgotten Your Password
              </h1>
              <p className="text-black text-[14px] leading-[140%] mb-6 text-gray-500">
                No problem! We’ll send you a link to reset it. Please enter the
                email address you use to sign in to Highr.id
              </p>
              <form
                className="bg-grey-lightest"
                name="forgot"
                onSubmit={handleSubmit}
              >
                <div className="w-full justify-center">
                  <div className="mb-5">
                    <label className="text-gray-800 text-sm font-bold mb-4 leading-[240%]">
                      Your Email
                    </label>
                    <TextInput
                      icon={EnvelopeIcon}
                      theme={textInputTheme}
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Email"
                    />
                    {typeof validations.email != "undefined"
                      ? validations.email.map((e) => {
                          return (
                            <span className="input-error-message">{e}</span>
                          );
                        })
                      : ""}
                  </div>
                  <div className="flex justify-center">
                    {/* <ReCAPTCHA
                      sitekey={config.RECAPTCHA_SITE_KEY}
                      onChange={handleRecaptchaChange}
                    /> */}
                    <GoogleReCaptchaProvider
                      reCaptchaKey={config.RECAPTCHA_SITE_KEY}
                    >
                      <GoogleReCaptcha onVerify={handleRecaptchaChange} />
                    </GoogleReCaptchaProvider>
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-normal h-[46px] rounded-lg mt-5 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={submitting || !formPassed}
                  >
                    {submitting ? <BtnSpinner /> : "Send reset link"}
                  </button>
                </div>
                <div className="flex justify-center ">
                  <Link
                    to="/login"
                    className="text-black text-center text-[14px] leading-[140%] font-semibold tracking-normal mt-[16px] mb-2"
                  >
                    Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterGuest />
    </div>
  );
};

export default ForgotPasswordPage;
