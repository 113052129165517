import React, { useState, Children } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const TestSectionSlider = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalChildren = Children.count(children);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < totalChildren - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="slider-component flex flex-col flex-1">
      <div className="content flex-1 overflow-y-auto">{Children.toArray(children)[currentIndex]}</div>
      <div className="navigation flex justify-center gap-4 items-center mt-6 h-[50px]">
        
        <button
          onClick={handlePrev}
          className="bg-gray-200 p-2 rounded-full disabled:bg-gray-100 text-gray-700 disabled:text-gray-400"
          disabled={currentIndex === 0}
        >
          <ChevronLeftIcon className="w-5 h-5 " />
        </button>
        <span className="text-gray-700">
          {currentIndex + 1} / {totalChildren}
        </span>
        <button
          onClick={handleNext}
          className="bg-gray-200 p-2 rounded-full text-gray-700 disabled:bg-gray-100 disabled:text-gray-400"
          disabled={currentIndex === totalChildren - 1}
        >
          <ChevronRightIcon className="w-5 h-5 " />
        </button>
      </div>
    </div>
  );
};

export default TestSectionSlider;
