import React, { Component } from "react";

export class Footer extends Component {

  render() {
    return (
      <div>
        <footer className="text-center mt-[-10px] mt-[32px]">
          <div className="text-gray-800 text-white text-center text-[14px] tracking-normal leading-[140%]">
              Copyright ©2022
            <a className="text-gray-800 text-white text-center text-[14px] tracking-normal leading-[140%] ml-1" href="https://tailwind-elements.com/">Powered by Highr ID</a>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;